import { Stack } from "@mui/material";
import { KitchenProvider } from "contexts/KitchenContext";
import { useOutlet } from "react-router-dom";
import KitchenTables from "./components/KitchenTables";

export const KitchenLayout = () => {
  const outlet = useOutlet();
  return <KitchenProvider>{outlet}</KitchenProvider>;
};

const KitchenPage = () => {
  return <KitchenTables />;
};

export default KitchenPage;
