import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/700.css";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "views/NotFound";
import OrderStatusPage from "views/order-status-page/OrderStatusPage";
import SignInPage from "views/auth/SignInPage";
import OpenLayout from "components/layout/OpenLayout";
import ProtectedLayout, { ProtectedWithHeaderLayout } from "components/layout/ProtectedLayout";
import DashboardPage from "views/dashboard/DashboardPage";
import BookActivityPage from "views/book-activity-page/BookActivityPage";
import ActivitiesPage from "views/activities-page/ActivitiesPage";
import SelectMenuActivityPage from "views/select-menu-activity-page/SelectMenuActivityPage";
import i18n from "./i18n";
import LocaleLayout from "components/layout/LocaleLayout";
import { Languages } from "types/Languages";
import BookRoomPage from "views/book-room-page/BookRoomPage";
import ReceiptStatusPage from "views/receipt-status-page/ReceiptStatusPage";
import BookingStatusPage from "views/booking-status-page/BookingStatusPage";
import KitchenPage, { KitchenLayout } from "views/dashboard/kitchen-page/KitchenPage";
import DashboardLayout from "views/dashboard/DashboardLayout";
import WaiterPage from "views/dashboard/waiter-page/WaiterPage";
import DashboardActivitiesPage from "views/dashboard/activities-page/ActivitiesPage";
import ScanCodePage from "views/scan-code-page/ScanCodePage";
import KitchenOrderDetailPage from "views/dashboard/kitchen-page/KitchenOrderDetailPage";
import OrderDetails from "views/select-menu-activity-page/components/order-details/OrderDetails";
import ReceiptDetails from "views/select-menu-activity-page/components/receipt-details/ReceiptDetails";
import CreateOrderTab from "views/select-menu-activity-page/tabs/CreateOrderTab";
import MenuTab from "views/select-menu-activity-page/tabs/MenuTab";
import OrdersListTab from "views/select-menu-activity-page/tabs/OrdersListTab";
import ReceiptsTab from "views/select-menu-activity-page/tabs/ReceiptsTab";
import ClientOrderPage from "views/client-order-page/ClientOrderPage";
import ClientOrderMenuTab from "views/client-order-page/views/MenuTab";
import ClientOrderCreateOrderTab from "views/client-order-page/views/CreateOrderTab";
import ClientChooseActivityView from "views/client-order-page/views/ClientChooseActivityView";
import { useAuth } from "hooks/useAuth";
import RoleRoute from "components/layout/RoleRoute";
import ActivityLinksPage from "views/dashboard/activity-links-page/ActivityLinksPage";
import BookRoomsPage from "views/dashboard/book-room-page/BookRoomsPage";

const renderRoutes = (user?: any, usesLocale = false) => {
  if (!usesLocale) i18n.changeLanguage(Languages.ENGLISH);
  return (
    <>
      <Route element={<OpenLayout />}>
        <Route path="book/activities" element={<ActivitiesPage />} />
        <Route path="book/activities/:activitySlug" element={<BookActivityPage />} />

        <Route path="book/room" element={<BookRoomPage />} />

        <Route path="order/status/:orderNumber" element={<OrderStatusPage />} />
        <Route path="receipt/status/:receiptNumber" element={<ReceiptStatusPage />} />
        <Route path="booking/status/:bookingNumber" element={<BookingStatusPage />} />

        <Route path="order/:uuid" element={<ClientOrderPage />}>
          <Route path="choose" element={<ClientChooseActivityView />} />
          <Route path="menu" element={<ClientOrderMenuTab />} />
          <Route path="create-order" element={<ClientOrderCreateOrderTab />} />
        </Route>

        <Route path="signin" element={<SignInPage />} />
      </Route>

      {/* Protected routes */}
      <Route path="dashboard" element={<ProtectedLayout />}>
        <Route element={<DashboardLayout />}>
          <Route index element={<DashboardPage />} />
          <Route
            path="kitchen"
            element={
              <RoleRoute role={user?.user.role.name ?? ""} roles={["Admin", "Cook"]}>
                <KitchenLayout />
              </RoleRoute>
            }
          >
            <Route index element={<KitchenPage />} />
            <Route path=":id" element={<KitchenOrderDetailPage />} />
          </Route>
          <Route
            path="waiter"
            element={
              <RoleRoute role={user?.user.role.name ?? ""} roles={["Admin", "Waiter"]}>
                <WaiterPage />
              </RoleRoute>
            }
          />
          <Route path="activities" element={<DashboardActivitiesPage />} />
          <Route path="scan" element={<ScanCodePage />} />
          <Route path="activity-links" element={<ActivityLinksPage />} />
          <Route path="book-room" element={<BookRoomsPage />} />
        </Route>
        <Route element={<ProtectedWithHeaderLayout />}>
          <Route
            path="select-menu/activity/:activitySlug"
            element={
              <RoleRoute role={user?.user.role.name ?? ""} roles={["Admin", "Waiter"]}>
                <SelectMenuActivityPage />
              </RoleRoute>
            }
          >
            <Route index element={<Navigate to="menu" />} />
            <Route path="menu" element={<MenuTab />} />
            <Route path="orders" element={<OrdersListTab />} />
            <Route path="orders/:orderId" element={<OrderDetails />} />
            <Route path="receipts" element={<ReceiptsTab />} />
            <Route path="receipts/:receiptId" element={<ReceiptDetails />} />
            <Route path="create-order" element={<CreateOrderTab />} />
          </Route>
        </Route>
      </Route>
    </>
  );
};

function App() {
  const { user } = useAuth();

  return (
    <div>
      <Routes>
        <Route path=":locale" element={<LocaleLayout />}>
          {renderRoutes(user)}
          <Route path="*" element={<RedirectToExternalWebsite />} />
        </Route>
        {renderRoutes(user, true)}
        <Route path="*" element={<RedirectToExternalWebsite />} />
      </Routes>
    </div>
  );
}

const RedirectToExternalWebsite = () => {
  window.location.replace(process.env.REACT_APP_EXTERNAL_REDIRECT_URL ?? "");

  return null;
};

export default App;
