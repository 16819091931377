import { Box, Typography, Stack, Divider } from "@mui/material";
import React, { useMemo, useState } from "react";
import { ActivityContext } from "contexts/ActivityContext";
import { Product } from "models/Activity";
import ExtraCard from "./components/ExtraCard";
import _ from "lodash";
import { ProductGroup } from "types/ProductGroup";

const Extras = () => {
  const { activity, setSelectedProducts, selectedProducts } = React.useContext(ActivityContext);

  const [groupedProducts, setGroupedProducts] = useState<ProductGroup[]>([]);

  useMemo(() => {
    if (activity) {
      const groupsWithProducts = _(activity?.products)
        .groupBy("product_category_name")
        .map((items, key) => ({
          title: key !== "null" ? key : "Uncategorized",
          category: { ...items[0].category },
          items,
        }))
        .value();
      setGroupedProducts(groupsWithProducts);
    }
  }, [activity]);

  const handleExtraChange = (extra: Product, newValue: number) => {
    setSelectedProducts((prevValue) => {
      return { ...prevValue, [extra.id]: { product: extra, amount: newValue } };
    });
  };

  return (
    activity && (
      <Box>
        <Box marginY={2}>
          <Typography variant="h6">Extras</Typography>
          <Typography variant="body1">
            {`Extras to be added to your ${activity.name}. Served at the location.`}
          </Typography>
          <Stack direction="column" spacing={2} marginY={2}>
            {groupedProducts.map((productGroup, index) => {
              return (
                <Box key={index}>
                  <Typography variant="h6">{productGroup.title}</Typography>
                  <Divider />
                  <Stack mt={1} spacing={1}>
                    {productGroup.items.map((product, index) => {
                      const handleChange = (newValue: number) => {
                        handleExtraChange(product, newValue);
                      };
                      return (
                        selectedProducts && (
                          <ExtraCard
                            key={index}
                            imgUrl={product.display_image?.url}
                            title={product.name}
                            price={product.price}
                            description={product.description}
                            onChange={handleChange}
                            value={selectedProducts[product.id].amount}
                          />
                        )
                      );
                    })}
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </Box>
        <Box height={50} />
      </Box>
    )
  );
};

export default Extras;
