import { SelectMenuActivityProvider } from "contexts/WaiterOrderFoodContext";
import FoodBottomNavigation from "./components/FoodBottomNavigation";
import { useOutlet } from "react-router-dom";

const SelectMenuActivityPage = () => {
  const outlet = useOutlet();

  return (
    <SelectMenuActivityProvider>
      {outlet}
      <FoodBottomNavigation />
    </SelectMenuActivityProvider>
  );
};

export default SelectMenuActivityPage;
