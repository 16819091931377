import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { PaymentMethod } from "models/PaymentMethod";
import BoxRadioControl from "components/controls/custom-radio/BoxRadioControl";
import CustomRadioGroup from "components/controls/custom-radio/CustomRadioGroup";
import { useTranslation } from "react-i18next";

type PaymentMethodData = { data: PaymentMethod[]; loading: boolean; error: unknown };

type Props = {
  value: any;
  onChange: (value: any) => void;
  exclude?: string[];
  disabled?: boolean;
};

const SelectPaymentMethod = ({ value, onChange, exclude, disabled }: Props) => {
  const { t } = useTranslation();

  const [selectedPaymentMethod, setPaymentMethod] = useState(value ?? { id: null });

  const { data, loading }: PaymentMethodData = useFetch("open/payment-methods", {
    exclude,
  });

  const handlePaymentMethodChange = (selectedMethod: any) => {
    const methodFromData: any = data.find((method) => method.id === selectedMethod);
    if (methodFromData) {
      onChange(methodFromData);
      setPaymentMethod(methodFromData);
    }
  };

  return (
    <Box>
      {loading ? (
        <Typography variant="body1">{t("Loading")}</Typography>
      ) : (
        data && (
          <CustomRadioGroup defaultValue={data[0].id} value={selectedPaymentMethod.id}>
            <Grid container marginY={1} spacing={1} columns={{ xs: 3, sm: 4 }}>
              {data.map((paymentMethod, i) => (
                <Grid key={i} xs={1} sm={1} item>
                  <BoxRadioControl
                    value={paymentMethod.id}
                    onClick={handlePaymentMethodChange}
                    disabled={disabled}
                  >
                    <PaymentCard slug={paymentMethod.slug} name={paymentMethod.name} />
                  </BoxRadioControl>
                </Grid>
              ))}
            </Grid>
          </CustomRadioGroup>
        )
      )}
    </Box>
  );
};

type PaymentCardProps = {
  slug: string;
  name: string;
};

const PaymentCard = ({ slug, name }: PaymentCardProps) => {
  const getPaymentImage = () => {
    switch (slug) {
      case "nequi":
        return require("assets/images/payment-icons/nequi.png");
      case "clave":
        return require("assets/images/payment-icons/clave.png");
      case "credit_card":
        return require("assets/images/payment-icons/mastercard.png");
      case "yappy":
        return require("assets/images/payment-icons/yappy.png");
      case "cash":
        return require("assets/images/payment-icons/cash.png");
      case "bill":
        return require("assets/images/payment-icons/bill.jpeg");

      default:
        return require("assets/images/payment-icons/nequi.png");
    }
  };
  return (
    <Stack alignItems="center">
      <img src={getPaymentImage()} alt={slug} height={25} />
      <Typography variant="caption">{name}</Typography>
    </Stack>
  );
};

export default SelectPaymentMethod;
