import { Box, Paper, Stack, Typography, Divider } from "@mui/material";
import { calculateOrderPrice } from "helpers/MenuActivityHelper";
import { formatMoney } from "helpers/Shared";
import { Order, OrderProduct } from "models/Order";
import { useTranslation } from "react-i18next";

const OrderDetailsCard = ({ order }: { order: Order }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Paper>
        <Stack marginY={2} p={2}>
          <Stack>
            <Typography fontWeight="bold">{order.activity.name}</Typography>
            <Typography variant="caption">
              {t("Orders")}: {order.invoice_number}
            </Typography>
          </Stack>
          <Divider />
          <Stack my={1}>
            <Stack direction="row" justifyContent="justify-between">
              <Typography flex={1} variant="caption">
                {t("Item")}
              </Typography>
              <Typography flex={1} textAlign="right" variant="caption">
                {t("Quantity")}
              </Typography>
              <Typography flex={1} textAlign="right" variant="caption">
                {t("Total")}
              </Typography>
            </Stack>
          </Stack>
          {order.products &&
            order.products.map((order: OrderProduct) => (
              <Stack my={1} key={order.name}>
                <Stack direction="row" justifyContent="justify-between">
                  <Typography flex={1} variant="body1">
                    {order.name}
                  </Typography>
                  <Typography flex={1} textAlign="right" variant="body1">
                    {order.quantity}
                  </Typography>
                  <Typography flex={1} textAlign="right" variant="body1">
                    {formatMoney(order.price * order.quantity)}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          <Divider sx={{ borderStyle: "dashed", borderBottomWidth: "medium" }} />
          <Stack direction="row" marginY={2} justifyContent="space-between" alignItems="center">
            <Typography fontWeight="bold">{t("Total")}</Typography>
            <Typography fontWeight="bold" variant="h5" fontFamily="Open Sans">
              {formatMoney(calculateOrderPrice(order.products))}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default OrderDetailsCard;
