import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RichText from "../richtext-parser/RichText";

const ReadMoreText = ({ text, isRichText = false }: { text: string; isRichText?: boolean }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleOnPress = () => setExpanded(!expanded);

  return (
    <Box>
      {text.length > 150 ? (
        <>
          <Typography
            variant="body1"
            sx={{
              ...(!expanded && {
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }),
            }}
          >
            {isRichText ? <RichText htmlString={text} /> : text}
          </Typography>
          {text.length > 100 && (
            <Typography
              mt={1}
              variant="body1"
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={handleOnPress}
            >
              {expanded ? t("Read less") : t("Read more →")}
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="body1">
          {isRichText ? <RichText htmlString={text} /> : text}
        </Typography>
      )}
    </Box>
  );
};

export default ReadMoreText;
