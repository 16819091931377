import { useOutlet } from "react-router-dom";
import { ClientOrderProvider } from "contexts/ClientOrderContext";
import BottomTabNavigation from "./components/BottomTabNavigation";

const ClientOrderPage = () => {
  const outlet = useOutlet();

  return (
    <ClientOrderProvider>
      {outlet}
      <BottomTabNavigation />
    </ClientOrderProvider>
  );
};

export default ClientOrderPage;
