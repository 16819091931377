import { Container, Unstable_Grid2 as Grid } from "@mui/material";
import ActivityBookNowCard from "views/activities-page/components/ActivityBookNowCard";
import useFetch from "hooks/useFetch";
import { Activity } from "models/Activity";

const ActivitiesPage = () => {
  const { data, loading, error } = useFetch("open/activities");

  return (
    data && (
      <Container maxWidth="md">
        <Grid container spacing={2} columns={{ md: 2, sm: 2, xs: 1 }} marginY={2}>
          {data.map((item: Activity) => (
            <ActivityBookNowCard key={item.id} activity={item} />
          ))}
        </Grid>
      </Container>
    )
  );
};

export default ActivitiesPage;
