import { Paper, Stack, Typography, Divider } from "@mui/material";
import { formatMoney } from "helpers/Shared";
import { Activity } from "models/Activity";
import { Order, OrderProduct } from "models/Order";
import { useTranslation } from "react-i18next";

const OrderOverviewCard = ({
  order,
  products,
  activity,
  total,
}: {
  order?: Order;
  products: (OrderProduct & { amount?: number })[];
  activity?: Activity;
  total: number;
}) => {
  const { t } = useTranslation();

  return (
    <Paper>
      <Stack marginY={2} p={2}>
        <Stack marginBottom={1}>
          <Typography fontWeight="bold">{activity?.name}</Typography>
        </Stack>
        <Divider />
        <Stack my={1}>
          <Stack direction="row" justifyContent="justify-between">
            <Typography flex={1} variant="caption">
              {t("Item")}
            </Typography>
            <Typography flex={1} textAlign="right" variant="caption">
              {t("Quantity")}
            </Typography>
            <Typography flex={1} textAlign="right" variant="caption">
              {t("Total")}
            </Typography>
          </Stack>
        </Stack>
        {products.map((order) => (
          <Stack my={1} key={order.name}>
            <Stack direction="row" justifyContent="justify-between">
              <Typography flex={1} variant="body1">
                {order.name}
              </Typography>
              <Typography flex={1} textAlign="right" variant="body1">
                {order.quantity || order.amount}
              </Typography>
              <Typography flex={1} textAlign="right" variant="body1">
                {formatMoney(order.price * (order.amount || order.quantity))}
              </Typography>
            </Stack>
          </Stack>
        ))}
        <Divider sx={{ borderStyle: "dashed", borderBottomWidth: "medium" }} />
        <Stack direction="row" marginY={2} justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold">{t("Total")}</Typography>
          <Typography fontWeight="bold" variant="h5" fontFamily="Open Sans">
            {formatMoney(total)}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default OrderOverviewCard;
