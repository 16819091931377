import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import { Product } from "models/Activity";
import ExtraCard from "views/book-activity-page/components/extras/components/ExtraCard";
import { useTranslation } from "react-i18next";
import { BookRoomContext } from "contexts/BookRoomContext";

const Extras = () => {
  const { t } = useTranslation();
  const { setSelectedProducts, selectedProducts, bookingExtras } =
    React.useContext(BookRoomContext);

  const handleExtraChange = (extra: Product, newValue: number) => {
    setSelectedProducts((prevValue) => {
      return { ...prevValue, [extra.id]: { product: extra, amount: newValue } };
    });
  };

  return (
    <Box>
      <Box marginY={2}>
        <Typography variant="h6">{t("Extras")}</Typography>
        <Typography variant="body1">{t("Extras to be added to your booking.")}</Typography>
        <Stack direction="column" spacing={2} marginY={2}>
          {bookingExtras?.map((product, index) => {
            const handleChange = (newValue: number) => {
              handleExtraChange(product, newValue);
            };
            return (
              selectedProducts && (
                <ExtraCard
                  key={index}
                  imgUrl={product.display_image?.url}
                  title={product.name}
                  price={Number(product.price)}
                  description={product.description}
                  onChange={handleChange}
                  value={selectedProducts[product.id].amount}
                />
              )
            );
          })}
        </Stack>
      </Box>
      <Box height={50} />
    </Box>
  );
};

export default Extras;
