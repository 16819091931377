import { Box, Typography, Stack } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import useFetch from "hooks/useFetch";
import { Activity } from "models/Activity";
import qs from "qs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DashboardMenuRedirectCard } from "../DashboardPage";

const WaiterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [page] = useState(1);

  const { data: menuActivities } = useFetch(
    "activities",
    {
      filter: {
        bookingMode: "MENU",
      },
    },
    { Authorization: `Bearer ${user.token}` },
    page,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
  
  return (
    <Box marginY={2}>
      <Typography variant="h5">{t("Menu (Waiter)")}</Typography>
      <Stack spacing={2}>
        {menuActivities &&
          menuActivities.map((activity: Activity) => {
            const handleClick = () => navigate(`../select-menu/activity/${activity.slug}`);
            return (
              <DashboardMenuRedirectCard
                key={activity.id}
                title={activity.name}
                subText={activity.category.name}
                img={activity.display_image?.thumbnail_url ?? ""}
                onClick={handleClick}
              />
            );
          })}
      </Stack>
    </Box>
  );
};

export default WaiterPage;
