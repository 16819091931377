import { Box, Link, Stack, Typography } from "@mui/material";
import DisplayImageCarousel from "components/DisplayImageCarousel";
import ReadMoreText from "components/generic/read-more-text/ReadMoreText";
import RichText from "components/generic/richtext-parser/RichText";
import { BookRoomContext } from "contexts/BookRoomContext";
import { colors } from "helpers/Theme";
import { isEmpty } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import BookRoomInformation from "./components/BookRoomInformation";
import CheckRoomAvailability from "./components/CheckRoomAvailability";
import SelectDateRange from "./components/SelectDateRange";
import SelectRoomType from "./components/SelectRoomType";

const Reserve = () => {
  const { t } = useTranslation();

  const { accommodation, selectedDateRange, availabilityData } = useContext(BookRoomContext);

  return (
    accommodation && (
      <Box>
        <Box height={250}>
          <DisplayImageCarousel
            gallery={accommodation.gallery}
            displayImage={accommodation.display_image}
          />
        </Box>
        <Stack
          spacing={2}
          marginTop={-5}
          padding={2}
          paddingTop={3}
          marginX={-2}
          marginBottom={10}
          position="relative"
          bgcolor={colors.white}
          sx={{ borderTopLeftRadius: 25, borderTopRightRadius: 25 }}
        >
          <Box>
            <Typography variant="h5">
              {accommodation.name ?? t("Stay at the Happy.Green Retreat")}
            </Typography>
            {accommodation.description && (
              <ReadMoreText text={accommodation.description} isRichText />
            )}
            {accommodation.website && (
              <Link href={accommodation.website}>
                <Typography variant="body1" color="inherit">
                  {t("Read more")}
                </Typography>
              </Link>
            )}
          </Box>
          {/* SelectDates */}
          <SelectDateRange />
          {/* Availability check */}
          <CheckRoomAvailability />
          {/* Select Room Type */}
          {selectedDateRange?.from &&
            selectedDateRange?.to &&
            !isEmpty(availabilityData?.rooms) && <SelectRoomType />}
          <BookRoomInformation />
        </Stack>
      </Box>
    )
  );
};

export default Reserve;
