import { LoadingButton } from "@mui/lab";
import { Box, Container, Paper, Stack, TextField, useTheme } from "@mui/material";
import EmptyOrder from "components/order-food/EmptyOrder";
import OrderOverviewCard from "components/order-food/OrderOverviewCard";
import { ClientOrderContext } from "contexts/ClientOrderContext";
import { isValidEmail } from "helpers/ActivityReserveHelper";
import { generateCreateOrderPayload } from "helpers/ClientOrderHelper";
import { calculateOrderPrice } from "helpers/MenuActivityHelper";
import { formatMoney } from "helpers/Shared";
import { isEmpty } from "lodash";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PreviousOrders from "../components/PreviousOrders";
import SelectPaymentMethod from "../components/SelectPaymentMethod";

const CreateOrderTab = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    order,
    selectedActivity,
    email,
    activityLink,
    setEmail,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentMethods,
    createOrder,
    creatingOrder,
  } = useContext(ClientOrderContext);

  const handlePostOrder = () => {
    const payload = generateCreateOrderPayload(
      selectedActivity?.id ?? 0,
      email,
      selectedPaymentMethod?.slug ?? "",
      order,
      window.location.href,
      {
        options: activityLink?.settings.option_groups,
        tags: activityLink?.settings.tags,
      }
    );
    createOrder(payload);
  };

  const isValid = () => {
    return isValidEmail(email) && selectedPaymentMethod;
  };

  const changeEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const optionsAndTags = useMemo(() => {
    const optiongroups = activityLink?.settings.option_groups
      ?.filter((option) => option.activity_id === selectedActivity?.id)
      .map((option) => option.name);
    const tags = activityLink?.settings.tags?.map((tag) => tag.name);
    return [...(optiongroups ?? []), ...(tags ?? [])].join(" | ");
  }, [activityLink?.settings.option_groups, activityLink?.settings.tags, selectedActivity?.id]);

  return (
    <Box>
      {isEmpty(order) ? (
        <EmptyOrder
          title={"Empty Order"}
          description={t("Your order is empty, select some items from the menu first")}
        />
      ) : (
        <Box my={2}>
          <Container maxWidth="md">
            <Paper>
              <Stack p={2} spacing={1} direction="row">
                {optionsAndTags}
              </Stack>
            </Paper>
            <Box>
              <OrderOverviewCard
                products={order}
                activity={selectedActivity}
                total={calculateOrderPrice(order)}
              />
            </Box>
            <Stack spacing={2}>
              <TextField
                fullWidth
                id="outlined-basic"
                label={t("Email")}
                onChange={changeEmail}
                value={email}
                disabled={creatingOrder}
                InputProps={{
                  sx: { backgroundColor: theme.palette.background.default },
                }}
              />
              <SelectPaymentMethod
                onChange={setSelectedPaymentMethod}
                value={selectedPaymentMethod}
                paymentMethods={paymentMethods}
                disabled={creatingOrder}
              />
              <LoadingButton
                variant="contained"
                fullWidth
                loading={creatingOrder}
                disabled={order.length === 0 || creatingOrder || !isValid()}
                onClick={handlePostOrder}
              >
                {t("Place order of {{price}}", {
                  price: formatMoney(calculateOrderPrice(order)),
                })}
              </LoadingButton>
            </Stack>
          </Container>
        </Box>
      )}
      <PreviousOrders />
    </Box>
  );
};

export default CreateOrderTab;
