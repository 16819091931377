import { Remove, Add } from "@mui/icons-material";
import { Stack, IconButton, Box, Typography } from "@mui/material";
import { colors } from "helpers/Theme";
import { useState } from "react";

type Props = {
  value: number;
  max?: number;
  onChange: (newValue: number) => void;
};

const NumericInput = ({ value, onChange, max }: Props) => {
  const [counter, setCounter] = useState(value);

  const handleMinus = () => {
    if (counter > 0) {
      setCounter(counter - 1);
      onChange(counter - 1);
    }
  };

  const handlePlus = () => {
    if (max) {
      if (counter < max) {
        setCounter(counter + 1);
        onChange(counter + 1);
      }
    } else {
      setCounter(counter + 1);
      onChange(counter + 1);
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1} data-testid="numericInput" >
      <Box bgcolor="#D9D9D9" borderRadius={100}>
        <IconButton
          color="secondary"
          data-testid="min"
          onClick={handleMinus}
          sx={{ padding: 0.6 }}
        >
          <Remove fontSize="inherit" />
        </IconButton>
      </Box>
      <Box width={25} display="flex" justifyContent="center" flex={1}>
        <Typography variant="body1" fontWeight="bold">
          {counter}
        </Typography>
      </Box>
      <Box bgcolor="#C8F8E6" borderRadius={100}>
        <IconButton
          data-testid="plus"
          onClick={handlePlus}
          sx={{ color: colors.primary, padding: 0.6 }}
        >
          <Add fontSize="inherit" />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default NumericInput;
