import { Box } from "@mui/material";
import React from "react";
import { ActivityContext } from "contexts/ActivityContext";
import ReservationActivityInfo from "./components/ReservationActivityInfo";
import ReservationAlternativePricesAmount from "./components/ReservationAlternativePricesAmount";
import ReservationSelectDate from "./components/ReservationSelectDate";
import ReservationSelectTimeslot from "./components/ReservationSelectTimeslot";
import ReservationSelectOption from "./components/ReservationSelectOption";
import ReservationPeopleAmount from "./components/ReservationPeopleAmount";

const Reserve = () => {
  const { activity } = React.useContext(ActivityContext);

  return (
    activity && (
      <>
        <ReservationActivityInfo />
        {Boolean(activity.has_alternative_prices) ? (
          <ReservationAlternativePricesAmount />
        ) : (
          <ReservationPeopleAmount />
        )}
        <ReservationSelectDate />
        {activity.has_time_slots && <ReservationSelectTimeslot />}
        {activity.has_option_groups && <ReservationSelectOption />}
        <Box height={50} />
      </>
    )
  );
};

export default Reserve;
