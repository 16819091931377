import { CheckCircle, Cancel } from "@mui/icons-material";
import { Card, Stack, Typography, capitalize, Divider, Chip } from "@mui/material";
import { formatMoney, getCreatedDate } from "helpers/Shared";
import { Order } from "models/Order";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ReceiptOverviewCard = ({ item }: { item: Order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(item.id.toString());
  };

  const getStatusColor = () => {
    switch (item.status) {
      case "pending":
        return "warning";
      case "completed":
      case "paid":
      case "confirmed":
        return "primary";
      case "cancelled":
        return "error";

      default:
        return "default";
    }
  };

  return (
    <Card onClick={handleClick} key={item.id}>
      <Stack p={2} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography fontWeight="bold" fontFamily="Open Sans">
              {item.user.first_name} {item.user.last_name}
            </Typography>
            <Typography variant="caption">#{item.invoice_number}</Typography>
          </Stack>
          <Stack textAlign="right">
            <Typography fontWeight="bold" variant="h5" fontFamily="Open Sans">
              {formatMoney(item.total)}
            </Typography>
            <Typography
              variant="caption"
              color={item.paid_at ? "primary" : "error"}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {item.paid_at ? (
                <>
                  <CheckCircle fontSize="inherit" />
                  {t("Paid")}
                </>
              ) : (
                <>
                  <Cancel fontSize="inherit" />
                  {t("Not Paid")}
                </>
              )}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="caption">{getCreatedDate(item.created_at)}</Typography>
          <Chip label={capitalize(item.status)} size="small" color={getStatusColor()} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default ReceiptOverviewCard;
