import { Box, Typography, Stack } from "@mui/material";
import NumericInput from "components/controls/numeric-input/NumericInput";
import RichText from "components/generic/richtext-parser/RichText";
import { ActivityContext } from "contexts/ActivityContext";
import { formatMoney } from "helpers/Shared";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const ReservationPeopleAmount = () => {
  const { t } = useTranslation();
  const { activity, setSelectedPeopleAmount, selectedPeopleAmount } = useContext(ActivityContext);

  const handleAmountChange = (newValue: number) => setSelectedPeopleAmount(newValue);

  return (
    activity && (
      <Box marginY={3}>
        <Typography variant="h6">1. {activity.alternative_price_title ?? t('Amount of people')}</Typography>
        <RichText htmlString={activity.alternative_price_description} />
        <Stack direction="row" mt={1} justifyContent="space-between" alignItems="center">
          <Typography variant="body1" flex={1}>
            {t("Visitors")}
          </Typography>
          <Typography variant="body1" flex={1} textAlign="right" mr={3}>
            {formatMoney(activity.price)}
          </Typography>
          <NumericInput value={selectedPeopleAmount} onChange={handleAmountChange} max={15} />
        </Stack>
      </Box>
    )
  );
};

export default ReservationPeopleAmount;
