import { Box, Container, Icon } from "@mui/material";
import { colors } from "helpers/Theme";
import { useState } from "react";
import { MenuGroup } from "types/MenuGroups";
import QuickFilterStyles from "./QuickFilterStyles";

type Props = {
  menuGroups: MenuGroup[];
  onCategoryClick: (index: number) => void;
};

const QuickFilterTopBar = ({ menuGroups, onCategoryClick }: Props) => {
  const [selectedCategory, setSelectedCategory] = useState(0);

  const handleCategoryClick = (index: number) => {
    setSelectedCategory(index);
    onCategoryClick(index);
  };

  const renderCategoryTabs = () =>
    menuGroups.map((menuGroup, index) => {
      const scrollIntoView = () => handleCategoryClick(index);
      return (
        <QuickFilterStyles.StyledTab
          label={menuGroup.title}
          key={index}
          onClick={scrollIntoView}
          icon={
            <Icon
              baseClassName="fas"
              className={`fa-${menuGroup.category.icon ? menuGroup.category.icon : "circle-small"}`}
            />
          }
        />
      );
    });

  return (
    <Box position="sticky" top={0} bgcolor={colors.darkMedium} zIndex={10}>
      <Container maxWidth="md" sx={{ paddingX: 0 }}>
        <QuickFilterStyles.StyledTabs
          value={selectedCategory}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {renderCategoryTabs()}
        </QuickFilterStyles.StyledTabs>
      </Container>
    </Box>
  );
};

export default QuickFilterTopBar;
