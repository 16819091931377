import { ChevronRight } from "@mui/icons-material";
import { Box, Card, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const DashboardPage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box marginY={2}>
        <Typography variant="h5">{t("Dashboard")}</Typography>
        <Typography variant="body1">
          {t("Welcome to the dashboard. You can create orders here.")}
        </Typography>
      </Box>
    </Box>
  );
};

export const DashboardMenuRedirectCard = ({
  title,
  subText,
  img,
  onClick,
}: {
  title: string;
  subText: string;
  img: string;
  onClick: () => void;
}) => {
  return (
    <Card onClick={onClick} sx={{ cursor: "pointer" }}>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" p={1}>
        <Box borderRadius={5} overflow="hidden">
          <img src={img} alt={title} height="60" />
        </Box>
        <Stack flex={1}>
          <Typography fontWeight="bold">{title}</Typography>
          <Typography>{subText}</Typography>
        </Stack>
        <ChevronRight fontSize="large" />
      </Stack>
    </Card>
  );
};

export default DashboardPage;
