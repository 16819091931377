import { Box, TextField, Typography } from "@mui/material";
import TextInput from "components/controls/text-input/TextInput";
import { ActivityContext } from "contexts/ActivityContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const RemarkForm = () => {
  const { t } = useTranslation();
  const { remarks, setRemarks } = useContext(ActivityContext);

  const handleTextChange = (value: any) => setRemarks(value.target.value);

  return (
    <Box marginY={2}>
      <Typography variant="h6">{t("Remarks")}</Typography>
      <Typography variant="body1">
        {t("Any questions or remarks? Please fill them in below.")}
      </Typography>
      <Box marginY={2}>
        <TextInput multiline fullWidth minRows={3} onChange={handleTextChange} value={remarks} />
      </Box>
    </Box>
  );
};

export default RemarkForm;
