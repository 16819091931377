import { format } from "date-fns";
import { isEmpty, sum } from "lodash";
import { Room } from "models/Accommodation";
import { BookRoomDataType, BookRoomPostPayload } from "types/BookRoomPostDataType";
import { BookRoomSteps } from "types/BookRoomSteps";
import { SelectedProductObject } from "types/SelectedProductObject";
import RetreatApiClient from "./RetreatApiClient";

export const getNextStep = (currentStep: BookRoomSteps, hasProducts: boolean): BookRoomSteps => {
  switch (currentStep) {
    case BookRoomSteps.EXTRAS:
      return BookRoomSteps.CONFIRM;

    case BookRoomSteps.RESERVE:
      return hasProducts ? BookRoomSteps.EXTRAS : BookRoomSteps.CONFIRM;

    default:
      return BookRoomSteps.RESERVE;
  }
};

export const getPreviousStep = (currentStep: BookRoomSteps, hasProducts: boolean) => {
  switch (currentStep) {
    case BookRoomSteps.EXTRAS:
      return BookRoomSteps.RESERVE;

    case BookRoomSteps.CONFIRM:
      return hasProducts ? BookRoomSteps.EXTRAS : BookRoomSteps.RESERVE;

    default:
      return BookRoomSteps.RESERVE;
  }
};

export const calculateTotalPrice = (room: Room, rooms: number, extras: SelectedProductObject) => {
  const productsPrice = sum(
    Object.values(extras).map((value) => value.product.price * value.amount)
  );
  return room.price * rooms + productsPrice;
};

export const getAlternativeGuests = (alternativePriceMapped: any[]) => {
  return !isEmpty(alternativePriceMapped)
    ? alternativePriceMapped.map((alternativePrice) => ({
        id: alternativePrice.id,
        name: alternativePrice.name,
        people: alternativePrice.people,
        price: alternativePrice.price,
        total: 0,
      }))
    : [];
};

export const reserveRoom = (reservation: BookRoomDataType) => {
  const alternativeGuestsMapped = Object.values(reservation.alternativeGuests ?? {});
  let payload: BookRoomPostPayload = {
    room_id: reservation.roomId,
    email: reservation.email,
    first_name: reservation.firstName,
    last_name: reservation.lastName,
    phone: reservation.phoneNumber,
    payment_provider: reservation.paymentMethod.payment_provider,
    starts_at: format(reservation.dateRange.from as Date, "yyyy-MM-dd"),
    ends_at: format(reservation.dateRange.to as Date, "yyyy-MM-dd"),
    options: Object.values(reservation.products ?? {})
      .filter((product) => product.amount > 0)
      .map((product) => ({
        id: product.product.id,
        amount: product.amount,
      })),
    people: reservation.people ?? 0,
    ...(!isEmpty(alternativeGuestsMapped) && {
      guests: alternativeGuestsMapped.map((guest) => ({
        ...guest,
        people: guest.people,
      })),
    }),
  };
  return RetreatApiClient.reserveRoom(payload);
};

export const calculateDiscountedRoomPrice = (discount: number, originalPrice: number) => {
  return (1 - discount / 100) * originalPrice;
};

export const calculateNightlyRoomPrice = (prices: {
  averageNightlyPrice?: number;
  roomTotalPrice?: number;
  nights: number;
}) => {
  const { averageNightlyPrice, roomTotalPrice, nights } = prices;
  if (averageNightlyPrice) return averageNightlyPrice;
  if (roomTotalPrice) return roomTotalPrice / nights;
  return 0;
};
