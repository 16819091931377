import { Box, Divider, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useContext } from "react";
import { ActivityContext } from "contexts/ActivityContext";
import { calculateTotalPrice } from "helpers/ActivityReserveHelper";
import { sum } from "lodash";
import { useTranslation } from "react-i18next";
import { formatMoney } from "helpers/Shared";
import { formatInTimeZone } from "date-fns-tz";

const Summary = () => {
  const { t } = useTranslation();
  const {
    activity,
    selectedPeopleAmount,
    selectedDate,
    selectedTimeslot,
    selectedOptions,
    selectedProducts,
    selectedAlternativePrices,
  } = useContext(ActivityContext);

  return (
    activity && (
      <Box marginY={2}>
        <Typography variant="h6" marginY={1}>
          {t("Summary")}
        </Typography>
        <Box>
          <Typography variant="body2" fontWeight="bold">
            {activity.name}
          </Typography>
          <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
            <Typography variant="body2">{t("Date")}</Typography>
            <Typography variant="body2">{formatInTimeZone(selectedDate ?? new Date(), "utc", "PPP")}</Typography>
          </Stack>
          {Boolean(activity.has_time_slots) && (
            <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
              <Typography variant="body2">{t("Pickup time")}</Typography>
              <Typography variant="body2">{`${selectedTimeslot?.start_time} - ${selectedTimeslot?.end_time}`}</Typography>
            </Stack>
          )}
          {!activity.has_alternative_prices && (
            <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
              <Typography variant="body2" flex={1}>
                {t("Price")}
              </Typography>
              <Typography variant="body2" flex={1} textAlign="right">
                {`${selectedPeopleAmount} x ${formatMoney(activity.price)}`}
              </Typography>
              <Typography variant="body2" flex={1} textAlign="right">
                {formatMoney(activity.price * selectedPeopleAmount)}
              </Typography>
            </Stack>
          )}
        </Box>
        {selectedAlternativePrices && (
          <>
            <Divider />
            <Box marginY={1}>
              <Typography variant="body2" fontWeight="bold">
                {t("Prices")}
              </Typography>
              {Object.values(selectedAlternativePrices).map((price, index) => {
                if (price.amount <= 0) return null;
                return (
                  <Stack
                    direction="row"
                    spacing={1}
                    key={index}
                    marginY={1}
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" flex={1}>
                      {price.price.name}
                    </Typography>
                    <Typography variant="body2" flex={1} textAlign="right">
                      {`${price.amount} x ${formatMoney(price.price.price)}`}
                    </Typography>
                    <Typography variant="body2" flex={1} textAlign="right">
                      {formatMoney(price.price.price * price.amount)}
                    </Typography>
                  </Stack>
                );
              })}
            </Box>
          </>
        )}
        {selectedProducts && sum(Object.values(selectedProducts).map((value) => value.amount)) > 0 && (
          <>
            <Divider />
            <Box marginY={1}>
              <Typography variant="body2" fontWeight="bold">
                {t("Extras")}
              </Typography>
              {Object.values(selectedProducts).map((product, index) => {
                if (product.amount <= 0) return null;
                return (
                  <Stack
                    direction="row"
                    spacing={1}
                    key={index}
                    marginY={1}
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" flex={1}>
                      {product.product.name}
                    </Typography>
                    <Typography variant="body2" flex={1} textAlign="right">{`${
                      product.amount
                    } x ${formatMoney(product.product.price)}`}</Typography>
                    <Typography variant="body2" flex={1} textAlign="right">
                      {formatMoney(product.product.price * product.amount)}
                    </Typography>
                  </Stack>
                );
              })}
            </Box>
          </>
        )}
        {selectedOptions && (
          <>
            <Divider />
            <Box marginY={1}>
              <Typography variant="body2" fontWeight="bold">
                {t("Options")}
              </Typography>
              {Object.values(selectedOptions).map((option, index) => {
                return (
                  <Box key={index}>
                    <Stack
                      direction="row"
                      spacing={1}
                      key={index}
                      marginY={1}
                      justifyContent="space-between"
                    >
                      <Typography variant="body2" flex={1}>
                        {option.name}
                      </Typography>
                      <Typography variant="body2" flex={1}>
                        {option.options[option.selected].name}
                      </Typography>
                      <Typography variant="body2" flex={1} textAlign="right">
                        {formatMoney(option.options[option.selected].price)}
                      </Typography>
                    </Stack>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
        <Divider sx={{ borderStyle: "dashed", borderBottomWidth: "medium" }} />
        <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
          <Typography variant="body2" fontWeight="bold">
            {t("Total price")}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {formatMoney(
              calculateTotalPrice(
                activity.price,
                selectedAlternativePrices ?? {},
                selectedProducts ?? {},
                selectedOptions ?? {},
                activity.has_alternative_prices,
                selectedPeopleAmount
              )
            )}
          </Typography>
        </Stack>
      </Box>
    )
  );
};

export default Summary;
