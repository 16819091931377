import { CheckCircle, Error } from "@mui/icons-material";
import { Box, Link, Stack, Typography } from "@mui/material";
import { BookRoomContext } from "contexts/BookRoomContext";
import RetreatApiClient from "helpers/RetreatApiClient";
import { colors, BaseTheme } from "helpers/Theme";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRange } from "react-day-picker";
import Loader from "components/generic/loader/Loader";
import { isEmpty } from "lodash";
import { format } from "date-fns";

const CheckRoomAvailability = () => {
  const { t } = useTranslation();

  const {
    selectedDateRange,
    numberOfGuests,
    availabilityData,
    setAvailabilityData,
    selectedAlternativeGuests,
  } = useContext(BookRoomContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    async function fetchAvailability() {
      try {
        setError(null);
        setLoading(true);
        setAvailabilityData(null);
        const response = await RetreatApiClient.checkRoomTypeAvailability(
          selectedDateRange as DateRange,
          numberOfGuests
        );
        setAvailabilityData(response.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    if (selectedDateRange && selectedDateRange.to && selectedDateRange.from) {
      fetchAvailability();
    }
  }, [selectedDateRange, numberOfGuests, setAvailabilityData, selectedAlternativeGuests]);

  const renderAvailability = () => {
    if (loading) {
      return (
        <Box
          borderRadius={BaseTheme.shape.borderRadius}
          p={3}
          bgcolor={colors.greyLight}
          my={1}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <Loader />
        </Box>
      );
    }

    if (error) {
      return (
        <Box
          borderRadius={BaseTheme.shape.borderRadius}
          p={3}
          bgcolor={colors.errorLight}
          my={1}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          {t("Something went wrong... Please try again later")}
        </Box>
      );
    }

    if (availabilityData && selectedDateRange && selectedDateRange.to && selectedDateRange.from) {
      if (isEmpty(availabilityData.rooms)) {
        return (
          <Box borderRadius={BaseTheme.shape.borderRadius} p={3} bgcolor={colors.errorLight} my={1}>
            <Stack direction="row" spacing={1}>
              <Error htmlColor={colors.error} />
              <Typography>
                {t(
                  "No Hideaways available on these dates. Select another date or use this form to get on the waiting list."
                )}
                <br />
                <Link href={process.env.REACT_APP_NO_ROOMS_FORM_URL}>{t("Click here")}</Link>
              </Typography>
            </Stack>
          </Box>
        );
      }
      return (
        <Box borderRadius={BaseTheme.shape.borderRadius} p={3} bgcolor={colors.primaryLight} my={1}>
          <Stack spacing={1}>
            <Stack
              spacing={0.5}
              direction="row"
              display="flex"
              alignItems="center"
              color={colors.primary}
            >
              <CheckCircle fontSize="inherit" />
              <Typography fontWeight="bold" variant="body2" color={colors.primary}>
                {t("{{count}} Hideaways available on these dates.", {
                  count: availabilityData?.rooms.length ?? 0,
                })}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="caption">{t("Checkin date")}</Typography>
                <Typography fontWeight="bold">
                  {selectedDateRange?.from && format(selectedDateRange?.from as Date, "eee d MMM")}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="caption">{t("Checkout date")}</Typography>
                <Typography fontWeight="bold">
                  {selectedDateRange?.to && format(selectedDateRange?.to as Date, "eee d MMM")}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="caption">{t("Nights")}</Typography>
                <Typography fontWeight="bold">
                  {t("{{count}} nights", { count: Number(availabilityData.nights) })}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      );
    }

    return (
      <Box borderRadius={BaseTheme.shape.borderRadius} p={3} bgcolor={colors.greyLight} my={1}>
        <Stack direction="row" spacing={1}>
          <Error htmlColor={colors.darkMedium} />
          <Typography>
            {t(
              "Please select your check-in and check-out date to see the nightly rates and availability."
            )}
          </Typography>
        </Stack>
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h6">{t("Availability")}</Typography>
      {renderAvailability()}
    </Box>
  );
};

export default CheckRoomAvailability;
