import { useLocation } from "react-router-dom";

export const useMatchCurrentTab = (patterns: readonly string[]) => {
  const { pathname } = useLocation();
  let match = patterns[0];
  patterns.forEach((pattern) => {
    if (pathname.includes(pattern)) match = pattern;
  });
  return match;
};
