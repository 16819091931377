import { Box, Stack, Typography, useTheme } from "@mui/material";
import { differenceInDays, format } from "date-fns";
import { colors, BaseTheme } from "helpers/Theme";
import { isEmpty } from "lodash";
import { OrderActivityAlternativePrice } from "models/Order";
import { useTranslation } from "react-i18next";
import { DateRange } from "react-day-picker";
import { RoomType } from "models/RoomType";
import { formatInTimeZone } from "date-fns-tz";

type Props = {
  totalPrice?: number | string;
  invoiceNumber?: string;
  reservationDate?: Date;
  reservationPickupTimeStart?: Date;
  reservationPickupTimeEnd?: Date;
  formattedTimeslot?: string;
  alternativePrices?: OrderActivityAlternativePrice[];
  options?: any[];
  productCount?: number;
  people?: number;
  dateRange?: DateRange;
  rooms?: number;
  roomType?: RoomType;
  onViewDetailsClick?: () => void;
};

const QuickSummary = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    totalPrice,
    invoiceNumber,
    reservationDate,
    reservationPickupTimeStart,
    reservationPickupTimeEnd,
    alternativePrices,
    people,
    dateRange,
    rooms,
    roomType,
    productCount,
    formattedTimeslot,
    onViewDetailsClick,
  } = props;

  return (
    <Box borderRadius={BaseTheme.shape.borderRadius} bgcolor={theme.palette.mode === "dark" ? colors.darkMedium : colors.greyLight} p={3}>
      <Stack direction="row" spacing={2} mb={1}>
        <Stack spacing={2} flex={3}>
          {!isEmpty(alternativePrices) ? (
            <Box>
              <Typography variant="body2">{t("Guests")}</Typography>
              {alternativePrices?.map(
                (alternativePrice) =>
                  alternativePrice.people > 0 && (
                    <Typography variant="body1" fontWeight="bold">
                      {alternativePrice.people}x {alternativePrice.name}
                    </Typography>
                  )
              )}
            </Box>
          ) : (
            <Box>
              <Typography variant="body2">{t("Guests")}</Typography>
              <Typography variant="body1" fontWeight="bold">
                {t("{{count}} guest", { count: people })}
              </Typography>
            </Box>
          )}
          {dateRange && (
            <Stack spacing={2}>
              <Box>
                <Typography variant="body2">{t("Checkin")}</Typography>
                <Typography variant="body1" fontWeight="bold">
                  {formatInTimeZone(dateRange.from as Date, "utc", "ccc LLLL do Y")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">{t("Checkout")}</Typography>
                <Typography variant="body1" fontWeight="bold">
                  {formatInTimeZone(dateRange.to as Date, "utc", "ccc LLLL do Y")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">{t("Nights")}</Typography>
                <Typography variant="body1" fontWeight="bold">
                  {t("{{count}} nights", {
                    count: differenceInDays(dateRange.to as Date, dateRange.from as Date),
                  })}
                </Typography>
              </Box>
            </Stack>
          )}
          {reservationDate && (
            <Box>
              <Typography variant="body2">{t("Reservation date")}</Typography>
              <Typography variant="body1" fontWeight="bold">
                {formatInTimeZone(new Date(reservationDate), "utc", "ccc LLLL do Y")}
              </Typography>
            </Box>
          )}
          {reservationPickupTimeStart && reservationPickupTimeEnd && (
            <Box>
              <Typography variant="body2">{t("Pickup time")}</Typography>
              <Typography variant="body1" fontWeight="bold">
                {formattedTimeslot
                  ? formattedTimeslot
                  : `${formatInTimeZone(
                      new Date(reservationPickupTimeStart as Date),
                      "utc",
                      "kk:mm"
                    )} - ${formatInTimeZone(
                      new Date(reservationPickupTimeEnd as Date),
                      "utc",
                      "kk:mm"
                    )}`}
              </Typography>
            </Box>
          )}
          {formattedTimeslot && (
            <Box>
              <Typography variant="body2">{t("Pickup time")}</Typography>
              <Typography variant="body1" fontWeight="bold">
                {formattedTimeslot}
              </Typography>
            </Box>
          )}
        </Stack>
        <Stack spacing={2} flex={2}>
          {invoiceNumber && (
            <Box>
              <Typography variant="body2">{t("Order number")}</Typography>
              <Typography variant="body1" fontWeight="bold">
                #{invoiceNumber}
              </Typography>
            </Box>
          )}
          {roomType && (
            <Box>
              <Typography variant="body2">{t("Room")}</Typography>
              <Typography variant="body1" fontWeight="bold">
                {roomType.name}
              </Typography>
            </Box>
          )}
          {totalPrice && (
            <Box>
              <Typography variant="body2">{t("Total price")}</Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                fontFamily="Open Sans"
              >
                {totalPrice}
              </Typography>
              {onViewDetailsClick && (
                <Typography
                  color="primary"
                  variant="body2"
                  onClick={onViewDetailsClick}
                  sx={{ cursor: "pointer" }}
                >
                  {t("View details →")}
                </Typography>
              )}
            </Box>
          )}
        </Stack>
      </Stack>
      {isEmpty(productCount) && (
        <Typography variant="caption" fontStyle="italic" textAlign="center">
          {t("*You have added {{count}} option to your booking, view them below.", {
            count: productCount ?? 0,
          })}
        </Typography>
      )}
    </Box>
  );
};

export default QuickSummary;
