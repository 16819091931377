import { OrderProduct } from "models/Order";
import { CreateOrderPayload } from "types/CreateOrderData";
import RetreatApiClient from "./RetreatApiClient";

export const generateCreateOrderPayload = (
  activityId: number,
  email: string,
  paymentSlug: string,
  products: any[],
  callbackUrl: string,
  options?: {
    tags?: string[];
    options?: any;
    remarks?: string;
  }
): CreateOrderPayload => {
  return {
    activity_id: activityId,
    email: email,
    payment_provider: paymentSlug,
    products: products.map((product: OrderProduct) => ({
      id: product.id,
      quantity: product.quantity,
    })),
    ends_at: new Date(),
    starts_at: new Date(),
    people: 1,
    first_name: "QR",
    last_name: "Code",
    phone: "123456789",
    callback_url: callbackUrl,
    remarks: options?.remarks,
    tags: options?.tags?.map((tag: any) => tag.name),
    options: options?.options
      ?.filter((option: any) => option.activity_id === activityId)
      .map((option: any) => ({ id: option.id })),
  };
};

export const getActivitiesBySlugs = (slugs: string[]) => {
  const promises = slugs.map((slug) => RetreatApiClient.getActivityLinkMenu(slug));
  return Promise.all(promises).then((response) => response.map((r) => r.data.data));
};
