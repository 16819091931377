import i18n from "i18n";
import { useParams, useOutlet } from "react-router-dom";
import { Languages } from "types/Languages";

const LocaleLayout = () => {
  const params = useParams();
  const outlet = useOutlet();

  if (i18n.language !== params.locale) {
    const isCompatibleLanguage = Object.values(Languages).includes(params.locale as Languages);
    i18n.changeLanguage(isCompatibleLanguage ? params.locale : Languages.ENGLISH);
  }

  return outlet;
};

export default LocaleLayout;
