import { Box, Divider, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { calculateNightlyRoomPrice, calculateTotalPrice } from "helpers/BookRoomHelper";
import { formatMoney } from "helpers/Shared";
import { sum } from "lodash";
import { Room } from "models/Accommodation";
import { OrderProduct } from "models/Order";
import { RoomType } from "models/RoomType";
import { useTranslation } from "react-i18next";
import { SelectedProductObject } from "types/SelectedProductObject";
import { formatInTimeZone } from "date-fns-tz";

type Props = {
  checkInDate: Date;
  checkOutDate: Date;
  nights: number;
  guests: number;
  room: Room;
  roomType: RoomType;
  rooms: number;
  extras?: SelectedProductObject;
  totalPrice?: number;
  roomTotalPrice?: number;
  options?: (OrderProduct & { amount: number })[];
};

const Summary = ({
  checkInDate,
  checkOutDate,
  nights,
  guests,
  room,
  roomType,
  rooms,
  extras,
  totalPrice,
  roomTotalPrice,
  options,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} mb={2}>
      <Typography variant="h6" marginY={1}>
        {t("Summary")}
      </Typography>
      <Box>
        <Typography variant="body2" fontWeight="bold">
          {t("Details")}
        </Typography>
        <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
          <Typography variant="body2">{t("Check in")}</Typography>
          <Typography variant="body2">{formatInTimeZone(checkInDate, "utc", "PPPP")}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
          <Typography variant="body2">{t("Check out")}</Typography>
          <Typography variant="body2">{formatInTimeZone(checkOutDate, "utc", "PPPP")}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
          <Typography variant="body2">{t("Nights")}</Typography>
          <Typography variant="body2">{t("{{count}} night", { count: nights })}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
          <Typography variant="body2">{t("Guests")}</Typography>
          <Typography variant="body2">{t("{{count}} guest", { count: guests })}</Typography>
        </Stack>
      </Box>
      <Divider />
      <Box>
        <Typography variant="body2" fontWeight="bold">
          {t("{{count}} night stay", { count: nights })}
        </Typography>
        <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
          <Typography variant="body2" flex={1}>
            {roomType.name ?? t("Hideaway")}
          </Typography>
          <Typography variant="body2" flex={2} textAlign="right">
            {t("{{price}} per night", {
              price: formatMoney(
                calculateNightlyRoomPrice({
                  averageNightlyPrice: room.average_nightly_price,
                  roomTotalPrice,
                  nights,
                })
              ),
            })}
          </Typography>
          <Typography variant="body2" flex={1} textAlign="right">
            {formatMoney(roomTotalPrice ?? room.price)}
          </Typography>
        </Stack>
        <Divider />
        <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
          <Typography variant="body2" fontWeight="bold">
            {t("Total for {{count}} nights", { count: nights })}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {formatMoney(roomTotalPrice ?? room.price)}
          </Typography>
        </Stack>
      </Box>
      {extras && sum(Object.values(extras).map((value) => value.amount)) > 0 && (
        <>
          <Divider />
          <Box marginY={1}>
            <Typography variant="body2" fontWeight="bold">
              {t("Extras")}
            </Typography>
            {Object.values(extras).map((product, index) => {
              if (product.amount <= 0) return null;
              return (
                <Stack
                  direction="row"
                  spacing={1}
                  key={index}
                  marginY={1}
                  justifyContent="space-between"
                >
                  <Typography variant="body2" flex={1}>
                    {product.product.name}
                  </Typography>
                  <Typography variant="body2" flex={1} textAlign="right">{`${
                    product.amount
                  } x ${formatMoney(Number(product.product.price))}`}</Typography>
                  <Typography variant="body2" flex={1} textAlign="right">
                    {formatMoney(product.product.price * product.amount)}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        </>
      )}
      {options && (
        <>
          <Divider />
          <Box marginY={1}>
            <Typography variant="body2" fontWeight="bold">
              {t("Extras")}
            </Typography>
            {options.map((product, index) => {
              if (product.amount <= 0) return null;
              return (
                <Stack
                  direction="row"
                  spacing={1}
                  key={index}
                  marginY={1}
                  justifyContent="space-between"
                >
                  <Typography variant="body2" flex={1}>
                    {product.name}
                  </Typography>
                  <Typography variant="body2" flex={1} textAlign="right">
                    {`${product.amount} x ${formatMoney(Number(product.price))}`}
                  </Typography>
                  <Typography variant="body2" flex={1} textAlign="right">
                    {formatMoney(product.price * product.amount)}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        </>
      )}
      <Divider
        sx={extras || options ? { borderStyle: "dashed", borderBottomWidth: "medium" } : {}}
      />
      <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
        <Typography variant="body2" fontWeight="bold">
          {t("Total price")}
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          {formatMoney(totalPrice ?? calculateTotalPrice(room, rooms, extras ?? {}))}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Summary;
