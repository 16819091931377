import { colors } from "helpers/Theme";
import { DayPicker, DayPickerRangeProps } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./range-picker.css";

const RangePicker = (props: Partial<DayPickerRangeProps>) => {
  return (
    <DayPicker
      mode="range"
      style={{ fontFamily: "Open Sans, Roboto" }}
      modifiersStyles={{
        disabled: {},
        today: {
          border: "1px solid currentColor",
          fontWeight: "inherit",
          borderColor: colors.greyLight,
        },
        selected: { color: colors.white, backgroundColor: colors.primary },
        range_start: { backgroundColor: colors.primary },
        range_middle: { backgroundColor: colors.primaryLight, color: colors.dark },
        range_end: { backgroundColor: colors.primary },
      }}
      {...props}
    />
  );
};

export default RangePicker;
