import { Box, Typography, Stack, FormGroup, FormControlLabel, Checkbox, Link } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { isValidEmail } from "helpers/ActivityReserveHelper";
import Summary from "./components/Summary";
import { Trans, useTranslation } from "react-i18next";
import TextInput from "components/controls/text-input/TextInput";
import SelectPaymentMethod from "components/SelectPaymentMethod";
import QuickSummary from "components/QuickSummary";
import { formatMoney } from "helpers/Shared";
import { BookRoomContext } from "contexts/BookRoomContext";
import { differenceInDays } from "date-fns";
import { sum } from "lodash";
import { calculateTotalPrice, getAlternativeGuests } from "helpers/BookRoomHelper";
import { Room } from "models/Accommodation";
import { RoomType } from "models/RoomType";

const Confirm = () => {
  const { t } = useTranslation();
  const {
    selectedAlternativeGuests,
    selectedDateRange,
    selectedRoomType,
    numberOfGuests,
    numberOfRooms,
    selectedProducts,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    creatingReservation,
    readTermsAndConditions,
    setReadTermsAndConditions,
  } = useContext(BookRoomContext);

  const [emailError, setEmailError] = useState("");

  const fullSummaryRef = useRef<any>();

  const handleFirstNameChange = (e: any) => setFirstName(e.target.value);
  const handleLastNameChange = (e: any) => setLastName(e.target.value);
  const handlePhoneNumberChange = (e: any) => setPhoneNumber(e.target.value);
  const handleEmailChange = (e: any) => setEmail(e.target.value);

  const handleEmailBlur = () =>
    setEmailError(isValidEmail(email) ? "" : t("Invalid email address"));

  const numberOfNights = differenceInDays(
    selectedDateRange?.to as Date,
    selectedDateRange?.from as Date
  );

  const renderQuickSummary = () => {
    return (
      <QuickSummary
        totalPrice={formatMoney(
          calculateTotalPrice(
            selectedRoomType?.cheapest_room as Room,
            numberOfRooms,
            selectedProducts ?? {}
          )
        )}
        alternativePrices={getAlternativeGuests(Object.values(selectedAlternativeGuests ?? {}))}
        dateRange={selectedDateRange}
        people={numberOfGuests}
        rooms={numberOfRooms}
        roomType={selectedRoomType as RoomType}
        productCount={sum(Object.values(selectedProducts ?? {}).map((p) => (p.amount > 0 ? 1 : 0)))}
        onViewDetailsClick={scrollToFullSummary}
      />
    );
  };

  const scrollToFullSummary = () => {
    fullSummaryRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleReadTermsAndConditionsClick = (e: any) => {
    setReadTermsAndConditions(e.target.checked);
  };

  return (
    <Box marginY={2}>
      {renderQuickSummary()}
      <Box marginY={2}>
        <Typography variant="h6">{t("Payment method")}</Typography>
        <Typography variant="body1">
          {t("Payment is done via our secure payment gateaway partners Paguelofacil and Stripe.")}
        </Typography>
        <SelectPaymentMethod
          onChange={setSelectedPaymentMethod}
          value={selectedPaymentMethod}
          exclude={["bill", "cash"]}
          disabled={creatingReservation}
        />
      </Box>
      <Box marginY={2}>
        <Typography variant="h6">{t("Review & Payment")}</Typography>
        <Typography variant="body1">
          {t(
            "Add your contact details and payment information here. You will receive a confirmation e-mail."
          )}
        </Typography>
        <Stack spacing={2} marginY={2}>
          <Stack direction="row" spacing={2}>
            <TextInput
              label={t("First name")}
              onChange={handleFirstNameChange}
              value={firstName}
              fullWidth
              autoComplete="given-name"
              disabled={creatingReservation}
            />
            <TextInput
              label={t("Last name")}
              onChange={handleLastNameChange}
              fullWidth
              value={lastName}
              autoComplete="family-name"
              disabled={creatingReservation}
            />
          </Stack>
          <TextInput
            label={t("Email")}
            onChange={handleEmailChange}
            value={email}
            autoComplete="email"
            onBlur={handleEmailBlur}
            error={emailError !== ""}
            helperText={emailError}
            disabled={creatingReservation}
          />
          <TextInput
            label={t("Phone Number")}
            onChange={handlePhoneNumberChange}
            value={phoneNumber}
            autoComplete="tel"
            disabled={creatingReservation}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={readTermsAndConditions}
                  onChange={handleReadTermsAndConditionsClick}
                />
              }
              label={
                <Trans
                  i18nKey={t("I have read and agreed to the <a>terms and conditions</a>.")}
                  components={{
                    a: (
                      <Link
                        href={process.env.REACT_APP_TERMS_CONDITIONS_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              }
            />
          </FormGroup>
        </Stack>
      </Box>
      <Box ref={fullSummaryRef}>
        <Summary
          checkInDate={selectedDateRange?.from as Date}
          checkOutDate={selectedDateRange?.to as Date}
          guests={
            numberOfGuests <= 0
              ? sum(Object.values(selectedAlternativeGuests ?? {}).map((p) => p.people))
              : numberOfGuests
          }
          nights={numberOfNights}
          room={selectedRoomType?.cheapest_room as Room}
          roomType={selectedRoomType as RoomType}
          rooms={numberOfRooms}
          extras={selectedProducts ?? {}}
        />
      </Box>
      <Box height={50} />
    </Box>
  );
};

export default Confirm;
