import { ArrowBack, Cancel, CheckCircle } from "@mui/icons-material";
import { Box, Container, Paper, Stack, Typography, capitalize, Link } from "@mui/material";
import { colors } from "helpers/Theme";
import useFetch from "hooks/useFetch";
import { Order } from "models/Order";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import OrderDetailsCard from "./OrderDetailsCard";
import LoadingContainer from "components/LoadingContainer";
import { formatInTimeZone } from "date-fns-tz";

const OrderDetails = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const { data } = useFetch(`orders/${params.orderId}`);

  const order = data as Order;

  if (!order) {
    return (
      <>
        <LoadingContainer />
      </>
    );
  }

  const getPaymentStatus = () => {
    if (order.paid_at) {
      return {
        title: t("Paid"),
        subtext: (
          <Trans
            i18nKey="Paid with <strong>{{ paymentProvider }}</strong> on {{ dateTime }}."
            components={{ strong: <strong /> }}
            values={{
              paymentProvider: capitalize(order.payment_provider ?? ""),
              dateTime: formatInTimeZone(new Date(order.paid_at), "utc", "PP p"),
            }}
          />
        ),
        icon: <CheckCircle color="primary" />,
        color: colors.primary,
      };
    }
    return {
      title: t("Not paid yet"),
      subtext: (
        <Trans
          i18nKey="Client pays with <strong>{{ paymentProvider }}</strong>."
          components={{ strong: <strong /> }}
          values={{
            paymentProvider: capitalize(order.payment_provider ?? ""),
          }}
        />
      ),
      icon: <Cancel color="error" />,
      color: colors.error,
    };
  };

  const paymentStatus = getPaymentStatus();

  return (
    <Container maxWidth="md">
      <Box>
        <Stack my={2} alignItems="center" flexDirection="row" onClick={() => navigate(-1)}>
          <ArrowBack />
          <Typography justifyContent="center">{t("Back")}</Typography>
        </Stack>
      </Box>
      <Box marginY={2}>
        <Paper>
          <Stack spacing={2} p={2}>
            <Stack>
              <Typography variant="h5">{t("Order")}</Typography>
              <Typography>#{order.invoice_number}</Typography>
              {order.transaction_id && (
                <Link href={"../receipts/" + order.transaction_id}>
                  {t("View customer receipt")}
                </Link>
              )}
            </Stack>
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {order.user.first_name} {order.user.last_name}
              </Typography>
              <Typography>{order.user.email}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {paymentStatus.icon}
              <Stack>
                <Typography variant="body1" color={paymentStatus.color}>
                  {paymentStatus.title}
                </Typography>
                <Typography variant="caption">{paymentStatus.subtext}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Box>
      {order.remarks && (
        <Box marginY={2}>
          <Paper>
            <Stack p={2}>
              <Typography variant="body1" fontWeight="bold">
                {t("Remarks")}
              </Typography>
              <Typography>{order.remarks}w</Typography>
            </Stack>
          </Paper>
        </Box>
      )}
      <OrderDetailsCard order={order} />
      <Box height={50} />
    </Container>
  );
};

export default OrderDetails;
