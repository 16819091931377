import { Box, Typography, TextField, InputAdornment, IconButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format, addMonths } from "date-fns";
import { useContext, useState } from "react";
import { ActivityContext } from "contexts/ActivityContext";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { CalendarMonth } from "@mui/icons-material";
import { colors } from "helpers/Theme";

const ReservationSelectDate = () => {
  const { t } = useTranslation();
  const { activity, selectedDate, setSelectedDate } = useContext(ActivityContext);

  const [calendarOpen, setCalendarOpen] = useState(false);

  const { data, loading, error } = useFetch("open/activities/availability/check", {
    activity_id: activity ? activity.id : 0,
    starts_at: format(new Date(), "yyyy-MM-dd"),
    ends_at: format(addMonths(new Date(), 1), "yyyy-MM-dd"),
  });

  const disableDate = (day: Date) => {
    const formattedDate = format(day, "yyyy-MM-dd");
    if (data && data.available_dates && data.available_dates[formattedDate]) {
      return !data.available_dates[formattedDate].available;
    }
    return true;
  };

  const handleOpenCalendar = () => setCalendarOpen(true);
  const handleCloseCalendar = () => setCalendarOpen(false);

  return (
    <Box marginY={3}>
      <Typography variant="h6" mt={2}>
        2. {t("Select a date")}
      </Typography>
      <Typography variant="body1" mt={1}>
        {t("Select the date you want to visit.")}
      </Typography>
      <Box marginY={2}>
        <DatePicker
          open={calendarOpen}
          onOpen={handleOpenCalendar}
          onClose={handleCloseCalendar}
          label={
            loading ? t("Loading...") : error ? t("Something went wrong...") : t("Pick a date")
          }
          disablePast
          value={selectedDate}
          onChange={setSelectedDate}
          disabled={loading}
          inputFormat="PPPP"
          showToolbar={false}
          shouldDisableDate={disableDate}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={error ? true : false}
              InputProps={{
                ...params.InputProps,
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <CalendarMonth />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { backgroundColor: colors.textInput },
              }}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
              onClick={handleOpenCalendar}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default ReservationSelectDate;
