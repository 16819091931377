import { CheckCircle, Cancel } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  capitalize,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import TextInput from "components/controls/text-input/TextInput";
import QRCodeScanner from "components/QRCodeScanner";
import QuickSummary from "components/QuickSummary";
import RetreatApiClient from "helpers/RetreatApiClient";
import { formatMoney } from "helpers/Shared";
import { useAuth } from "hooks/useAuth";
import { Order } from "models/Order";
import QrScanner from "qr-scanner";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

function ScanCodePage() {
  const { t } = useTranslation();

  const { user } = useAuth();

  const [manualMode, setManualMode] = useState(false);
  const [inputText, setInputText] = useState("");

  const [data, setData] = useState<Order | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleScan = (result: QrScanner.ScanResult) => {
    if (!!result) {
      setLoading(true);
      !data && fetchOrder(result.data);
    }
  };

  const handleManualInput = () => {
    setLoading(true);
    fetchOrder(inputText);
  };

  const fetchOrder = (value: string) => {
    setError(null);
    RetreatApiClient.getOrderByInvoiceNumber(value)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        setError(err);
        setToastOpen(true);
        setToastMessage(t("No order found with that invoice number."));
      })
      .finally(() => setLoading(false));
  };

  const handleComplete = () => {
    if (data) {
      setError(null);
      RetreatApiClient.completeOrderByInvoiceNumber(data.invoice_number, user.token)
        .then((res) => {
          setToastMessage(res.data.data.message ?? t("Order completed"));
          setData(null);
        })
        .catch((err) => {
          setError(err);
          setToastMessage(t("Error: order could not be completed"));
        })
        .finally(() => {
          setToastOpen(true);
        });
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
    setToastMessage("");
    setError(null);
  };

  const toggleManualInput = (event: any) => {
    setManualMode(event.target.checked);
  };

  return (
    <Container maxWidth="md">
      <Stack spacing={2} my={2}>
        <Typography variant="h5">{t("Scan code")}</Typography>
        {!data && (
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={manualMode} onChange={toggleManualInput} />}
                label={t("Enter code manually")}
              />
            </FormGroup>
            {manualMode ? (
              <FormControl onSubmit={handleManualInput} fullWidth>
                <Stack spacing={1}>
                  <TextInput
                    value={inputText}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setInputText(event.target.value)
                    }
                  />
                  <Button
                    variant="contained"
                    onClick={handleManualInput}
                    disabled={inputText === ""}
                  >
                    {t("Find order")}
                  </Button>
                </Stack>
              </FormControl>
            ) : (
              <Container maxWidth="sm">
                {loading ? <Box>Loading</Box> : <QRCodeScanner onScan={handleScan} />}
              </Container>
            )}
          </Box>
        )}
        {data && (
          <Stack spacing={2}>
            <Typography variant="h6">{data.activity.name}</Typography>
            <QuickSummary
              totalPrice={formatMoney(data.total)}
              invoiceNumber={data.invoice_number}
              reservationDate={data.starts_at}
              reservationPickupTimeStart={data.starts_at}
              reservationPickupTimeEnd={data.ends_at}
              people={data.people}
              alternativePrices={data.activity_alternative_prices}
            />
            <Stack alignItems="center" direction="row" spacing={1}>
              {data.paid_at ? (
                <CheckCircle color="primary" fontSize="small" />
              ) : (
                <Cancel color="error" />
              )}
              <Typography variant="body2" color={data.paid_at ? "primary" : "error"}>
                {data.paid_at
                  ? t("Paid with {{provider}}", { provider: capitalize(data.payment_provider) })
                  : t("Not paid yet")}
              </Typography>
            </Stack>
            <Button fullWidth variant="contained" onClick={handleComplete}>
              {t("Complete order")}
            </Button>
            <Button fullWidth onClick={() => setData(null)}>
              {t("Scan new order")}
            </Button>
          </Stack>
        )}
      </Stack>
      <Snackbar
        open={toastOpen}
        onClose={handleClose}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={error ? "error" : "success"} sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ScanCodePage;
