import { Box } from "@mui/material";
import Loader from "components/generic/loader/Loader";

const LoadingContainer = () => (
  <Box
    height="100vh"
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{ opacity: 0.5 }}
  >
    <Loader />
  </Box>
);

export default LoadingContainer;
