import { MenuBook, TableBar, ShoppingCartCheckout, Receipt } from "@mui/icons-material";
import { Paper, BottomNavigation, BottomNavigationAction, Badge, Container } from "@mui/material";
import { SelectMenuActivityContext } from "contexts/WaiterOrderFoodContext";
import { useMatchCurrentTab } from "hooks/useMatchCurrentTab";
import { useContext } from "react";
import { Link } from "react-router-dom";

const FoodBottomNavigation = () => {
  const { order } = useContext(SelectMenuActivityContext);

  const currentTab = useMatchCurrentTab(["/menu", "/orders", "/receipts", "/create-order"]);

  return (
    <Container maxWidth="md">
      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels value={currentTab}>
          <BottomNavigationAction
            label="Menu"
            icon={<MenuBook />}
            value="/menu"
            to="menu"
            component={Link}
          />
          <BottomNavigationAction
            label="Orders"
            icon={<TableBar />}
            value="/orders"
            to="orders"
            component={Link}
          />
          <BottomNavigationAction
            label="Receipts"
            icon={<Receipt />}
            value="/receipts"
            to="receipts"
            component={Link}
          />
          <BottomNavigationAction
            label="Basket"
            icon={
              <Badge badgeContent={order.length} color="primary">
                <ShoppingCartCheckout />
              </Badge>
            }
            value="/create-order"
            to="create-order"
            component={Link}
          />
        </BottomNavigation>
      </Paper>
    </Container>
  );
};

export default FoodBottomNavigation;
