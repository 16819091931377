import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, capitalize, Divider, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { formatMoney } from "helpers/Shared";
import { isEmpty, sumBy } from "lodash";
import { Order } from "models/Order";
import { useTranslation } from "react-i18next";
import { formatInTimeZone } from "date-fns-tz";

const OrderSummary = ({ order }: { order: Order }) => {
  const { t } = useTranslation();

  const renderPrices = () => {
    if (!isEmpty(order.activity_alternative_prices)) {
      return (
        <Box>
          {order.activity_alternative_prices.map((price, index) => {
            if (price.people <= 0) return null;
            return (
              <Stack
                key={index}
                direction="row"
                spacing={1}
                marginY={2}
                justifyContent="space-between"
              >
                <Typography variant="body2" flex={1}>
                  {price.name}
                </Typography>
                <Typography variant="body2" textAlign="right" flex={1}>
                  {formatMoney(price.price)} x {price.people}
                </Typography>
                <Typography variant="body2" textAlign="right" flex={1}>
                  {formatMoney(price.total)}
                </Typography>
              </Stack>
            );
          })}
        </Box>
      );
    }

    return (
      <Box>
        <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
          <Typography variant="body2" flex={1}>
            {t("Visitors")}
          </Typography>
          <Typography variant="body2" textAlign="right" flex={1}>
            {formatMoney(order.activity.price)} x {order.people}
          </Typography>
          <Typography variant="body2" textAlign="right" flex={1}>
            {formatMoney(order.activity_total)}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const renderProducts = () => {
    return (
      sumBy(order.products, "quantity") > 0 && (
        <>
          <Divider />
          <Box marginY={1}>
            <Typography variant="body2" fontWeight="bold">
              {t("Products")}
            </Typography>
            {order.products.map((product, index) => {
              if (product.quantity === 0) return null;
              return (
                <Stack
                  key={index}
                  direction="row"
                  spacing={1}
                  marginY={2}
                  justifyContent="space-between"
                >
                  <Typography variant="body2" flex={1}>
                    {product.name}
                  </Typography>
                  <Typography variant="body2" textAlign="right" flex={1}>
                    {formatMoney(product.price)} x {product.quantity}
                  </Typography>
                  <Typography variant="body2" textAlign="right" flex={1}>
                    {formatMoney(product.total ?? product.price * product.quantity)}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        </>
      )
    );
  };

  const renderOptions = () => {
    return (
      !isEmpty(order.activity_options) && (
        <>
          <Divider />
          <Box marginY={1}>
            <Typography variant="body2" fontWeight="bold">
              {t(" Options")}
            </Typography>
            {order.activity_options.map((option, index) => {
              return (
                <Stack
                  key={index}
                  direction="row"
                  spacing={1}
                  marginY={2}
                  justifyContent="space-between"
                >
                  <Typography variant="body2" flex={1}>
                    {option.group_name}
                  </Typography>
                  <Typography variant="body2" textAlign="right" flex={1}>
                    {option.name}
                  </Typography>
                  <Typography variant="body2" textAlign="right" flex={1}>
                    {formatMoney(option.price)}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        </>
      )
    );
  };

  const renderRemarks = () => {
    return (
      order.remarks && (
        <>
          <Divider />
          <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
            <Typography variant="body2" flex={1}>
              {t("Remarks")}
            </Typography>
            <Typography variant="body2" flex={2}>
              {order.remarks}
            </Typography>
          </Stack>
        </>
      )
    );
  };

  return (
    <Box>
      <Box marginY={2}>
        <Typography variant="h6">{t("Contact Details")}</Typography>
        <Stack direction="row" spacing={1} marginY={2} justifyContent="space-between">
          <Typography variant="body2">{t("Email address")}</Typography>
          <Typography variant="body2">{order.user.email}</Typography>
        </Stack>
        {order.user.phone && (
          <Stack direction="row" spacing={1} marginY={2} justifyContent="space-between">
            <Typography variant="body2">{t("Phonenumber")}</Typography>
            <Typography variant="body2">{order.user.phone}</Typography>
          </Stack>
        )}
        <Stack direction="row" spacing={1} marginY={2} justifyContent="space-between">
          <Typography variant="body2">{t("Full name")}</Typography>
          <Typography variant="body2">
            {order.user.first_name} {order.user.last_name}
          </Typography>
        </Stack>
      </Box>
      <Typography variant="h6">{t("Summary")}</Typography>
      <Stack alignItems="center" direction="row" spacing={1}>
        {order.paid_at ? (
          <CheckCircle color="primary" fontSize="small" />
        ) : (
          <Cancel color="error" />
        )}
        <Typography variant="body2" color={order.paid_at ? "primary" : "error"}>
          {order.paid_at
            ? t("Paid with {{provider}}", { provider: capitalize(order.payment_provider) })
            : t("Not paid yet")}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} marginY={2} justifyContent="space-between">
        <Typography variant="body2">{t("Date")}</Typography>
        <Typography variant="body2">
          {formatInTimeZone(new Date(order.starts_at), "utc", "EEEE, LLLL d")}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} marginY={2} justifyContent="space-between">
        <Typography variant="body2">{t("Time")}</Typography>
        <Typography variant="body2">
          {formatInTimeZone(new Date(order.starts_at), "utc", "kk:mm")} -{" "}
          {formatInTimeZone(new Date(order.ends_at), "utc", "kk:mm")}
        </Typography>
      </Stack>
      {renderPrices()}
      {renderProducts()}
      {renderOptions()}
      {renderRemarks()}
      <Divider sx={{ borderStyle: "dashed", borderBottomWidth: "medium" }} />
      <Stack direction="row" spacing={1} marginY={1} justifyContent="space-between">
        <Typography variant="body2" fontWeight="bold">
          {t("Total price")}
        </Typography>
        <Typography variant="body2" fontWeight="bold">
          {formatMoney(order.total)}
        </Typography>
      </Stack>
    </Box>
  );
};

export default OrderSummary;
