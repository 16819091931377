import { AppBar, Toolbar, Container, Box } from "@mui/material";

type Props = {
  rightComponent?: React.ReactNode;
  onClickLogo?: () => void;
};

const Header = ({ rightComponent, onClickLogo }: Props) => {
  const handleOnClickLogo = () => {
    if (onClickLogo) {
      onClickLogo();
    }
  };
  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box flexGrow={1}>
            <img
              src={require("assets/images/happy-green-retreat-logo.png")}
              alt="logo"
              width={120}
              onClick={handleOnClickLogo}
            />
          </Box>
          {rightComponent && rightComponent}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
