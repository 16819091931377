import { compareDesc, formatDistanceToNowStrict, sub, format } from "date-fns";

export const formatMoney = (price: number, currency = "$") => {
  return currency + price.toFixed(2);
};

export const getCreatedDate = (createdAt: Date) => {
  const oneDayBackFromNow = sub(new Date(), { days: 1 });
  const isOlderThanOneDay = compareDesc(oneDayBackFromNow, new Date(createdAt));
  return isOlderThanOneDay === 1
    ? formatDistanceToNowStrict(new Date(createdAt), { addSuffix: true })
    : format(new Date(createdAt), "PPP");
};
