import { Stack, Typography, Box, Button } from "@mui/material";
import YappyInstructions from "components/YappyInstructions";
import RetreatApiClient from "helpers/RetreatApiClient";
import { BaseTheme, colors } from "helpers/Theme";
import { Order } from "models/Order";
import { useTranslation } from "react-i18next";

const ReceiptPaymentStatus = ({ receipt }: { receipt: Order }) => {
  const { t } = useTranslation();

  const fetchPaymentLink = () => {
    RetreatApiClient.requestReceiptPaymentLink(receipt.id).then((response) => {
      window.location.assign(response.data.data.link);
    });
  };

  const renderStatus = () => {
    switch (receipt.status) {
      case "completed":
      case "paid":
      case "confirmed":
        return null;

      case "pending":
        if (receipt.payment_provider === "yappy") {
          return <YappyInstructions order={receipt} />;
        }
        return (
          <Stack spacing={1} alignItems="center">
            <Typography variant="h6" textAlign="center">
              {t("Payment required")}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t(
                "Your order is currently only a reservation. Make sure you pay before someone else books your reserved date!"
              )}
            </Typography>
            <Button variant="contained" onClick={fetchPaymentLink}>
              {t("Proceed to payment")}
            </Button>
          </Stack>
        );

      default:
        break;
    }
  };
  return (
    <Box marginY={3} borderRadius={BaseTheme.shape.borderRadius} bgcolor={colors.primaryLight} p={3}>
      {renderStatus()}
    </Box>
  );
};

export default ReceiptPaymentStatus;
