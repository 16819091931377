import { generateNewOrderObject } from "helpers/MenuActivityHelper";
import useFetch from "hooks/useFetch";
import useOneSignal from "hooks/useOneSignal";
import { Activity, Option } from "models/Activity";
import { OrderProduct } from "models/Order";
import { createContext, ReactNode, useState } from "react";
import { useParams } from "react-router-dom";

type SelectMenuActivityContextType = {
  menu: any;
  activity: Activity | undefined;
  tags: any;
  order: OrderProduct[];
  setOrder: React.Dispatch<React.SetStateAction<OrderProduct[]>>;
  tag: { id: number; name: string } | null;
  setTag: React.Dispatch<React.SetStateAction<{ id: number; name: string } | null>>;
  selectedOptionGroups: { [id: string]: Option } | null;
  setSelectedOptionGroups: React.Dispatch<React.SetStateAction<{ [id: string]: Option } | null>>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  changeOrder: (currentOrder: OrderProduct[], product: OrderProduct, newValue: number) => void;
};

export const SelectMenuActivityContext = createContext<SelectMenuActivityContextType>({
  menu: null,
  tags: null,
  activity: undefined,
  order: [],
  setOrder: () => null,
  tag: null,
  setTag: () => null,
  selectedOptionGroups: null,
  setSelectedOptionGroups: () => null,
  remarks: "",
  setRemarks: () => null,
  changeOrder: () => null,
});

type ActivityData = {
  data: Activity;
  loading: boolean;
  error: unknown;
};

export const SelectMenuActivityProvider = ({ children }: { children: ReactNode }) => {
  const params = useParams();

  const [order, setOrder] = useState<OrderProduct[]>([]);
  const [tag, setTag] = useState<{ id: number; name: string } | null>(null);
  const [selectedOptionGroups, setSelectedOptionGroups] = useState<{ [id: string]: Option } | null>(
    null
  );
  const [remarks, setRemarks] = useState("");

  const { data }: ActivityData = useFetch(`open/activities/${params.activitySlug}`);

  useOneSignal({ role: "waiter" });

  const changeOrder = (currentOrder: OrderProduct[], product: OrderProduct, newValue: number) => {
    setOrder(generateNewOrderObject(currentOrder, product, newValue));
  };

  return (
    <SelectMenuActivityContext.Provider
      value={{
        menu: data?.products ?? [],
        tags: data?.tags ?? [],
        order,
        activity: data,
        setOrder,
        tag,
        setTag,
        selectedOptionGroups,
        setSelectedOptionGroups,
        remarks,
        setRemarks,
        changeOrder,
      }}
    >
      {children}
    </SelectMenuActivityContext.Provider>
  );
};
