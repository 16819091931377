import { Box, Container, Stack, Typography } from "@mui/material";
import DisplayImageCarousel from "components/DisplayImageCarousel";
import QuickSummary from "components/QuickSummary";
import { formatMoney } from "helpers/Shared";
import { colors } from "helpers/Theme";
import useFetch from "hooks/useFetch";
import { Room } from "models/Accommodation";
import { Order, OrderProduct } from "models/Order";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Summary from "views/book-room-page/steps/confirm/components/Summary";
import BookingPaymentStatus from "./components/BookingPaymentStatus";

const BookingStatusPage = () => {
  const { t } = useTranslation();
  const params = useParams();

  const fullSummaryRef = useRef<any>();

  const { data } = useFetch(`open/bookings/${params.bookingNumber}/status`);

  const booking = data as Order & {
    nights: number;
    room: Room;
    room_total: number;
    options: (OrderProduct & { amount: number })[];
  };

  if (!booking) {
    return <></>;
  }

  const scrollToFullSummary = () => {
    fullSummaryRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container maxWidth="md">
      <DisplayImageCarousel
        gallery={[]}
        displayImage={{
          id: 10,
          file_name: "yoga-at-the-beach.jpg",
          mime_type: "image/jpeg",
          size: 53265,
          url: "https://dev-retreat.happy.green/media/15",
          thumbnail_url: "https://dev-retreat.happy.green/media/15/thumbnail",
        }}
      />
      <Stack
        spacing={2}
        marginTop={-5}
        padding={2}
        paddingTop={3}
        marginX={-2}
        marginBottom={10}
        position="relative"
        bgcolor={colors.white}
        sx={{ borderTopLeftRadius: 25, borderTopRightRadius: 25 }}
      >
        <Box>
          <Typography variant="h5">{t("Stay at the Happy.Green Retreat")}</Typography>
        </Box>
        <QuickSummary
          dateRange={{ from: new Date(booking.starts_at), to: new Date(booking.ends_at) }}
          people={booking.people}
          roomType={booking.room.type}
          invoiceNumber={booking.invoice_number}
          totalPrice={formatMoney(booking.total)}
          onViewDetailsClick={scrollToFullSummary}
        />
        <BookingPaymentStatus bookingOrder={booking} />
        <Stack spacing={1}>
          <Typography variant="h6" marginY={1}>
            {t("Your details")}
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1">{t("Full name")}</Typography>
            <Typography variant="body1">
              {booking.user.first_name} {booking.user.last_name}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1">{t("Email address")}</Typography>
            <Typography variant="body1">{booking.user.email}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1">{t("Phone number")}</Typography>
            <Typography variant="body1">{booking.user.phone}</Typography>
          </Stack>
        </Stack>
        <Box ref={fullSummaryRef}>
          <Summary
            checkInDate={new Date(booking.starts_at)}
            checkOutDate={new Date(booking.ends_at)}
            guests={booking.people}
            nights={booking.nights}
            room={booking.room}
            roomType={booking.room.type}
            totalPrice={booking.total}
            roomTotalPrice={booking.room_total}
            options={booking.options}
            rooms={2}
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default BookingStatusPage;
