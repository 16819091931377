import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { ClientOrderContext } from "contexts/ClientOrderContext";
import { Activity } from "models/Activity";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const ClientChooseActivityView = () => {
  const { activities, setSelectedActivity, clearOrder } = useContext(ClientOrderContext);
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Stack spacing={2} my={2}>
        {activities.map((activity: Activity, index: number) => {
          const handleSelectActivity = () => {
            if (activity.booking_mode !== "MENU") {
              navigate("/book/activities/" + activity.slug);
            } else {
              setSelectedActivity(activity);
              clearOrder();
              navigate("../menu");
            }
          };
          return (
            <ChooseActivityCard key={index} activity={activity} onClick={handleSelectActivity} />
          );
        })}
      </Stack>
    </Container>
  );
};

const ChooseActivityCard = ({ activity, onClick }: { activity: Activity; onClick: () => void }) => {
  return (
    <Card onClick={onClick}>
      <CardActionArea>
        <CardMedia component="img" height="140" image={activity.display_image.url} alt="activty" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {activity.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {activity.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ClientChooseActivityView;
