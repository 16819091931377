import { LoadingButton } from "@mui/lab";
import { Box, Paper, Container, Stack, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  loading: boolean;
  onBack(): void;
  onNext(): void;
  isValid?: boolean;
  showBackButton?: boolean;
  nextButtonText?: string;
};

const BottomStepperNavigator = ({
  onBack,
  onNext,
  loading = false,
  isValid = true,
  showBackButton,
  nextButtonText = "Next",
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box position="fixed" bottom={0} left={0} width="100%" zIndex={100}>
      <Paper elevation={4} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
        <Container maxWidth="sm">
          <Stack direction="row" p={2} spacing={2} justifyContent="center">
            {showBackButton && (
              <Button variant="outlined" onClick={onBack} disabled={loading} sx={{ width: "40%" }}>
                {t("Back")}
              </Button>
            )}
            <LoadingButton
              variant="contained"
              onClick={onNext}
              disabled={loading || !isValid}
              fullWidth
              loading={loading}
            >
              {nextButtonText}
            </LoadingButton>
          </Stack>
        </Container>
      </Paper>
    </Box>
  );
};

export default BottomStepperNavigator;
