import { useMediaQuery } from "@mui/material";
import DesktopDateRangePicker from "./desktop/DesktopDateRangePicker";
import MobileDateRangePicker from "./mobile/MobileDateRangePicker";

const DateRangePicker = (props: any) => {
  const { desktopModeMediaQuery = "@media (pointer: fine)", ...attributes } = props;

  const isDesktop = useMediaQuery(desktopModeMediaQuery, { defaultMatches: true });

  if (isDesktop) {
    return <DesktopDateRangePicker {...attributes} />;
  }

  return <MobileDateRangePicker {...attributes} />;
};

export default DateRangePicker;
