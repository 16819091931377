const trackRoomBooked = (price: number, invoiceNumber: string, currency: string = "USD") => {
  gtag("event", "room-booked", {
    value: price,
    currency: currency,
    transaction_id: invoiceNumber,
  });
};

const trackActivityBooked = (price: number, invoiceNumber: string, currency: string = "USD") => {
  gtag("event", "activity-booked", {
    value: price,
    currency: currency,
    transaction_id: invoiceNumber,
  });
};

const Analytics = { trackRoomBooked, trackActivityBooked };

export default Analytics;
