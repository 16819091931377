import {
  Stack,
  Autocomplete,
  Box,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  Container,
  Chip,
  FormControlLabel,
  Switch,
  useTheme,
} from "@mui/material";
import LoadingContainer from "components/LoadingContainer";
import { SelectMenuActivityContext } from "contexts/WaiterOrderFoodContext";
import RetreatApiClient from "helpers/RetreatApiClient";
import { formatMoney } from "helpers/Shared";
import { colors } from "helpers/Theme";
import { useAuth } from "hooks/useAuth";
import { isEmpty, sumBy } from "lodash";
import { Activity, Option } from "models/Activity";
import { Order } from "models/Order";
import { useContext, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CreateReceiptDialog from "../components/create-receipt-dialog/CreateReceiptDialog";
import OrderSummaryCard from "../components/OrderSummaryCard";

const OrdersListTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { tags, activity } = useContext(SelectMenuActivityContext);

  const {
    option_groups: optionGroups,
    has_option_groups: hasOptionGroups,
    id: activityId,
  } = activity as Activity;

  const { user } = useAuth();

  const [tag, setTag] = useState<{ id: number; name: string } | null>(null);
  const [selectedOptionGroups, setSelectedOptionGroups] = useState<{ [id: string]: Option } | null>(
    null
  );
  const [showPaid, setShowPaid] = useState(false);

  const [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState<Order[]>([]);
  const [ordersError, setOrdersError] = useState(null);

  const [checkedOrders, setCheckedOrders] = useState<Order[]>([]);

  const [createReceiptDialogOpen, setCreateReceiptDialogOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    RetreatApiClient.getOrders(
      {
        option: Object.values(selectedOptionGroups ?? {}).map((option) => option?.id),
        tags: tag?.name,
        activity: activityId,
        ...(showPaid ? { paid: true } : { notpaid: true }),
      },
      user.token
    )
      .then((response) => {
        setOrders(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setOrdersError(error);
        setLoading(false);
      });
  }, [tag, user, activityId, selectedOptionGroups, showPaid]);

  useMemo(() => {
    if (hasOptionGroups) {
      const formattedOptionGroups = optionGroups.reduce(
        (prevValue, option) => ({ ...prevValue, [option.id]: null }),
        {}
      );
      setSelectedOptionGroups(formattedOptionGroups);
    }
  }, [hasOptionGroups, optionGroups]);

  const handleUpdateOptionGroups = (optionGroupId: number, newValue: any | null) => {
    setSelectedOptionGroups((prevValue: any) => {
      return { ...prevValue, [optionGroupId]: newValue };
    });
  };

  const handleOnCheckedOrdersChange = (order: Order, checked: boolean) => {
    if (checked) {
      setCheckedOrders([...checkedOrders, order]);
    } else {
      setCheckedOrders(checkedOrders.filter((checkedOrder) => checkedOrder.id !== order.id));
    }
  };

  const handleClose = () => setCreateReceiptDialogOpen(!createReceiptDialogOpen);

  const handleShowPaidSwitchChange = (e: React.SyntheticEvent, checked: boolean) =>
    setShowPaid(checked);

  const renderOptionGroups = () =>
    hasOptionGroups &&
    optionGroups.map((option) => {
      const handleChange = (event: any, newValue: Option | null) => {
        handleUpdateOptionGroups(option.id, newValue);
      };
      return (
        selectedOptionGroups && (
          <Grid xs={1} key={option.id}>
            <Autocomplete
              disablePortal
              id={`optiongroups-${option.id}`}
              options={option.options}
              getOptionLabel={(option: Option) => option.name}
              sx={{ flex: 3 }}
              value={selectedOptionGroups[option.id]}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={option.name}
                  InputProps={{
                    sx: { backgroundColor: theme.palette.background.default },
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>
        )
      );
    });

  const renderTags = () =>
    !isEmpty(tags) && (
      <Grid xs={1}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={tags}
          getOptionLabel={(option: { id: number; name: string }) => option.name}
          sx={{ flex: 3 }}
          value={tag}
          onChange={(event: any, newValue: { id: number; name: string } | null) => {
            setTag(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Tag")}
              InputProps={{
                sx: { backgroundColor: theme.palette.background.default },
                ...params.InputProps,
              }}
            />
          )}
        />
      </Grid>
    );

  const renderCreateReceiptButton = () =>
    !isEmpty(checkedOrders) && (
      <>
        <Box
          position="absolute"
          zIndex={100}
          width="100%"
          height="100%"
          top={0}
          left={0}
          bgcolor="rgba(0,0,0,0.5)"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100%"
          >
            <Chip
              onClick={() => setCreateReceiptDialogOpen(true)}
              sx={{ height: 50 }}
              clickable
              size="medium"
              color="primary"
              label={
                <Typography
                  fontFamily="Open Sans"
                  variant="body1"
                  fontWeight={400}
                  color={colors.white}
                  mx={2}
                >
                  <Trans
                    i18nKey=" <strong>Create receipt</strong> (Total {{ price }})"
                    components={{ strong: <strong /> }}
                    values={{
                      price: formatMoney(sumBy(checkedOrders, "total")),
                    }}
                  />
                </Typography>
              }
            />
          </Box>
        </Box>
        <CreateReceiptDialog
          orders={checkedOrders}
          open={createReceiptDialogOpen}
          onClose={handleClose}
        />
      </>
    );

  return (
    <Box>
      <Box
        bgcolor={theme.palette.mode === "dark" ? colors.darkMedium : colors.greyLight}
        paddingY={2}
        position="relative"
      >
        <Container maxWidth="md">
          <Grid container spacing={2} columns={2}>
            {renderOptionGroups()}
            {renderTags()}
          </Grid>
          <Box mt={1} ml={1}>
            <FormControlLabel
              control={<Switch />}
              onChange={handleShowPaidSwitchChange}
              label={t("Show paid")}
            />
          </Box>
          {renderCreateReceiptButton()}
        </Container>
      </Box>
      <Container maxWidth="md">
        {loading ? (
          <LoadingContainer />
        ) : (
          <Stack spacing={2} marginY={2}>
            {orders.map((order) => {
              const handleOnOrderClick = () => {
                navigate(order.id.toString());
              };
              return (
                <OrderSummaryCard
                  order={order}
                  key={order.id}
                  onCheckedChange={handleOnCheckedOrdersChange}
                  onCardClicked={handleOnOrderClick}
                  value={Boolean(checkedOrders.find((item) => item.id === order.id))}
                />
              );
            })}
          </Stack>
        )}
        <Box height={120} />
      </Container>
    </Box>
  );
};

export default OrdersListTab;
