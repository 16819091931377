import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Stack,
  Chip,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import RetreatApiClient from "helpers/RetreatApiClient";
import { useAuth } from "hooks/useAuth";
import useFetch from "hooks/useFetch";
import { differenceBy, isEmpty } from "lodash";
import { Activity, ActivityOption } from "models/Activity";
import { ActivityLink, ActivityLinkActivitySetting } from "models/ActivityLink";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  currentActivityLink?: ActivityLink;
  onSave?: (newValue: ActivityLink, update: boolean) => void;
  onClose: () => void;
};

const ActivityLinkDialog = ({ open, currentActivityLink, onClose, onSave }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [activityLink, setActivityLink] = useState<ActivityLink | null>(
    currentActivityLink ?? null
  );

  const [optionGroups, setOptionGroups] = useState<
    (ActivityOption & { activity_id: number; activity: Activity })[]
  >([]);

  useEffect(() => {
    if (currentActivityLink) {
      setActivityLink(currentActivityLink);
      // fetch optiongroups
      if (!isEmpty(currentActivityLink.settings.option_groups)) {
        currentActivityLink.settings.option_groups?.map((optionGroup) =>
          RetreatApiClient.fetchOptionGroup(optionGroup.parentOptionGroupId, user.token).then(
            (res) =>
              setOptionGroups((previousOptionGroups) => [...previousOptionGroups, res.data.data])
          )
        );
      }
    }
  }, [currentActivityLink, user.token]);

  const { data: activities } = useFetch("activities", { limit: 50 });
  const { data: tags } = useFetch("tags");

  const handleOnSave = () => {
    if (activityLink && onSave) {
      onSave(activityLink, currentActivityLink !== undefined);
    }
  };

  const handleOnClose = () => {
    setActivityLink(null);
    setOptionGroups([]);
    onClose();
  };

  const handleAddActivityToSettings = (activity: Activity) => {
    const selectedActivity = { id: activity.id, slug: activity.slug, name: activity.name };
    setActivityLink({
      ...activityLink,
      settings: {
        ...activityLink?.settings,
        activities: [...(activityLink?.settings?.activities ?? []), selectedActivity],
      },
    } as ActivityLink);

    // get option groups from activity (fetch or object) and push into array
    if (activity.has_option_groups) {
      const activityOptionGroups = activity.option_groups.map((optionGroup) => ({
        ...optionGroup,
        activity_id: activity.id,
        activity: activity,
      }));
      setOptionGroups([...optionGroups, ...activityOptionGroups]);
    }
  };

  const handleRemoveActivityFromSettings = (activity: ActivityLinkActivitySetting) => {
    setActivityLink({
      ...activityLink,
      settings: {
        ...activityLink?.settings,
        activities: activityLink?.settings?.activities?.filter(
          (settingActivity) => settingActivity.id !== activity.id
        ),
        // remove selected optiongrups if available
        option_groups: activityLink?.settings?.option_groups?.filter(
          (optionGroup) => optionGroup.activity_id !== activity.id
        ),
      },
    } as ActivityLink);
    const filteredOptionGroups = optionGroups.filter(
      (optionGroup) => optionGroup.activity_id !== activity.id
    );
    setOptionGroups(filteredOptionGroups);
  };

  const handleAddTagToSettings = (tag: any) => {
    const selectedTag = { id: tag.id, name: tag.name };
    setActivityLink({
      ...activityLink,
      settings: {
        ...activityLink?.settings,
        tags: [...(activityLink?.settings?.tags ?? []), selectedTag],
      },
    } as ActivityLink);
  };

  const handleRemoveTagFromSettings = (tag: ActivityLinkActivitySetting) => {
    setActivityLink({
      ...activityLink,
      settings: {
        ...activityLink?.settings,
        tags: activityLink?.settings?.tags?.filter((settingsTag) => settingsTag.id !== tag.id),
      },
    } as ActivityLink);
  };

  const handleSelectOption = (val: any, parentId: number, activityId: number) => {
    // filter out existing selected option by parent optiongroup id and push new value into new settings
    const newOptionGroupSetting =
      activityLink?.settings?.option_groups?.filter(
        (optionGroup) => optionGroup.parentOptionGroupId !== parentId
      ) ?? [];
    newOptionGroupSetting.push({
      id: val.id,
      name: val.name,
      slug: val.slug,
      parentOptionGroupId: parentId,
      activity_id: activityId,
    });

    setActivityLink({
      ...activityLink,
      settings: {
        ...activityLink?.settings,
        option_groups: newOptionGroupSetting,
      },
    } as ActivityLink);
  };

  const handleDeselectOption = (parentId: number) => {
    setActivityLink({
      ...activityLink,
      settings: {
        ...activityLink?.settings,
        option_groups: activityLink?.settings?.option_groups?.filter(
          (optionGroup) => optionGroup.parentOptionGroupId !== parentId
        ),
      },
    } as ActivityLink);
  };

  const handleNameChange = (val: any) => {
    setActivityLink({ id: 0, uuid: "0", ...activityLink, name: val.target.value } as ActivityLink);
  };

  const isDisabled = () => {
    return activityLink?.name === "" && !isEmpty(activityLink?.settings?.activities);
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle>{t("Activity Link Editor")}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <TextField
            label={t("Name")}
            value={activityLink?.name}
            onChange={handleNameChange}
            fullWidth
          />
          <Stack spacing={1}>
            <Typography>{t("Activities")}</Typography>
            <Grid2 container spacing={1}>
              {activityLink &&
                activityLink?.settings?.activities?.map((activity, index) => {
                  const handleDelete = () => {
                    handleRemoveActivityFromSettings(activity);
                  };
                  return (
                    <Grid2 key={index}>
                      <Chip label={activity.name} key={activity.id} onDelete={handleDelete}></Chip>
                    </Grid2>
                  );
                })}
            </Grid2>
            <Grid2 container spacing={1}>
              {activities &&
                differenceBy<Activity, ActivityLinkActivitySetting>(
                  activities,
                  activityLink?.settings?.activities ?? [],
                  "id"
                ).map((activity, index) => {
                  const handleSelect = () => {
                    handleAddActivityToSettings(activity);
                  };
                  return (
                    <Grid2 key={index}>
                      <Chip label={activity.name} key={activity.id} onClick={handleSelect}></Chip>
                    </Grid2>
                  );
                })}
            </Grid2>
          </Stack>

          {(!isEmpty(optionGroups) || activityLink?.settings?.option_groups) && (
            <Stack spacing={1}>
              <Typography>{t("Option Groups")}</Typography>
              {optionGroups.map((optionGroup, index) => {
                return (
                  <Stack spacing={1} key={index}>
                    <Typography>
                      {optionGroup.name} - {optionGroup.activity?.name ?? ""}
                    </Typography>
                    <Grid2 container spacing={1}>
                      {optionGroup.options.map((option, index) => {
                        const selected = activityLink?.settings.option_groups?.find(
                          (optionGroup) => optionGroup.id === option.id
                        );
                        const handleSelect = () => {
                          selected
                            ? handleDeselectOption(optionGroup.id)
                            : handleSelectOption(option, optionGroup.id, optionGroup.activity_id);
                        };
                        return (
                          <Grid2 key={index}>
                            <Chip
                              label={option.name}
                              key={option.id}
                              variant={selected ? "outlined" : "filled"}
                              color={selected ? "primary" : "default"}
                              onClick={handleSelect}
                            />
                          </Grid2>
                        );
                      })}
                    </Grid2>
                  </Stack>
                );
              })}
            </Stack>
          )}

          <Stack spacing={1}>
            <Typography>{t("Tags")}</Typography>
            <Grid2 container spacing={1}>
              {activityLink &&
                activityLink?.settings?.tags?.map((tag, index) => {
                  const handleDelete = () => {
                    handleRemoveTagFromSettings(tag);
                  };
                  return (
                    <Grid2 key={index}>
                      <Chip label={tag.name} key={tag.id} onDelete={handleDelete}></Chip>
                    </Grid2>
                  );
                })}
            </Grid2>
            <Grid2 container spacing={1}>
              {tags &&
                differenceBy<Activity, ActivityLinkActivitySetting>(
                  tags,
                  activityLink?.settings?.tags ?? [],
                  "id"
                ).map((tag, index) => {
                  const handleSelect = () => {
                    handleAddTagToSettings(tag);
                  };
                  return (
                    <Grid2 key={index}>
                      <Chip label={tag.name} key={tag.id} onClick={handleSelect}></Chip>
                    </Grid2>
                  );
                })}
            </Grid2>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>{t("Cancel")}</Button>
        <Button onClick={handleOnSave} disabled={isDisabled()}>
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivityLinkDialog;
