import { Box, Divider, Link, Paper, Stack, Typography } from "@mui/material";
import { Order, OrderProduct } from "models/Order";
import { useTranslation } from "react-i18next";
import { calculateReceiptTotal } from "helpers/SelectMenuActivityHelper";
import { formatMoney } from "helpers/Shared";
import { useMemo, useState } from "react";

const ReceiptSummaryCard = ({
  orders,
  showOrderLinks,
}: {
  orders: Order[];
  showOrderLinks?: boolean;
}) => {
  const { t } = useTranslation();

  const [combinedOrder, setCombinedOrder] = useState<any | null>(null);

  useMemo(() => {
    const combinedOrderObject = orders
      .map((order) => order.products)
      .flat()
      .reduce((result: { [id: number]: OrderProduct }, orderProduct) => {
        result[orderProduct.id] = {
          ...orderProduct,
          quantity:
            (result[orderProduct.id] ? result[orderProduct.id].quantity : 0) +
            orderProduct.quantity,
        };
        return result;
      }, {});
    setCombinedOrder(Object.values(combinedOrderObject));
  }, [orders]);

  return (
    <Box>
      <Paper>
        <Stack marginY={2} p={2}>
          <Stack>
            <Typography fontWeight="bold">{orders[0].activity.name}</Typography>
            <Typography variant="caption">
              {showOrderLinks && (
                <Box>
                  {t("Orders")}:{" "}
                  {orders.map((order) => (
                    <Link key={order.id} href={"../orders/" + order.id}>
                      #{order.invoice_number}{" "}
                    </Link>
                  ))}
                </Box>
              )}
            </Typography>
          </Stack>
          <Divider />
          <Stack my={1}>
            <Stack direction="row" justifyContent="justify-between">
              <Typography flex={1} variant="caption">
                {t("Item")}
              </Typography>
              <Typography flex={1} textAlign="right" variant="caption">
                {t("Quantity")}
              </Typography>
              <Typography flex={1} textAlign="right" variant="caption">
                {t("Total")}
              </Typography>
            </Stack>
          </Stack>
          {combinedOrder &&
            combinedOrder.map((order: OrderProduct) => (
              <Stack my={1} key={order.name}>
                <Stack direction="row" justifyContent="justify-between">
                  <Typography flex={1} variant="body1">
                    {order.name}
                  </Typography>
                  <Typography flex={1} textAlign="right" variant="body1">
                    {order.quantity}
                  </Typography>
                  <Typography flex={1} textAlign="right" variant="body1">
                    {formatMoney(order.price * order.quantity)}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          <Divider sx={{ borderStyle: "dashed", borderBottomWidth: "medium" }} />
          <Stack direction="row" marginY={2} justifyContent="space-between" alignItems="center">
            <Typography fontWeight="bold">{t("Total")}</Typography>
            <Typography fontWeight="bold" variant="h5" fontFamily="Open Sans">
              {formatMoney(calculateReceiptTotal(orders))}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default ReceiptSummaryCard;
