import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useMatchCurrentTab } from "hooks/useMatchCurrentTab";
import {
  AccountBox,
  CameraAlt,
  House,
  LocalActivity,
  MenuBook,
  QrCode,
  Restaurant,
} from "@mui/icons-material";
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { colors } from "helpers/Theme";
import ThemeSwitch from "components/controls/ThemeSwitch";

const drawerWidth = 240;

const DashboardLayout = () => {
  const outlet = useOutlet();
  const { t } = useTranslation();
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleNavigateToDashboard = () => navigate("../dashboard");

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    signOut();
    handleClose();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const currentPage = useMatchCurrentTab([
    "/dashboard",
    "waiter",
    "kitchen",
    "book-room",
    "activities",
    "scan",
    "activity-links",
  ]);

  const listItems = [
    {
      name: t("Dashboard"),
      icon: <House />,
      path: "/dashboard",
      roles: ["Admin", "Cook", "Waiter"],
    },
    { name: t("Waiter"), icon: <MenuBook />, path: "waiter", roles: ["Admin", "Waiter"] },
    {
      name: t("Kitchen"),
      icon: <Restaurant />,
      path: "kitchen",
      roles: ["Admin", "Kitchen", "Cook"],
    },
    {
      name: t("Activities"),
      icon: <LocalActivity />,
      path: "activities",
      roles: ["Admin", "Reception"],
    },
    {
      name: t("Book Room"),
      icon: <House />,
      path: "book-room",
      roles: ["Admin", "Reception"],
    },
    { name: t("Scan orders"), icon: <CameraAlt />, path: "scan", roles: ["Admin", "Reception"] },
    { name: t("Activity Links"), icon: <QrCode />, path: "activity-links", roles: ["Admin"] },
  ];

  const drawer = (
    <Box>
      <Toolbar color="secondary" />
      <Divider />
      <List>
        {listItems.map((listItem, index) => {
          const handleNavigate = () => {
            navigate(listItem.path);
            handleDrawerToggle();
          };
          const canShow = listItem.roles.includes(user.user.role.name) ?? false;
          return (
            canShow && (
              <ListItem
                disablePadding
                key={index}
                sx={{
                  backgroundColor: currentPage === listItem.path ? colors.white : "transparent",
                }}
              >
                <ListItemButton onClick={handleNavigate}>
                  <ListItemIcon
                    sx={{
                      color: currentPage === listItem.path ? colors.darkMedium : colors.white,
                    }}
                  >
                    {listItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      "& .MuiListItemText-primary": {
                        color: currentPage === listItem.path ? colors.darkMedium : colors.white,
                      },
                    }}
                  >
                    {listItem.name}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            )
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box display="flex">
      <AppBar
        color="secondary"
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box flexGrow={1}>
            <img
              src={require("assets/images/happy-green-retreat-logo.png")}
              alt="logo"
              width={120}
              onClick={handleNavigateToDashboard}
            />
          </Box>
          <Box>
            <Button variant="text" endIcon={<AccountBox />} onClick={handleMenu} color="inherit">
              {user.user.first_name}
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleSignout}>{t("Sign out")}</MenuItem>
            </Menu>
          </Box>
          <ThemeSwitch />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: colors.dark,
              color: colors.white,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: colors.dark,
              color: colors.white,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {outlet}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
