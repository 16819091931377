import { Box, Stack, Typography } from "@mui/material";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DashboardMenuRedirectCard } from "../DashboardPage";

const BookRoomsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data } = useFetch(`accommodations/${process.env.REACT_APP_ACCOMMODATION_ID}`);

  const handleNavigate = () => navigate("/book/room");

  return (
    <Box>
      <Stack marginY={2} spacing={1}>
        <Typography variant="h5">{t("Book Room")}</Typography>
        {data && (
          <DashboardMenuRedirectCard
            title={data.name}
            subText={data.description}
            img={data.display_image && data.display_image.url}
            onClick={handleNavigate}
          />
        )}
      </Stack>
    </Box>
  );
};

export default BookRoomsPage;
