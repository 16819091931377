import {
  Slide,
  DialogTitle,
  DialogContent,
  Stack,
  FormControlLabel,
  Switch,
  Button,
  Dialog,
  styled,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import TextInput from "components/controls/text-input/TextInput";
import SelectPaymentMethod from "components/SelectPaymentMethod";
import { calculateReceiptTotal, createReceipt } from "helpers/SelectMenuActivityHelper";
import { formatMoney } from "helpers/Shared";
import { colors } from "helpers/Theme";
import { useAuth } from "hooks/useAuth";
import { Order } from "models/Order";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReceiptSummaryCard from "./ReceiptSummaryCard";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  onClose: () => void;
  orders: Order[];
};

const CreateReceiptDialog = ({ open, onClose, orders }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();

  const [useClientInfo, setUseClientInfo] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any | null>(null);

  const handleChangeClientInfo = (e: React.SyntheticEvent, checked: boolean) =>
    setUseClientInfo(checked);

  const handleFirstNameChange = (e: any) => setFirstName(e.target.value);
  const handleLastNameChange = (e: any) => setLastName(e.target.value);
  const handleEmailChange = (e: any) => setEmail(e.target.value);

  const handleCreateReceipt = () => {
    setLoading(true);
    createReceipt({
      orders,
      createNewUser: useClientInfo,
      newUser: { email, firstName, lastName },
      token: user.token,
      userId: user.user.id,
      paymentProvider: selectedPaymentMethod?.payment_provider ?? "cash",
    })
      .then((response) => {
        setLoading(false);
        onClose();
        navigate(`../receipts/${response.data.data.id}`);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const isValid = () => {
    if (useClientInfo) {
      const clientInfoComplete = email && firstName && lastName;
      return !loading && clientInfoComplete && selectedPaymentMethod;
    }
    return !loading && selectedPaymentMethod;
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
      fullWidth
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle>{t("Create receipt")}</DialogTitle>
      <DialogContent>
        <Stack px={2}>
          <FormControlLabel
            control={<Switch />}
            onChange={handleChangeClientInfo}
            label={t("Add client information")}
          />
          {useClientInfo && (
            <Stack spacing={2}>
              <TextInput
                disabled={!useClientInfo}
                label={t("Email")}
                value={email}
                onChange={handleEmailChange}
                variant="outlined"
                autoComplete="email"
              />
              <Stack direction="row" spacing={2}>
                <TextInput
                  disabled={!useClientInfo}
                  label={t("First name")}
                  value={firstName}
                  onChange={handleFirstNameChange}
                  variant="outlined"
                  fullWidth
                  autoComplete="given-name"
                />
                <TextInput
                  disabled={!useClientInfo}
                  label={t("Last name")}
                  value={lastName}
                  onChange={handleLastNameChange}
                  variant="outlined"
                  fullWidth
                  autoComplete="family-name"
                />
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack p={2}>
          <Typography variant="body1">
            <Trans
              i18nKey="Pays <strong>{{ total }}</strong> with:"
              components={{ strong: <strong /> }}
              values={{
                total: `${formatMoney(calculateReceiptTotal(orders))}`,
              }}
            />
          </Typography>
          <SelectPaymentMethod
            onChange={setSelectedPaymentMethod}
            value={selectedPaymentMethod}
            exclude={["bill"]}
          />
        </Stack>
        <Box
          bgcolor={theme.palette.mode === "dark" ? colors.darkMedium : colors.greyLight}
          p={2}
          min-height="100%"
        >
          <ReceiptSummaryCard orders={orders} />
        </Box>
      </DialogContent>
      <Stack direction="row" p={2} spacing={2} justifyContent="center">
        <Button variant="outlined" onClick={onClose} disabled={loading} sx={{ width: "40%" }}>
          {t("Close")}
        </Button>
        <Button variant="contained" onClick={handleCreateReceipt} disabled={!isValid()} fullWidth>
          {t("Create receipt")}
        </Button>
      </Stack>
    </BootstrapDialog>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default CreateReceiptDialog;
