import { Box, Typography, Grid, Stack } from "@mui/material";
import { ActivityContext } from "contexts/ActivityContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { formatMoney } from "helpers/Shared";
import RetreatApiClient from "helpers/RetreatApiClient";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { ActivityOption, Option } from "models/Activity";
import { SelectedOptionObject } from "types/SelectedOptionObject";
import { BaseTheme, colors } from "helpers/Theme";
import { Error } from "@mui/icons-material";
import ChipRadioControl from "components/controls/chip-radio/ChipRadioControl";
import ChipRadioGroup from "components/controls/chip-radio/ChipRadioGroup";

const ReservationSelectOption = () => {
  const {
    activity,
    setSelectedOptions,
    selectedOptions,
    selectedDate,
    selectedPeopleAmount,
    selectedAlternativePrices,
    setOptionGroupsAvailabilityData,
    optionGroupsAvailabilityData,
  } = useContext(ActivityContext);

  const optionGroups = useMemo(
    () =>
      !isEmpty(optionGroupsAvailabilityData)
        ? optionGroupsAvailabilityData
        : activity?.option_groups,
    [optionGroupsAvailabilityData, activity]
  );

  const selectedOptionGroups = selectedOptions as SelectedOptionObject;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    async function fetchAvailability() {
      try {
        setError(null);
        setLoading(true);
        setOptionGroupsAvailabilityData(null);
        const response = await RetreatApiClient.checkOptionGroupsAvailability(
          selectedDate as Date,
          selectedDate as Date,
          selectedPeopleAmount,
          activity?.id ?? 0
        );
        setOptionGroupsAvailabilityData(response.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchAvailability();
  }, [
    activity?.id,
    selectedDate,
    selectedPeopleAmount,
    selectedAlternativePrices,
    setOptionGroupsAvailabilityData,
  ]);

  const handleOptionChange = (selectedOption: string, parentSlug: string) => {
    // TODO remove any
    setSelectedOptions((prevValue: any) => {
      if (prevValue)
        return {
          ...prevValue,
          [parentSlug]: { ...prevValue[parentSlug], selected: selectedOption },
        };
    });
  };

  const renderOptionGroups = () => {
    if (optionGroups && activity) {
      return optionGroups.map((optionGroup, index) => {
        return (
          <Stack key={index} my={1} spacing={1}>
            <Typography variant="h6">
              {activity.has_time_slots ? 4 + index : 3 + index}. {optionGroup.name}
            </Typography>
            {optionGroup.description && (
              <Typography variant="body1">{optionGroup.description}</Typography>
            )}
            {renderOptions(optionGroup.options, optionGroup)}
          </Stack>
        );
      });
    }
    return null;
  };

  const renderOptions = (options: Option[], parentOptionGroup: ActivityOption) => {
    const currentSelected = options.find(
      (option) => option.slug === selectedOptionGroups[parentOptionGroup.slug].selected
    );
    return (
      <Box>
        {currentSelected?.is_available === undefined
          ? false
          : !currentSelected.is_available && (
              <NotAvailableMessage stockLeft={currentSelected?.stock ?? 0} />
            )}
        <ChipRadioGroup
          defaultValue={options[0].slug}
          value={selectedOptionGroups[parentOptionGroup.slug].selected}
        >
          <Grid container marginY={1} columns={2} spacing={1}>
            {options.map((option, index) => {
              const handle = () => {
                handleOptionChange(option.slug, parentOptionGroup.slug);
              };
              return (
                <Grid item key={index}>
                  <ChipRadioControl
                    disabled={option.is_available === undefined ? false : !option.is_available}
                    label={`${option.name} - ${formatMoney(option.price)}`}
                    value={option.slug}
                    onClick={handle}
                  />
                </Grid>
              );
            })}
          </Grid>
        </ChipRadioGroup>
      </Box>
    );
  };

  return activity && <Box marginY={3}>{renderOptionGroups()}</Box>;
};

const NotAvailableMessage = ({ stockLeft }: { stockLeft: number }) => {
  const { t } = useTranslation();
  return (
    <Box borderRadius={BaseTheme.shape.borderRadius} p={2} bgcolor={colors.errorLight} my={1}>
      <Stack direction="row" spacing={1}>
        <Error htmlColor={colors.error} />
        <Typography>
          {stockLeft > 0
            ? t(
                "The maximum amount of people for this option is {{amount}}, please change the amount of people to {{amount}} or less. Or choose other possible option.",
                { amount: stockLeft }
              )
            : t("This option is sold out. Please select a different option if possible.")}
        </Typography>
      </Stack>
    </Box>
  );
};

export default ReservationSelectOption;
