import { Box, Button, Container, FormControl, Paper, Stack, Typography } from "@mui/material";
import TextInput from "components/controls/text-input/TextInput";
import { useAuth } from "hooks/useAuth";
import { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

const SignInPage = () => {
  const { t } = useTranslation();
  const { signIn, error } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    signIn(email, password);
  };

  return (
    <Container maxWidth="sm">
      <Box marginY={2}>
        <Paper>
          <form onSubmit={handleSubmit}>
            <Stack p={2} spacing={2}>
              <Typography variant="h6">{t("Sign in")}</Typography>
              <TextInput label={t("Email")} type="email" onChange={handleEmailChange} />
              <TextInput label={t("Password")} type="password" onChange={handlePasswordChange} />
              <Typography color="error">{error && error.error}</Typography>
              <Box textAlign="right">
                <Button variant="contained" onClick={handleSubmit} type="submit">
                  {t("Sign in")}
                </Button>
              </Box>
            </Stack>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default SignInPage;
