import { Box, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRange } from "react-day-picker";
import TextInput from "components/controls/text-input/TextInput";
import { CalendarMonth } from "@mui/icons-material";
import { colors } from "helpers/Theme";
import DateRangePicker from "components/controls/date-range-picker/DateRangePicker";
import { format } from "date-fns";
import NumericInput from "components/controls/numeric-input/NumericInput";
import { BookRoomContext } from "contexts/BookRoomContext";
import { isEmpty } from "lodash";
import { Guest } from "models/Accommodation";

const SelectDateRange = () => {
  const { t } = useTranslation();

  const {
    accommodation,
    selectedDateRange,
    setSelectedDateRange,
    numberOfGuests,
    setNumberOfGuests,
    selectedAlternativeGuests,
    setSelectedAlternativeGuests,
  } = useContext(BookRoomContext);

  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenDateRange = (event: React.MouseEvent<HTMLElement>) => {
    setOpenDateRangePicker(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDateRange = () => {
    setOpenDateRangePicker(false);
    setAnchorEl(null);
  };

  const handleGuestsChange = (guest: Guest, newValue: number) => {
    setSelectedAlternativeGuests((prevValue) => {
      return { ...prevValue, [guest.id]: { ...guest, people: newValue } };
    });
  };

  const formatDateRange = (dateRange: DateRange | undefined) => {
    if (dateRange?.from) {
      if (!dateRange.to) {
        return format(dateRange.from, "PPP");
      }
      return `${format(dateRange.from, "PPP")} – ${format(dateRange.to, "PPP")}`;
    }
    return "";
  };

  return (
    accommodation && (
      <Box>
        <Typography variant="h6">{t("Select the dates of your stay")}</Typography>
        <Typography variant="body1">{t("Select a day that you want to visit.")}</Typography>
        <Box marginY={2}>
          <TextInput
            placeholder={t("Check in - Check out")}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <CalendarMonth />
                  </IconButton>
                </InputAdornment>
              ),
              sx: { backgroundColor: colors.textInput },
            }}
            inputProps={{
              readOnly: true,
            }}
            onClick={handleOpenDateRange}
            value={formatDateRange(selectedDateRange)}
          />
          <DateRangePicker
            open={openDateRangePicker}
            onOpen={handleOpenDateRange}
            onClose={handleCloseDateRange}
            onSelectRange={setSelectedDateRange}
            selectedRange={selectedDateRange}
            disabledDays={[{ before: new Date() }]}
            fromMonth={new Date()}
            anchorEl={anchorEl}
          />
        </Box>
        {!isEmpty(accommodation.guests) ? (
          <Stack>
            {accommodation.guests.map((guest, index) => {
              const handleChange = (newValue: number) => {
                handleGuestsChange(guest, newValue);
              };
              return (
                selectedAlternativeGuests && (
                  <Stack
                    key={index}
                    direction="row"
                    mt={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" flex={1}>
                      {guest.name}
                    </Typography>
                    <NumericInput
                      value={selectedAlternativeGuests[guest.id].people}
                      onChange={handleChange}
                      max={15}
                    />
                  </Stack>
                )
              );
            })}
          </Stack>
        ) : (
          <Stack direction="row" mt={1} justifyContent="space-between" alignItems="center">
            <Typography variant="body1" flex={1}>
              {t("Guests")}
            </Typography>
            <NumericInput value={numberOfGuests} onChange={setNumberOfGuests} max={15} />
          </Stack>
        )}
      </Box>
    )
  );
};

export default SelectDateRange;
