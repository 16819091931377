import { Box, Typography, Stack, Pagination } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import useFetch from "hooks/useFetch";
import { Activity } from "models/Activity";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DashboardMenuRedirectCard } from "../DashboardPage";

const ActivitiesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [page, setPage] = useState(1);

  const { data, meta } = useFetch(
    "activities",
    {},
    { Authorization: `Bearer ${user.token}` },
    page
  );

  const handleOnChangePage = (e: any, value: number) => {
    setPage(value);
  };

  return (
    <Box marginY={2}>
      <Typography variant="h5">{t("Activities")}</Typography>
      <Stack spacing={2}>
        {data &&
          data.map((activity: Activity) => {
            const handleClick = () => navigate(`/book/activities/${activity.slug}`);
            return (
              <DashboardMenuRedirectCard
                key={activity.id}
                title={activity.name}
                subText={activity.category.name}
                img={activity.display_image?.thumbnail_url ?? ""}
                onClick={handleClick}
              />
            );
          })}
      </Stack>
      <Box marginY={2} display="flex" justifyContent="center">
        <Pagination count={meta?.last_page ?? 1} page={page} onChange={handleOnChangePage} />
      </Box>
    </Box>
  );
};

export default ActivitiesPage;
