import OneSignal from "react-onesignal";
import { useEffect, useState } from "react";

declare global {
  interface Window {
    OneSignal: any;
  }
}

type OneSignalOptions = {
  role: string;
};

const useOneSignal = ({ role }: OneSignalOptions) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (window.OneSignal === undefined && !initialized) {
      OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_KEY ?? "",
        allowLocalhostAsSecureOrigin: true,
      }).then(() => {
        setInitialized(true);
        OneSignal.showSlidedownPrompt().then(() => OneSignal.sendTag("role", role));
      });
    } else {
      OneSignal.sendTag("role", role);
    }
  }, []);
};

export default useOneSignal;
