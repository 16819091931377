import { Order } from "models/Order";
import produce from "immer";

const setKitchenStateIntoStorage = (state: KitchenState) => {
  localStorage.setItem("kitchen", JSON.stringify(state));
};

export type KitchenTableDynamicType = {
  id: number;
  slug: string;
  title: string; // Preparing orders
  statusToFetch: string[]; // ["preparing", "pending"]
  loading: boolean;
  data: Order[]; // [{id: 1, status: "pending"}, {id: 2, status: "preparing"}]
  meta: { last_page?: number; current_page: number };
  lastFetched: Date;
};

export type KitchenTableDynamicTypeKeyValue = { [key: string]: KitchenTableDynamicType };

export type KitchenState = {
  tables: KitchenTableDynamicTypeKeyValue;
  loading: boolean;
  error: any;
};

const getTableKey = (status: string) => {
  switch (status) {
    case "pending":
    case "paid":
      return "new";
    case "confirmed":
    case "processing":
      return "preparing";
    case "completed":
      return "completed";
    default:
      return "new";
  }
};

const KitchenReducer = produce((draft: KitchenState, action) => {
  switch (action.type) {
    case "startLoading":
      const startLoadingObject = draft.tables[action.payload.slug];
      startLoadingObject.loading = true;
      break;

    case "updateTableData":
      const updateTableDataObject = draft.tables[action.payload.slug];
      updateTableDataObject.data = action.payload.data;
      updateTableDataObject.loading = false;
      updateTableDataObject.meta = action.payload.meta;
      updateTableDataObject.lastFetched = new Date();
      setKitchenStateIntoStorage(draft);
      break;

    case "newOrder":
      draft.tables.new.data.unshift(action.payload.order);
      setKitchenStateIntoStorage(draft);
      break;

    case "updateOrder":
      draft.tables.new.data = draft.tables.new.data.filter(
        (order) => order.id !== action.payload.order.id
      );
      draft.tables.completed.data = draft.tables.completed.data.filter(
        (order) => order.id !== action.payload.order.id
      );
      draft.tables.preparing.data = draft.tables.preparing.data.filter(
        (order) => order.id !== action.payload.order.id
      );

      const tableKey = getTableKey(action.payload.order.status);
      draft.tables[tableKey].data.unshift(action.payload.order);

      setKitchenStateIntoStorage(draft);
      break;

    case "stopInitialLoading":
      draft.loading = false;
      setKitchenStateIntoStorage(draft);
      break;

    case "startInitialLoading":
      draft.loading = true;
      setKitchenStateIntoStorage(draft);
      break;

    case "resetDefaultTables":
      draft.tables = action.payload;
      setKitchenStateIntoStorage(draft);
      break;

    default:
      break;
  }
});

export default KitchenReducer;
