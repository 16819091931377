import { BoxProps, useRadioGroup, Box, useTheme } from "@mui/material";
import { colors } from "helpers/Theme";

type Props<T> = {
  value: T;
  onClick: (id: T) => void;
  disabled?: boolean;
  borderRadius?: number;
  padding?: number;
  borderWidth?: number;
};

const BoxRadioControl = <T,>(props: BoxProps & Props<T>) => {
  const radioGroup = useRadioGroup();
  const theme = useTheme();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  const handleChange = () => {
    props.onClick(props.value);
  };

  return (
    <Box
      {...props}
      onClick={handleChange}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor:
            theme.palette.mode === "dark" ? colors.darkMedium : colors.greyLight,
          borderColor: colors.primary,
        },
        ...(props.disabled && {
          pointerEvents: "none",
          backgroundColor: colors.greyLight + "AA",
          filter: "grayscale(0.8)",
        }),
      }}
      border={props.borderWidth ?? 1}
      borderColor={checked ? colors.primary : theme.palette.mode === "dark" ? colors.darkMedium : colors.greyLight}
      borderRadius={props.borderRadius ?? 1}
      padding={props.padding ?? 1}
    >
      {props.children}
    </Box>
  );
};

export default BoxRadioControl;
