import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const SkeletonTable = () => {
  return (
    <Stack spacing={3}>
      {[...Array(5)].map((i, index) => (
        <Stack
          key={index}
          direction="row"
          justifyContent="justify-between"
          height={50}
          spacing={2}
          alignItems="center"
          p={2}
        >
          <Box width={20}>
            <Skeleton />
          </Box>
          <Box flex={1}>
            <Skeleton />
          </Box>
          <Box flex={1}>
            <Skeleton />
          </Box>
          <Box flex={2}>
            <Skeleton />
          </Box>
          <Box flex={1}>
            <Skeleton />
          </Box>
          <Box flex={1}>
            <Skeleton />
          </Box>
          <Box width={20}>
            <Skeleton />
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};

export default SkeletonTable;
