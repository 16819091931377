import React, { createContext } from "react";
import { Activity, TimeSlot } from "models/Activity";
import { SelectedAlternativePriceObject } from "types/SelectedAlternativePriceObject";
import { SelectedOptionObject } from "types/SelectedOptionObject";
import { SelectedProductObject } from "types/SelectedProductObject";
import { OptionGroupsAvailability } from "models/OptionGroupsAvailability";

type ActivityContextType = {
  activity: Activity | null;
  optionGroupsAvailabilityData: OptionGroupsAvailability[] | null;
  setOptionGroupsAvailabilityData: React.Dispatch<
    React.SetStateAction<OptionGroupsAvailability[] | null>
  >;
  selectedPeopleAmount: number;
  setSelectedPeopleAmount: React.Dispatch<React.SetStateAction<number>>;
  selectedAlternativePrices: SelectedAlternativePriceObject | null;
  setSelectedAlternativePrices: React.Dispatch<
    React.SetStateAction<SelectedAlternativePriceObject | null>
  >;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedDate: Date | null;
  setSelectedTimeslot: React.Dispatch<React.SetStateAction<TimeSlot | null>>;
  selectedTimeslot: TimeSlot | null;
  setSelectedProducts: React.Dispatch<React.SetStateAction<SelectedProductObject | null>>;
  selectedProducts: SelectedProductObject | null;
  setSelectedOptions: React.Dispatch<React.SetStateAction<SelectedOptionObject | null>>;
  selectedOptions: SelectedOptionObject | null;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<any | null>>;
  selectedPaymentMethod: any;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  creatingReservation: boolean;
  readTermsAndConditions: boolean;
  setReadTermsAndConditions: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ActivityContext = createContext<ActivityContextType>({
  activity: null,
  optionGroupsAvailabilityData: [],
  setOptionGroupsAvailabilityData: () => null,
  selectedPeopleAmount: 0,
  setSelectedPeopleAmount: () => null,
  selectedAlternativePrices: null,
  setSelectedAlternativePrices: () => null,
  selectedDate: null,
  setSelectedDate: () => null,
  selectedTimeslot: null,
  setSelectedTimeslot: () => null,
  selectedProducts: null,
  setSelectedProducts: () => null,
  selectedOptions: null,
  setSelectedOptions: () => null,
  selectedPaymentMethod: null,
  setSelectedPaymentMethod: () => null,
  email: "",
  setEmail: () => null,
  firstName: "",
  setFirstName: () => null,
  lastName: "",
  setLastName: () => null,
  phoneNumber: "",
  setPhoneNumber: () => null,
  remarks: "",
  setRemarks: () => null,
  creatingReservation: false,
  readTermsAndConditions: false,
  setReadTermsAndConditions: () => null,
});
