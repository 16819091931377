import { Container } from "@mui/material";
import BottomStepperNavigator from "components/controls/stepper/BottomStepperNavigator";
import StyledStepper from "components/controls/stepper/StyledStepper";
import { BookRoomContext } from "contexts/BookRoomContext";
import { useMemo, useState } from "react";
import Reserve from "./steps/reserve/Reserve";
import { DateRange } from "react-day-picker";
import Confirm from "./steps/confirm/Confirm";
import { useTranslation } from "react-i18next";
import { formatProducts, isValidEmail } from "helpers/ActivityReserveHelper";
import useFetch from "hooks/useFetch";
import { isEmpty, sum } from "lodash";
import { SelectedProductObject } from "types/SelectedProductObject";
import Extras from "./steps/extras/Extras";
import { BookRoomSteps } from "types/BookRoomSteps";
import { getNextStep, getPreviousStep, reserveRoom } from "helpers/BookRoomHelper";
import RedirectToPayment from "./steps/redirecting/RedirectToPayment";
import { Order } from "models/Order";
import { Accommodation } from "models/Accommodation";
import { SelectedAlternativeGuestsObject } from "types/SelectedAlternativeGuestsObject";
import { RoomType } from "models/RoomType";
import { BookingAvailability } from "models/BookingAvailability";
import Analytics from "helpers/Analytics";

type AccommodationData = {
  data: Accommodation;
  loading: boolean;
  error: unknown;
};

type RoomTypesData = {
  data: RoomType[];
  loading: boolean;
  error: unknown;
};

const BookRoomPage = () => {
  const { t } = useTranslation();

  const [steps, setSteps] = useState<BookRoomSteps[]>([
    BookRoomSteps.RESERVE,
    BookRoomSteps.CONFIRM,
  ]);
  const [activeStep, setActiveStep] = useState(steps[0]);

  // Reservation
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | undefined>(undefined);
  const [numberOfGuests, setNumberOfGuests] = useState<number>(0);
  const [selectedAlternativeGuests, setSelectedAlternativeGuests] =
    useState<SelectedAlternativeGuestsObject | null>(null);
  const [selectedRoomType, setSelectedRoomType] = useState<RoomType | null>(null);
  const [numberOfRooms, setNumberOfRooms] = useState(1);

  // Extras/addons
  const [selectedProducts, setSelectedProducts] = useState<SelectedProductObject | null>(null);

  //  Contact inputs
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  //  Payment methods
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any | null>(null);
  // Terms and Conditions
  const [readTermsAndConditions, setReadTermsAndConditions] = useState(false);

  // Payment
  const [creatingReservation, setCreatingReservation] = useState(false);
  const [reservationData, setReservationData] = useState<{
    booking: Order;
    payment_link: string;
  } | null>(null);
  const [paymentLink, setPaymentLink] = useState("");

  // Backend
  const { data: bookingExtrasData } = useFetch(`open/booking-options`);
  const [availabilityData, setAvailabilityData] = useState<BookingAvailability | null>(null);
  const { data: accommodationData }: AccommodationData = useFetch(`open/accommodations/${1}`);
  const { data: roomTypeData }: RoomTypesData = useFetch(`open/room-types`);

  useMemo(() => {
    if (bookingExtrasData && !isEmpty(bookingExtrasData)) {
      setSteps([BookRoomSteps.RESERVE, BookRoomSteps.EXTRAS, BookRoomSteps.CONFIRM]);
      setSelectedProducts(formatProducts(bookingExtrasData));
    }
  }, [bookingExtrasData]);

  useMemo(() => {
    if (accommodationData) {
      if (!isEmpty(accommodationData.guests)) {
        setSelectedAlternativeGuests(
          accommodationData.guests.reduce(
            (prevValue, guest) => ({ ...prevValue, [guest.id]: { ...guest, people: 0 } }),
            {}
          )
        );
      }
    }
  }, [accommodationData]);

  const handleReservePostRequest = () => {
    reserveRoom({
      email,
      phoneNumber,
      firstName,
      lastName,
      paymentMethod: selectedPaymentMethod,
      products: selectedProducts ?? {},
      people: selectedAlternativeGuests
        ? sum(Object.values(selectedAlternativeGuests).map((value) => value.people))
        : numberOfGuests,
      ...(selectedAlternativeGuests && { alternativeGuests: selectedAlternativeGuests }),
      roomId: selectedRoomType?.cheapest_room.id ?? 1,
      dateRange: selectedDateRange as DateRange,
    })
      .then((response) => {
        setActiveStep(BookRoomSteps.REDIRECTING);
        setReservationData(response.data.data);
        setPaymentLink(response.data.data.payment_link);
        const { total, invoice_number } = response.data.data.booking;
        Analytics.trackRoomBooked(total, invoice_number);
      })
      .finally(() => {
        setCreatingReservation(false);
      });
  };

  const getActiveStepIndex = () => {
    if (activeStep === BookRoomSteps.REDIRECTING) return steps.length + 1;
    return Object.values(steps).indexOf(activeStep);
  };

  const isValid = () => {
    switch (activeStep) {
      case BookRoomSteps.RESERVE:
        const isMoreThanOneGuest = selectedAlternativeGuests
          ? sum(Object.values(selectedAlternativeGuests).map((value) => value.people)) > 0
          : numberOfGuests > 0;
        return Boolean(
          selectedDateRange &&
            selectedDateRange.from &&
            selectedDateRange.to &&
            isMoreThanOneGuest &&
            numberOfRooms > 0 &&
            selectedRoomType &&
            !isEmpty(availabilityData?.rooms)
        );

      case BookRoomSteps.CONFIRM:
        if (
          !selectedPaymentMethod ||
          !firstName ||
          !lastName ||
          !email ||
          !phoneNumber ||
          !readTermsAndConditions
        ) {
          return false;
        }
        if (!isValidEmail(email)) return false;
        return true;

      default:
        return true;
    }
  };

  const handleNext = () => {
    window.scrollTo(0, 0);
    if (activeStep === BookRoomSteps.CONFIRM) {
      setCreatingReservation(true);
      handleReservePostRequest();
      return;
    }
    setActiveStep(getNextStep(activeStep, !isEmpty(bookingExtrasData)));
  };

  const handleBack = () => {
    setActiveStep(getPreviousStep(activeStep, !isEmpty(bookingExtrasData)));
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case BookRoomSteps.RESERVE:
        return <Reserve />;

      case BookRoomSteps.EXTRAS:
        return <Extras />;

      case BookRoomSteps.CONFIRM:
        return <Confirm />;

      case BookRoomSteps.REDIRECTING:
        return (
          <RedirectToPayment
            paymentLink={paymentLink}
            order={reservationData?.booking ?? undefined}
          />
        );

      default:
        break;
    }
  };

  return (
    <BookRoomContext.Provider
      value={{
        accommodation: accommodationData,
        bookingExtras: bookingExtrasData,
        roomTypes: roomTypeData,
        availabilityData,
        setAvailabilityData,
        selectedDateRange,
        setSelectedDateRange,
        selectedAlternativeGuests,
        setSelectedAlternativeGuests,
        numberOfGuests,
        setNumberOfGuests,
        selectedRoomType,
        setSelectedRoomType,
        numberOfRooms,
        setNumberOfRooms,
        selectedProducts,
        setSelectedProducts,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        email,
        setEmail,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        phoneNumber,
        setPhoneNumber,
        creatingReservation,
        readTermsAndConditions,
        setReadTermsAndConditions,
      }}
    >
      <StyledStepper steps={steps} activeStep={getActiveStepIndex()} />
      <Container maxWidth="md">
        {renderStepContent()}
        {activeStep !== BookRoomSteps.REDIRECTING && (
          <BottomStepperNavigator
            loading={creatingReservation}
            onBack={handleBack}
            onNext={handleNext}
            isValid={isValid()}
            showBackButton={activeStep !== BookRoomSteps.RESERVE}
            nextButtonText={t(activeStep === BookRoomSteps.CONFIRM ? "Confirm and Pay" : "Next")}
          />
        )}
      </Container>
    </BookRoomContext.Provider>
  );
};

export default BookRoomPage;
