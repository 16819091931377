import axios from "axios";
import { format } from "date-fns";
import { isEmpty, sum } from "lodash";
import { ActivityOption, AlternativePrice, Product } from "models/Activity";
import { ActivityOrderPostPayload, ReservationDataType } from "types/ReservationDataType";
import { SelectedAlternativePriceObject } from "types/SelectedAlternativePriceObject";
import { SelectedOptionObject } from "types/SelectedOptionObject";
import { SelectedProductObject } from "types/SelectedProductObject";
import { Steps } from "../types/StepsEnum";

export const getNextStep = (currentStep: Steps, hasProducts: boolean): Steps => {
  switch (currentStep) {
    case Steps.EXTRAS:
      return Steps.CONFIRM;

    case Steps.RESERVE:
      return hasProducts ? Steps.CONFIRM : Steps.EXTRAS;

    default:
      return Steps.RESERVE;
  }
};

export const getPreviousStep = (currentStep: Steps, hasProducts: boolean) => {
  switch (currentStep) {
    case Steps.EXTRAS:
      return Steps.RESERVE;

    case Steps.CONFIRM:
      return hasProducts ? Steps.RESERVE : Steps.EXTRAS;

    default:
      return Steps.RESERVE;
  }
};

export const getAlternativePrices = (alternativePriceMapped: any[]) => {
  return !isEmpty(alternativePriceMapped)
    ? alternativePriceMapped.map((alternativePrice) => ({
        id: alternativePrice.price.id,
        name: alternativePrice.price.name,
        people: alternativePrice.amount,
        price: alternativePrice.price.price,
        total: 0,
      }))
    : [];
};

export const reserveActivity = (reservation: ReservationDataType) => {
  const alternativePriceMapped = Object.values(reservation.alternativePrices ?? {});
  let payload: ActivityOrderPostPayload = {
    activity_id: reservation.activityId,
    email: reservation.email,
    first_name: reservation.firstName,
    last_name: reservation.lastName,
    phone: reservation.phoneNumber,
    payment_provider: reservation.paymentMethod.payment_provider,
    starts_at: new Date(format(reservation.date, "yyyy-MM-dd")),
    ends_at: new Date(format(reservation.date, "yyyy-MM-dd")),
    ...(!isEmpty(alternativePriceMapped) && {
      alternative_prices: alternativePriceMapped.map((alternativePrice) => ({
        id: alternativePrice.price.id,
        people: alternativePrice.amount,
      })),
    }),
    options: Object.values(reservation.options ?? {}).map((option) => ({
      id: option.options[option.selected].id,
    })),
    products: Object.values(reservation.products ?? {}).map((product) => ({
      id: product.product.id,
      quantity: product.amount,
    })),
    people: reservation.people ?? 0,
    remarks: reservation.remarks,
  };

  if (reservation.timeslot) {
    payload.starts_at = new Date(
      format(reservation.date, "yyyy-MM-dd") + "T" + reservation.timeslot?.start_time + "Z"
    );
    payload.ends_at = new Date(
      format(reservation.date, "yyyy-MM-dd") + "T" + reservation.timeslot?.end_time + "Z"
    );
  }

  if (!isEmpty(alternativePriceMapped)) {
    payload.people = sum(alternativePriceMapped.map((prices) => prices.amount));
  }

  return axios.post(
    `${process.env.REACT_APP_BASE_API_URL}open/activities/availability/reserve`,
    payload
  );
};

export const isValidEmail = (email: string): boolean => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return Boolean(email.match(regexp));
};

/**
 * Generates useState-compatible object of given products
 *
 * @param products - Activity products
 * @returns a useable object to manipulate state in react components : SelectedProductObject
 */
export const formatProducts = (products: Product[]): SelectedProductObject => {
  return products.reduce((o, product) => ({ ...o, [product.id]: { product, amount: 0 } }), {});
};

/**
 * Generates useState-compatible object of given alternate prices and defaults the first entry with an amount of 1.
 *
 * @param prices - Activity alternative prices
 * @returns a useable object to manipulate state in react components : SelectedAlternativePriceObject
 */
export const formatAlternativePrices = (
  prices: AlternativePrice[]
): SelectedAlternativePriceObject => {
  return prices.reduce((o, price, index) => ({ ...o, [price.id]: { price, amount: 0 } }), {});
};

/**
 * Generates useState-compatible object of given product groups, except for empty groups
 *
 * @remarks
 * If one group has no options, it will not be formatted in the returned object.
 *
 * @param optionGroups - Activity option groups
 * @returns a useable object to manipulate state in react components : SelectedOptionObject
 */
export const formatOptionGroups = (
  optionGroups: ActivityOption[],
  currentSelected?: SelectedOptionObject
): SelectedOptionObject => {
  return optionGroups.reduce((o, option) => {
    if (option.options.length <= 0) return { ...o };
    return {
      ...o,
      [option.slug]: {
        name: option.name,
        options: option.options.reduce((o, option) => ({ ...o, [option.slug]: option }), {}),
        selected: currentSelected
          ? currentSelected[option.slug].selected
          : option.options[0].slug ?? "",
      },
    };
  }, {});
};

/**
 * Calculates the total price of the current reservation
 *
 * @remarks
 * If activity has alternative prices, basePrice calculation will be ignored in the calculation.
 *
 * @param basePrice - number (ex. 12.99)
 * @param alternativePrices - SelectedAlternativePriceObject
 * @param products - SelectedProductObject
 * @param optionGroups - SelectedOptionObject
 * @param people - number (defaults to 0)
 *
 * @return Total Price of the current reservation
 */
export const calculateTotalPrice = (
  basePrice: number,
  alternativePrices: SelectedAlternativePriceObject,
  products: SelectedProductObject,
  optionGroups: SelectedOptionObject,
  useAlternativePrices: boolean = false,
  people = 0
) => {
  const calculatedBasePrice = people * basePrice;

  let alternativePricesTotal = 0;
  alternativePricesTotal = sum(
    Object.values(alternativePrices).map((value) => value.price.price * value.amount)
  );

  let productsPrice = 0;
  productsPrice = sum(Object.values(products).map((value) => value.product.price * value.amount));

  let optionsPrice = 0;
  optionsPrice = sum(
    Object.values(optionGroups).map((value) => value.options[value.selected].price)
  );

  return (
    (useAlternativePrices ? alternativePricesTotal : calculatedBasePrice) +
    productsPrice +
    optionsPrice
  );
};
