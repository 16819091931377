import { useRef, useEffect } from "react";

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => {
      if (savedCallback.current) savedCallback.current();
    }, delay);

    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
