import { Box, Stack, Typography } from "@mui/material";
import { colors } from "helpers/Theme";
import { isEmpty } from "lodash";
import { Activity } from "models/Activity";
import Carousel from "nuka-carousel";

const OrderActivityInfo = ({ activity }: { activity: Activity }) => {
  return (
    <Box>
      <Box marginX={-2} height={200}>
        {isEmpty(activity.gallery) ? (
          <img
            src={activity.display_image.url}
            alt={activity.display_image.file_name}
            height={250}
            width="100%"
            style={{ objectFit: "cover" }}
          />
        ) : (
          <Carousel
            autoplay
            wrapAround
            autoplayInterval={6000}
            defaultControlsConfig={{
              nextButtonStyle: { display: "none" },
              prevButtonStyle: { display: "none" },
              pagingDotsStyle: {
                fill: colors.white,
                paddingBottom: 45,
              },
            }}
          >
            <img
              src={activity.display_image.url}
              alt={activity.display_image.file_name}
              height={250}
              width="100%"
              style={{ objectFit: "cover" }}
            />
            {activity.gallery.map((image) => (
              <img
                key={image.id}
                src={image.url}
                alt={image.file_name}
                height={250}
                width="100%"
                style={{ objectFit: "cover" }}
              />
            ))}
          </Carousel>
        )}
      </Box>
      <Stack
        spacing={2}
        padding={2}
        paddingTop={3}
        marginX={-2}
        position="relative"
        bgcolor={colors.white}
        sx={{ borderTopLeftRadius: 25, borderTopRightRadius: 25 }}
      >
        <Typography variant="h6">
          {activity.name}
        </Typography>
      </Stack>
    </Box>
  );
};

export default OrderActivityInfo;
