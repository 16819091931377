import { Order, OrderProduct } from "models/Order";
import { CreateOrderPayload } from "types/CreateOrderData";
import { CreateReceiptPayload } from "types/CreateReceiptPayload";
import RetreatApiClient from "./RetreatApiClient";
import { sum } from "lodash";
import { Option } from "models/Activity";
import { calculateOrderPrice } from "./MenuActivityHelper";

export const calculateReceiptTotal = (orders: Order[]) => {
  return sum(orders.map((order) => Number(calculateOrderPrice(order.products))));
};

export const generateCreateOrderPayload = (
  activityId: number,
  products: OrderProduct[],
  remarks: string,
  options: { [id: string]: Option },
  tags: { name: string }[]
): CreateOrderPayload => {
  return {
    activity_id: activityId,
    email: "waiter@happy.green",
    first_name: "waiter",
    last_name: "waiter",
    payment_provider: "on-bill",
    starts_at: new Date(),
    ends_at: new Date(),
    phone: "123123",
    products: products.map((product) => ({
      id: product.id,
      quantity: product.quantity,
    })),
    people: 1,
    remarks: remarks,
    tags: tags.map((tag: any) => tag.name),
    options: Object.values(options ?? {})
      .filter((option) => option !== null)
      .map((option) => ({
        id: option.id,
      })),
  };
};

export const createReceipt = async (payload: CreateReceiptPayload) => {
  const { orders, paymentProvider, token, userId: waiterId, createNewUser, newUser } = payload;
  let userId = waiterId ?? 0;
  if (createNewUser && newUser) {
    const existingUser = await (
      await RetreatApiClient.getUserByEmail(newUser.email, token)
    ).data.data[0];
    if (!existingUser) {
      const createUserResponse = await RetreatApiClient.createUser(
        newUser.email,
        newUser.firstName,
        newUser.lastName,
        token
      );
      userId = createUserResponse.data.data.id;
    } else {
      userId = existingUser.id;
    }
  }

  return RetreatApiClient.createReceiptFromOrders(orders, userId, paymentProvider, token);
};
