import { Accommodation } from "models/Accommodation";
import { Product } from "models/Activity";
import { BookingAvailability } from "models/BookingAvailability";
import { RoomType } from "models/RoomType";
import React, { createContext } from "react";
import { DateRange } from "react-day-picker";
import { SelectedAlternativeGuestsObject } from "types/SelectedAlternativeGuestsObject";
import { SelectedProductObject } from "types/SelectedProductObject";

type BookRoomContextType = {
  accommodation: Accommodation | null;
  bookingExtras: Product[] | null;
  roomTypes: RoomType[] | null;
  availabilityData: BookingAvailability | null;
  setAvailabilityData: React.Dispatch<React.SetStateAction<BookingAvailability | null>>;
  selectedDateRange: DateRange | undefined;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  selectedAlternativeGuests: SelectedAlternativeGuestsObject | null;
  setSelectedAlternativeGuests: React.Dispatch<
    React.SetStateAction<SelectedAlternativeGuestsObject | null>
  >;
  numberOfGuests: number;
  setNumberOfGuests: React.Dispatch<React.SetStateAction<number>>;
  selectedRoomType: RoomType | null;
  setSelectedRoomType: React.Dispatch<React.SetStateAction<RoomType | null>>;
  numberOfRooms: number;
  setNumberOfRooms: React.Dispatch<React.SetStateAction<number>>;
  selectedProducts: SelectedProductObject | null;
  setSelectedProducts: React.Dispatch<React.SetStateAction<SelectedProductObject | null>>;
  selectedPaymentMethod: any;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<any | null>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  creatingReservation: boolean;
  readTermsAndConditions: boolean;
  setReadTermsAndConditions: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BookRoomContext = createContext<BookRoomContextType>({
  accommodation: null,
  bookingExtras: [],
  roomTypes: [],
  availabilityData: null,
  setAvailabilityData: () => null,
  selectedDateRange: undefined,
  setSelectedDateRange: () => null,
  selectedAlternativeGuests: null,
  setSelectedAlternativeGuests: () => null,
  numberOfGuests: 1,
  setNumberOfGuests: () => null,
  selectedRoomType: null,
  setSelectedRoomType: () => null,
  numberOfRooms: 1,
  setNumberOfRooms: () => null,
  selectedProducts: null,
  setSelectedProducts: () => null,
  selectedPaymentMethod: null,
  setSelectedPaymentMethod: () => null,
  email: "",
  setEmail: () => null,
  firstName: "",
  setFirstName: () => null,
  lastName: "",
  setLastName: () => null,
  phoneNumber: "",
  setPhoneNumber: () => null,
  creatingReservation: false,
  readTermsAndConditions: false,
  setReadTermsAndConditions: () => null,
});
