import { createTheme } from "@mui/material";

export const colors = {
  primary: "#15aa6b",
  primaryLight: "#EBF6F2",
  greyLight: "#EDEDED",
  dark: "#333333",
  darkMedium: "#555555",
  white: "#ffffff",
  warning: "#FFF0C9",
  error: "#d32f2f",
  errorLight: "#F6ECEB",
  textInput: "#FAFAFA",
};

const customShadows = createTheme().shadows;

customShadows[1] = "rgb(0 0 0 / 7%) 0px 3px 14px -1px, rgb(0 0 0 / 10%) 0px 1px 1px 0px";

export const BaseTheme = createTheme({
  shape: {
    borderRadius: 8,
  },
  shadows: customShadows,
  typography: {
    fontFamily: "Open Sans, Roboto",
    h1: {
      fontFamily: "Grand Halva, Open Sans, Roboto",
    },
    h2: {
      fontFamily: "Grand Halva, Open Sans, Roboto",
    },
    h3: {
      fontFamily: "Grand Halva, Open Sans, Roboto",
    },
    h4: {
      fontFamily: "Grand Halva, Open Sans, Roboto",
    },
    h5: {
      fontFamily: "Grand Halva, Open Sans, Roboto",
    },
    h6: {
      fontFamily: "Grand Halva, Open Sans, Roboto",
    },
    body1: {
      fontWeight: "lighter",
    },
    body2: {
      fontWeight: "lighter",
    },
    caption: {
      fontWeight: "lighter",
    },
  },
});

export const LightTheme = createTheme({
  ...BaseTheme,
  palette: {
    mode: "light",
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.dark,
    },
    warning: {
      main: colors.warning,
    },
  },
  typography: {
    ...BaseTheme.typography,
    body1: {
      ...BaseTheme.typography.body1,
      color: colors.darkMedium,
    },
    body2: {
      ...BaseTheme.typography.body2,
      color: colors.darkMedium,
    },
    caption: {
      ...BaseTheme.typography.caption,
      color: colors.darkMedium,
    },
  },
});

export const DarkTheme = createTheme({
  ...BaseTheme,
  palette: {
    mode: "dark",
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.dark,
    },
    warning: {
      main: colors.warning,
    },
    background: { default: colors.dark },
  },
});
