import { ChevronRight } from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table as MuiTable,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Stack,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import { formatMoney, getCreatedDate } from "helpers/Shared";
import { Order } from "models/Order";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";

const orderStatusColors: { [key: string]: string } = {
  pending: "greenyellow",
  confirmed: "greenyellow",
  processing: "info.main",
  completed: "lightgray",
};

const Table = ({ rows, slug }: { rows: Order[]; slug: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 850 }}>
        <TableBody>
          {rows.map((row, index) => {
            const chips: string[] = [
              ...(row.activity_options?.map((option) => option.name) ?? []),
              ...(row.tags?.map((tag) => tag.name) ?? []),
            ];
            const chipsToShow = chips.slice(0, 2);
            const chipsToHide = chips.slice(2);
            const getStatusColor = () => {
              if (!row.paid_at && row.payment_provider === "on-bill") {
                return orderStatusColors[row.status] ?? "lightgray";
              }
              return "#ff7777";
            };
            return (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box width={12} height={12} borderRadius={200} bgcolor={getStatusColor()} />
                    {!row.viewed_at && <Chip size="small" label={t("new")} />}
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack py={1}>
                    <Typography>{row.activity.name}</Typography>
                    <Typography variant="caption">#{row.invoice_number}</Typography>
                  </Stack>
                </TableCell>
                <TableCell size="medium">
                  <Typography>{formatMoney(row.total)}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Stack direction="row" spacing={1}>
                    {chipsToShow.map((chip, i) => (
                      <Chip label={chip} key={i} />
                    ))}
                    {chipsToHide.length > 0 && (
                      <Tooltip title={chipsToHide.join(" - ")}>
                        <Chip label={t("{{amount}} more", { amount: chipsToHide.length })} />
                      </Tooltip>
                    )}
                  </Stack>
                </TableCell>
                <TableCell align="right" size="small">
                  {getCreatedDate(row.starts_at)}
                </TableCell>
                <TableCell align="right" size="small">
                  <IconButton
                    onClick={() =>
                      navigate({
                        pathname: row.id.toString(),
                        search: `?${createSearchParams({ slug })}`,
                      })
                    }
                  >
                    <ChevronRight />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
