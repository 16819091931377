import { Typography, Box, Stack, Chip } from "@mui/material";
import { Product } from "models/Activity";
import NumericInput from "components/controls/numeric-input/NumericInput";

type Props = {
  menuItem: Product;
  value: number;
  onChange: (v: number) => void;
};

const MenuRowItem = ({ menuItem, value, onChange }: Props) => {
  return (
    <Box marginY={2}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignContent="center">
        <Stack>
          <Typography variant="body1">{menuItem.name}</Typography>
          {menuItem.tags && (
            <Stack direction="row" spacing={1}>
              {menuItem.tags.map((tag, index) => (
                <Chip variant="filled" size="small" label={tag.name} key={index} />
              ))}
            </Stack>
          )}
        </Stack>
        <NumericInput value={value} onChange={onChange} />
      </Stack>
    </Box>
  );
};

export default MenuRowItem;
