import { Box } from "@mui/material";
import { colors } from "helpers/Theme";
import { isEmpty } from "lodash";
import { DisplayImage } from "models/Activity";
import Carousel from "nuka-carousel";

const DisplayImageCarousel = ({
  displayImage,
  gallery,
  pagingDotsPaddingBottom = 60,
}: {
  displayImage: DisplayImage;
  gallery: DisplayImage[];
  pagingDotsPaddingBottom?: number;
}) => {
  return (
    <Box marginX={-2} height="100%" sx={{ WebkitTransform: "translate3d(0,0,0)" }}>
      {isEmpty(gallery) ? (
        displayImage ? (
          <img
            src={displayImage.url}
            alt={displayImage.file_name}
            height="100%"
            width="100%"
            style={{ objectFit: "cover" }}
          />
        ) : (
          <Box height="100%" width="100%" bgcolor={colors.greyLight} />
        )
      ) : (
        <Carousel
          autoplay
          wrapAround
          autoplayInterval={6000}
          defaultControlsConfig={{
            nextButtonStyle: { display: "none" },
            prevButtonStyle: { display: "none" },
            pagingDotsStyle: {
              fill: colors.white,
              paddingBottom: pagingDotsPaddingBottom,
            },
          }}
        >
          <img
            src={displayImage.url}
            alt={displayImage.file_name}
            height="100%"
            width="100%"
            style={{ objectFit: "cover" }}
          />
          {gallery.map((image) => (
            <img
              key={image.id}
              src={image.url}
              alt={image.file_name}
              height="100%"
              width="100%"
              style={{ objectFit: "cover" }}
            />
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default DisplayImageCarousel;
