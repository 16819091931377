import { CheckCircle, Cancel } from "@mui/icons-material";
import { Container, Box, Paper, Stack, Typography, capitalize } from "@mui/material";
import { format } from "date-fns";
import { colors } from "helpers/Theme";
import useFetch from "hooks/useFetch";
import { Order } from "models/Order";
import { useTranslation, Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import ReceiptSummaryCard from "views/select-menu-activity-page/components/create-receipt-dialog/ReceiptSummaryCard";
import ReceiptPaymentStatus from "./components/ReceiptPaymentStatus";
import { formatInTimeZone } from "date-fns-tz";

const ReceiptStatusPage = () => {
  const { t } = useTranslation();
  const params = useParams();

  const { data } = useFetch(`open/transactions/${params.receiptNumber}/status`);

  const receipt = data as Order & { orders: Order[] };

  if (!receipt) {
    return <></>;
  }

  const getPaymentStatus = () => {
    if (receipt.paid_at) {
      return {
        title: t("Paid"),
        subtext: (
          <Trans
            i18nKey="Paid with <strong>{{ paymentProvider }}</strong> on {{ dateTime }}."
            components={{ strong: <strong /> }}
            values={{
              paymentProvider: capitalize(receipt.payment_provider),
              dateTime: formatInTimeZone(new Date(receipt.paid_at), "utc", "PP p"),
            }}
          />
        ),
        icon: <CheckCircle color="primary" />,
        color: colors.primary,
      };
    }
    return {
      title: t("Not paid yet"),
      subtext: (
        <Trans
          i18nKey="Client pays with <strong>{{ paymentProvider }}</strong>."
          components={{ strong: <strong /> }}
          values={{
            paymentProvider: capitalize(receipt.payment_provider),
          }}
        />
      ),
      icon: <Cancel color="error" />,
      color: colors.error,
    };
  };

  const paymentStatus = getPaymentStatus();

  return (
    <Container maxWidth="md">
      <Box marginY={2}>
        <Paper>
          <Stack spacing={2} p={2}>
            <Stack>
              <Typography variant="h5">{t("Receipt")}</Typography>
              <Typography>#{receipt.invoice_number}</Typography>
            </Stack>
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {receipt.user.first_name} {receipt.user.last_name}
              </Typography>
              <Typography>{receipt.user.email}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {paymentStatus.icon}
              <Stack>
                <Typography variant="body1" color={paymentStatus.color}>
                  {paymentStatus.title}
                </Typography>
                <Typography variant="caption">{paymentStatus.subtext}</Typography>
              </Stack>
            </Stack>
            {!receipt.paid_at && <ReceiptPaymentStatus receipt={receipt} />}
          </Stack>
        </Paper>
      </Box>
      <ReceiptSummaryCard orders={receipt.orders} />
      <Box height={50} />
    </Container>
  );
};

export default ReceiptStatusPage;
