import { Navigate, PathRouteProps, Route } from "react-router-dom";

const RoleRoute = ({
  role,
  roles = [],
  children,
  ...props
}: {
  role: string;
  roles: string[];
  children: React.ReactElement;
}) => {
  return !roles.length || roles.includes(role) ? (
    <div {...props}>{children}</div>
  ) : (
    <Navigate to=".." />
  );
};

export default RoleRoute;
