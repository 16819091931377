import styled from "@emotion/styled";
import { Stack, Typography, Box } from "@mui/material";
import { formatMoney } from "helpers/Shared";
import { BaseTheme } from "helpers/Theme";
import { Order } from "models/Order";
import { Trans, useTranslation } from "react-i18next";

const YappyInstructions = ({ order }: { order: Order }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} alignItems="center">
      <Typography variant="h6" textAlign="center">
        {t("Payment required")}
      </Typography>
      <Typography variant="body1" textAlign="center">
        {t(
          "Your order is currently only a reservation. Make sure you pay before someone else books your reserved date!"
        )}
      </Typography>
      <Box maxWidth={400}>
        <img src={require("assets/images/yappy-search.png")} alt="yappy-search" width="100%" />
      </Box>
      <Typography variant="body1">
        {t(
          "To complete you payment, please follow the steps below in Yappy from the Banco General App."
        )}
      </Typography>
      <ol>
        <StyledLi>
          <Typography variant="body2">
            <Trans
              i18nKey="Search for <strong>HappyGreen Retreat</strong> in the <strong>Directory</strong>."
              components={{ strong: <strong /> }}
            />
          </Typography>
        </StyledLi>
        <StyledLi>
          <Typography variant="body2">
            <Trans
              i18nKey="Enter <strong>{{total}}</strong> as payment amount."
              components={{ strong: <strong /> }}
              values={{
                total: formatMoney(order.total),
              }}
            />
          </Typography>
        </StyledLi>
        <StyledLi>
          <Typography variant="body2">
            <Trans
              i18nKey="Put the order code <strong>{{invoiceNumber}}</strong> in the comment."
              components={{ strong: <strong /> }}
              values={{
                invoiceNumber: order.invoice_number,
              }}
            />
          </Typography>
        </StyledLi>
        <StyledLi>
          <Typography variant="body2">{t("Complete the transaction")}</Typography>
        </StyledLi>
        <StyledLi>
          <Typography variant="body2">
            {t("We will confirm your order as soon as possible")}
          </Typography>
        </StyledLi>
      </ol>
    </Stack>
  );
};

const StyledLi = styled.li`
  ::marker {
    font-size: ${BaseTheme.typography.body2.fontSize};
    font-family: ${BaseTheme.typography.body2.fontFamily};
    font-weight: ${BaseTheme.typography.body2.fontWeight};
    color: ${BaseTheme.typography.body2.color};
  },
`;

export default YappyInstructions;
