import { Cancel, CheckCircle, Receipt } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Divider,
  Checkbox,
  Chip,
  Unstable_Grid2 as Grid,
  Card,
} from "@mui/material";
import { Box } from "@mui/system";
import { formatMoney } from "helpers/Shared";
import { isEmpty } from "lodash";
import { Order } from "models/Order";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatInTimeZone } from "date-fns-tz";

type Props = {
  order: Order;
  value: boolean;
  onCheckedChange?: (order: Order, checked: boolean) => void;
  onCardClicked?: () => void;
};

const OrderSummaryCard = ({ order, value, onCheckedChange, onCardClicked }: Props) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(value);

  const handleOnCheckBoxClick = () => {
    if (order.transaction_id) return;
    const newValue = !checked;
    setChecked(newValue);
    if (onCheckedChange) {
      onCheckedChange(order, newValue);
    }
  };

  const getStatusChipColor = () => {
    switch (order.status) {
      case "pending":
        return "warning";
      case "completed":
      case "paid":
      case "confirmed":
        return "primary";
      case "cancelled":
        return "error";

      default:
        return "default";
    }
  };

  const renderOptionAndTagChips = () => {
    const elements: { title: string; value: string }[] = [];
    if (!isEmpty(order.activity_options)) {
      elements.push(
        ...order.activity_options.map((option) => ({
          title: option.group_name,
          value: option.name,
        }))
      );
    }
    if (!isEmpty(order.tags)) {
      elements.push(
        ...order.tags.map((tag) => ({
          title: t("Tag"),
          value: tag.name,
        }))
      );
    }
    return elements.map((element, index) => (
      <Grid key={index}>
        <Chip
          size="small"
          label={
            <Typography>
              <strong>{element.title}:</strong> {element.value}
            </Typography>
          }
        />
      </Grid>
    ));
  };

  return (
    <Card elevation={checked ? 5 : 1}>
      <Stack spacing={1} p={2}>
        <Stack direction="row" alignItems="center">
          {!order.transaction_id ? (
            <Checkbox
              checked={checked}
              onChange={handleOnCheckBoxClick}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
            />
          ) : (
            <Receipt sx={{ marginX: 1.5 }} />
          )}
          <Stack onClick={onCardClicked}>
            <Typography variant="body1" fontWeight="bold">
              {t("{{ name }} menu", { name: order.activity.name })}
            </Typography>
            <Typography variant="body2">#{order.invoice_number}</Typography>
          </Stack>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <Stack textAlign="right">
              <Typography fontWeight="bold" variant="h5" fontFamily="Open Sans">
                {formatMoney(order.total)}
              </Typography>
              <Typography
                variant="caption"
                color={order.paid_at ? "primary" : "error"}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                {order.paid_at ? (
                  <>
                    <CheckCircle fontSize="inherit" />
                    {t("Paid")}
                  </>
                ) : (
                  <>
                    <Cancel fontSize="inherit" />
                    {t("Not Paid")}
                  </>
                )}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Grid container spacing={1} onClick={onCardClicked}>
          {renderOptionAndTagChips()}
        </Grid>
        <Divider />
        <Stack onClick={onCardClicked} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">
            {t("{{ time }} by {{ user }}", {
              time: formatInTimeZone(new Date(order.offered_at), "utc", "d LLL 'at' p"),
              user: order.user.first_name,
            })}
          </Typography>
          <Chip label={order.status} color={getStatusChipColor()} size="small" />
        </Stack>
      </Stack>
    </Card>
  );
};

export default OrderSummaryCard;
