import { Chip, ChipProps, useRadioGroup } from "@mui/material";
import { colors } from "helpers/Theme";

type Props = {
  value: unknown;
  onClick: any;
};

const ChipRadioControl = (props: ChipProps & Props) => {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  const handleChange = () => {
    props.onClick(props.value);
  };

  return (
    <Chip
      {...props}
      onClick={handleChange}
      color={checked ? "primary" : undefined}
      variant="outlined"
      sx={{
        ...(checked && {
          borderWidth: 2,
          fontWeight: "bold",
          color: colors.dark,
          backgroundColor: colors.primary + "33",
        }),
      }}
    />
  );
};

export default ChipRadioControl;
