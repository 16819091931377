import { ArrowBack, Cancel, CheckCircle } from "@mui/icons-material";
import { Box, Container, Paper, Stack, Typography, capitalize, Button, Link } from "@mui/material";
import { colors } from "helpers/Theme";
import useFetch from "hooks/useFetch";
import { Order } from "models/Order";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ReceiptSummaryCard from "../create-receipt-dialog/ReceiptSummaryCard";
import RetreatApiClient from "helpers/RetreatApiClient";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { formatInTimeZone } from "date-fns-tz";
import { useAuth } from "hooks/useAuth";

const ReceiptDetails = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const { user } = useAuth();

  const { data } = useFetch(`transactions/${params.receiptId}`);

  const [paymentLinkLoading, setPaymentLinkLoading] = useState(false);
  const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);

  const receipt = data as Order & { orders: Order[] };

  if (!receipt) {
    return <></>;
  }

  const fetchPaymentLink = () => {
    setPaymentLinkLoading(true);
    RetreatApiClient.requestReceiptPaymentLink(receipt.id)
      .then((response) => {
        window.location.assign(response.data.data.link);
      })
      .finally(() => setPaymentLinkLoading(false));
  };

  const handleMarkAsPaid = () => {
    setMarkAsPaidLoading(true);
    RetreatApiClient.markTransactionAsPaid(receipt.id, user.token)
      .then((res) => {
        window.location.reload();
      })
      .finally(() => setMarkAsPaidLoading(false));
  };

  const getPaymentStatus = () => {
    if (receipt.paid_at) {
      return {
        title: t("Paid"),
        subtext: (
          <Trans
            i18nKey="Paid with <strong>{{ paymentProvider }}</strong> on {{ dateTime }}."
            components={{ strong: <strong /> }}
            values={{
              paymentProvider: capitalize(receipt.payment_provider),
              dateTime: formatInTimeZone(new Date(receipt.paid_at), "utc", "PP p"),
            }}
          />
        ),
        icon: <CheckCircle color="primary" />,
        color: colors.primary,
      };
    }
    return {
      title: t("Not paid yet"),
      subtext: (
        <Trans
          i18nKey="Client pays with <strong>{{ paymentProvider }}</strong>."
          components={{ strong: <strong /> }}
          values={{
            paymentProvider: capitalize(receipt.payment_provider),
          }}
        />
      ),
      icon: <Cancel color="error" />,
      color: colors.error,
    };
  };

  const paymentStatus = getPaymentStatus();

  return (
    <Container maxWidth="md">
      <Box>
        <Stack my={2} alignItems="center" flexDirection="row" onClick={() => navigate(-1)}>
          <ArrowBack />
          <Typography justifyContent="center">{t("Back")}</Typography>
        </Stack>
      </Box>
      <Box marginY={2}>
        <Paper>
          <Stack spacing={2} p={2}>
            <Stack>
              <Typography variant="h5">{t("Receipt")}</Typography>
              <Typography>#{receipt.invoice_number}</Typography>
              <Link href={"/receipt/status/" + receipt.invoice_number}>
                {t("View public page")}
              </Link>
            </Stack>
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {receipt.user.first_name} {receipt.user.last_name}
              </Typography>
              <Typography>{receipt.user.email}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {paymentStatus.icon}
              <Stack>
                <Typography variant="body1" color={paymentStatus.color}>
                  {paymentStatus.title}
                </Typography>
                <Typography variant="caption">{paymentStatus.subtext}</Typography>
              </Stack>
            </Stack>
            {!["cash", "on-bill", "yappy"].includes(receipt.payment_provider) &&
              !receipt.paid_at && (
                <LoadingButton
                  fullWidth
                  onClick={fetchPaymentLink}
                  variant="contained"
                  loading={paymentLinkLoading}
                >
                  {t("Proceed to payment")}
                </LoadingButton>
              )}
            {["cash", "on-bill", "yappy"].includes(receipt.payment_provider) &&
              !receipt.paid_at && (
                <LoadingButton
                  fullWidth
                  onClick={handleMarkAsPaid}
                  variant="contained"
                  loading={markAsPaidLoading}
                >
                  {t("Mark as paid")}
                </LoadingButton>
              )}
          </Stack>
        </Paper>
      </Box>
      <ReceiptSummaryCard orders={receipt.orders} showOrderLinks />
      <Box height={50} />
    </Container>
  );
};

export default ReceiptDetails;
