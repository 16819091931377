import { useEffect } from "react";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { useAuth } from "./useAuth";

const createSocketConnection = (token: string) => {
  const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
    cluster: "mt1",
    wsHost: process.env.REACT_APP_PUSHER_URL,
    wsPort: Number(process.env.REACT_APP_PUSHER_PORT),
    wssPort: Number(process.env.REACT_APP_PUSHER_PORT),
    enableStats: false,
    forceTLS: process.env.REACT_APP_PUSHER_TLS === "true",
    enabledTransports: ["ws", "wss"],
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_URL,
    auth: {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  });

  return new Echo({
    broadcaster: "pusher",
    client: pusher,
  });
};

const useSocket = (
  channel: "order-created" | "order-updated",
  event: "OrderCreated" | "OrderUpdated",
  callbackFn: (value: any) => void
) => {
  const { user } = useAuth();

  useEffect(() => {
    const listen = (
      echo: Echo,
      channel: string,
      event: string,
      callBack: (payload: any) => void
    ) => {
      echo.private(channel).listen(event, (payload: any) => {
        callBack(payload);
      });

      return function cleanUp() {
        echo.leaveChannel(`private-${channel}`);
      };
    };

    const connection = createSocketConnection(user.token);

    listen(connection, channel, event, callbackFn);

    return () => {
      connection.disconnect();
    };
  }, []);
};

export default useSocket;
