import { Box, Stack, Typography } from "@mui/material";
import Carousel from "nuka-carousel";
import React from "react";
import { ActivityContext } from "contexts/ActivityContext";
import { colors } from "helpers/Theme";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import RichText from "components/generic/richtext-parser/RichText";
import ReadMoreText from "components/generic/read-more-text/ReadMoreText";

const ReservationActivityInfo = () => {
  const { t } = useTranslation();

  const { activity } = React.useContext(ActivityContext);

  const goToLocationDirection = () => {
    window.open(
      `https://www.google.com/maps/search/${encodeURIComponent(
        `${activity?.longitude},${activity?.latitude}`
      )}`
    );
  };

  return (
    activity && (
      <Box>
        <Box marginX={-2} height={250}>
          {isEmpty(activity.gallery) ? (
            <img
              src={activity.display_image.url}
              alt={activity.display_image.file_name}
              height={250}
              width="100%"
              style={{ objectFit: "cover" }}
            />
          ) : (
            <Carousel
              autoplay
              wrapAround
              autoplayInterval={6000}
              defaultControlsConfig={{
                nextButtonStyle: { display: "none" },
                prevButtonStyle: { display: "none" },
                pagingDotsStyle: {
                  fill: colors.white,
                  paddingBottom: 45,
                },
              }}
            >
              <img
                src={activity.display_image.url}
                alt={activity.display_image.file_name}
                height={250}
                width="100%"
                style={{ objectFit: "cover" }}
              />
              {activity.gallery.map((image) => (
                <img
                  key={image.id}
                  src={image.url}
                  alt={image.file_name}
                  height={250}
                  width="100%"
                  style={{ objectFit: "cover" }}
                />
              ))}
            </Carousel>
          )}
        </Box>
        <Stack
          spacing={2}
          marginTop={-5}
          padding={2}
          paddingTop={3}
          marginX={-2}
          position="relative"
          bgcolor={colors.white}
          sx={{ borderTopLeftRadius: 25, borderTopRightRadius: 25 }}
        >
          <Box>
            <Typography variant="h5">{activity.name}</Typography>
            <ReadMoreText text={activity.description} isRichText />
          </Box>
          <Box>
            <Typography variant="h6">{t("How to get there")}</Typography>
            <Typography variant="body1" mt={1}>
              {activity.address}
            </Typography>
            <RichText htmlString={activity.location_description} />
            {activity.longitude && activity.latitude && (
              <Typography
                mt={1}
                variant="body1"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={goToLocationDirection}
              >
                {t("View location →")}
              </Typography>
            )}
          </Box>
        </Stack>
      </Box>
    )
  );
};

export default ReservationActivityInfo;
