import { Box, Typography, Stack, Checkbox, FormGroup, FormControlLabel, Link } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { ActivityContext } from "contexts/ActivityContext";
import {
  calculateTotalPrice,
  getAlternativePrices,
  isValidEmail,
} from "helpers/ActivityReserveHelper";
import Summary from "./components/Summary";
import RemarkForm from "./components/RemarkForm";
import { Trans, useTranslation } from "react-i18next";
import TextInput from "components/controls/text-input/TextInput";
import SelectPaymentMethod from "components/SelectPaymentMethod";
import QuickSummary from "components/QuickSummary";
import { formatMoney } from "helpers/Shared";

const Contact = () => {
  const { t } = useTranslation();
  const {
    activity,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    creatingReservation,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    selectedPeopleAmount,
    selectedDate,
    selectedTimeslot,
    selectedOptions,
    selectedProducts,
    selectedAlternativePrices,
    readTermsAndConditions,
    setReadTermsAndConditions,
  } = useContext(ActivityContext);

  const [emailError, setEmailError] = useState("");

  const fullSummaryRef = useRef<any>();

  const handleFirstNameChange = (e: any) => setFirstName(e.target.value);
  const handleLastNameChange = (e: any) => setLastName(e.target.value);
  const handlePhoneNumberChange = (e: any) => setPhoneNumber(e.target.value);
  const handleEmailChange = (e: any) => setEmail(e.target.value);

  const handleEmailBlur = () =>
    setEmailError(isValidEmail(email) ? "" : t("Invalid email address"));

  const renderQuickSummary = () => {
    if (activity && selectedDate) {
      return (
        <QuickSummary
          totalPrice={formatMoney(
            calculateTotalPrice(
              activity.price,
              selectedAlternativePrices ?? {},
              selectedProducts ?? {},
              selectedOptions ?? {},
              activity.has_alternative_prices,
              selectedPeopleAmount
            )
          )}
          reservationDate={selectedDate}
          formattedTimeslot={
            selectedTimeslot
              ? `${selectedTimeslot?.start_time} - ${selectedTimeslot?.end_time}`
              : undefined
          }
          people={selectedPeopleAmount}
          alternativePrices={getAlternativePrices(Object.values(selectedAlternativePrices ?? {}))}
          onViewDetailsClick={scrollToFullSummary}
        />
      );
    }
  };

  const scrollToFullSummary = () => {
    fullSummaryRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleReadTermsAndConditionsClick = (e: any) => {
    setReadTermsAndConditions(e.target.checked);
  };

  return (
    activity && (
      <Box marginY={2}>
        {renderQuickSummary()}
        <Box marginY={2}>
          <Typography variant="h6">{t("Payment method")}</Typography>
          <Typography variant="body1">
            {t("Payment is done via our secure payment gateaway partners Paguelofacil and Stripe.")}
          </Typography>
          <SelectPaymentMethod
            onChange={setSelectedPaymentMethod}
            value={selectedPaymentMethod}
            exclude={["bill", "cash"]}
            disabled={creatingReservation}
          />
        </Box>
        {Boolean(activity.has_remarks) && <RemarkForm />}
        <Box marginY={2}>
          <Typography variant="h6">{t("Review & Payment")}</Typography>
          <Typography variant="body1">
            {t(
              "Add your contact details and payment information here. You will receive a confirmation e-mail."
            )}
          </Typography>
          <Stack spacing={2} marginY={2}>
            <Stack direction="row" spacing={2}>
              <TextInput
                label={t("First name")}
                onChange={handleFirstNameChange}
                value={firstName}
                fullWidth
                autoComplete="given-name"
                disabled={creatingReservation}
              />
              <TextInput
                label={t("Last name")}
                onChange={handleLastNameChange}
                fullWidth
                value={lastName}
                autoComplete="family-name"
                disabled={creatingReservation}
              />
            </Stack>
            <TextInput
              label={t("Email")}
              onChange={handleEmailChange}
              value={email}
              autoComplete="email"
              onBlur={handleEmailBlur}
              error={emailError !== ""}
              helperText={emailError}
              disabled={creatingReservation}
            />
            <TextInput
              label={t("Phone Number")}
              onChange={handlePhoneNumberChange}
              value={phoneNumber}
              autoComplete="tel"
              disabled={creatingReservation}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={readTermsAndConditions}
                    onChange={handleReadTermsAndConditionsClick}
                  />
                }
                label={
                  <Trans
                    i18nKey={t("I have read and agreed to the <a>terms and conditions</a>.")}
                    components={{
                      a: (
                        <Link
                          href={process.env.REACT_APP_TERMS_CONDITIONS_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                }
              />
            </FormGroup>
          </Stack>
        </Box>
        <Box ref={fullSummaryRef}>
          <Summary />
        </Box>
        <Box height={50} />
      </Box>
    )
  );
};

export default Contact;
