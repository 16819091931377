import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Stack, Typography, Box, Button } from "@mui/material";
import RichText from "components/generic/richtext-parser/RichText";
import YappyInstructions from "components/YappyInstructions";
import { format } from "date-fns";
import RetreatApiClient from "helpers/RetreatApiClient";
import { BaseTheme, colors } from "helpers/Theme";
import { Order } from "models/Order";
import { formatInTimeZone } from "date-fns-tz";
import { useTranslation } from "react-i18next";

const BookingPaymentStatus = ({ bookingOrder }: { bookingOrder: Order }) => {
  const { t } = useTranslation();

  const fetchPaymentLink = () => {
    RetreatApiClient.requestBookingPaymentLink(bookingOrder.id).then((response) => {
      window.location.assign(response.data.data.link);
    });
  };

  const renderStatus = () => {
    switch (bookingOrder.status) {
      case "cancelled":
        return (
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <ErrorOutline color="error" fontSize="large" />
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {t("Cancelled")}
              </Typography>
              <Typography>
                {t("Your booking was cancelled. Please contact us if you have any questions.")}
              </Typography>
            </Stack>
          </Stack>
        );

      case "completed":
      case "paid":
      case "confirmed":
        return (
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <CheckCircleOutline color="primary" fontSize="large" />
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {t("Booking paid & confirmed")}
              </Typography>
              {bookingOrder.accommodation?.booking_confirmed_description && (
                <RichText htmlString={bookingOrder.accommodation.booking_confirmed_description} />
              )}
              <Typography>
                {t(
                  "Your booking is paid. We have sent you an e-mail with your booking details. We will be happy to receive you on {{date}}. Till then!",
                  {
                    date: formatInTimeZone(
                      new Date(bookingOrder.starts_at),
                      "utc",
                      "ccc LLLL do Y"
                    ),
                  }
                )}
              </Typography>
            </Stack>
          </Stack>
        );

      case "pending":
      case "offered":
      case null:
        if (bookingOrder.payment_provider === "yappy") {
          return <YappyInstructions order={bookingOrder} />;
        }
        return (
          <Stack spacing={1} alignItems="center">
            <Typography variant="h6" textAlign="center">
              {t("Payment required")}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t(
                "Your order is currently only a reservation. Make sure you pay before someone else books your reserved date!"
              )}
            </Typography>
            <Button variant="contained" onClick={fetchPaymentLink}>
              {t("Proceed to payment")}
            </Button>
          </Stack>
        );

      default:
        break;
    }
  };
  return (
    <Box
      marginY={3}
      borderRadius={BaseTheme.shape.borderRadius}
      bgcolor={bookingOrder.status === "cancelled" ? colors.errorLight : colors.primaryLight}
      p={3}
    >
      {renderStatus()}
    </Box>
  );
};

export default BookingPaymentStatus;
