import { Link, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import Loader from "components/generic/loader/Loader";
import { Order } from "models/Order";
import LoadingContainer from "../../../../components/LoadingContainer";
import { useTranslation } from "react-i18next";

const RedirectToPayment = ({ paymentLink, order }: { paymentLink: string; order?: Order }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(function () {
      window.location.assign(
        order?.payment_provider === "yappy"
          ? `/booking/status/${order?.invoice_number}`
          : paymentLink
      );
    }, 5000);
  }, [paymentLink, order]);

  return order ? (
    <Stack direction="column" alignItems="center" spacing={2}>
      <Loader />
      <Typography textAlign="center">{t("Successfully created a reservation!")}</Typography>
      {order.payment_provider === "yappy" ? (
        <>
          <Typography textAlign="center">
            {t("You will be redirected to your order in 5 seconds.")}
          </Typography>
          <Typography textAlign="center">
            {t("Not redirected?")}{" "}
            <Link href={`../booking/status/${order?.invoice_number}`}>{t("Click here")}</Link>
          </Typography>
        </>
      ) : (
        <>
          <Typography textAlign="center">
            {t("You will be redirected to the secure payment provider website in 5 seconds.")}
          </Typography>
          <Typography textAlign="center">
            {t("Not redirected?")} <Link href={paymentLink}>{t("Click here")}</Link>
          </Typography>
        </>
      )}
    </Stack>
  ) : (
    <LoadingContainer />
  );
};

export default RedirectToPayment;
