import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  width?: number | string;
  height?: number | string;
  onScan(result: QrScanner.ScanResult): void;
};

const QRCodeScanner = ({ width = "100%", height = 250, onScan }: Props) => {
  const { t } = useTranslation();
  const videoElement = useRef<HTMLVideoElement>(null);

  const [qrScanner, setQrScanner] = useState<QrScanner | null>(null);

  useEffect(() => {
    const handleScan = (result: QrScanner.ScanResult) => {
      onScan && onScan(result);
    };
    if (videoElement.current) {
      setQrScanner(
        new QrScanner(videoElement.current, handleScan, {
          preferredCamera: "environment",
          highlightScanRegion: true,
          highlightCodeOutline: true,
          // maxScansPerSecond is on 0.5 otherwise it wil scan 3 times per second
          maxScansPerSecond: 0.5,
        })
      );
    }
    return () => setQrScanner(null);
  }, [onScan, videoElement]);

  const startCamera = () => qrScanner?.start();
  const stopCamera = () => qrScanner?.stop();

  if (qrScanner) {
    startCamera();
  } else {
    stopCamera();
  }

  return (
    <Box width={width} height={height}>
      <video ref={videoElement} style={{ width: "100%", height: "100%" }}></video>
    </Box>
  );
};

export default QRCodeScanner;
