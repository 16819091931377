import { Snackbar, Alert } from "@mui/material";
import { differenceInMinutes } from "date-fns";
import RetreatApiClient from "helpers/RetreatApiClient";
import { useAuth } from "hooks/useAuth";
import useSocket from "hooks/useSocket";
import { isEmpty } from "lodash";
import { Order } from "models/Order";
import { createContext, ReactNode, useEffect, useReducer, useState } from "react";
import KitchenReducer, {
  KitchenState,
  KitchenTableDynamicType,
  KitchenTableDynamicTypeKeyValue,
} from "./KitchenReducer";

const storageKitchenState: KitchenState = localStorage.getItem("kitchen")
  ? JSON.parse(localStorage.getItem("kitchen")!)
  : null;

const defaultTablesObject: KitchenTableDynamicTypeKeyValue = {
  preparing: {
    id: 1,
    slug: "preparing",
    title: "Preparing Orders",
    statusToFetch: ["processing"],
    data: [],
    loading: false,
    meta: { current_page: 1 },
    lastFetched: new Date(),
  },
  new: {
    id: 2,
    slug: "new",
    title: "New Orders",
    statusToFetch: ["pending"],
    data: [],
    loading: false,
    meta: { current_page: 1 },
    lastFetched: new Date(),
  },
  completed: {
    id: 3,
    slug: "completed",
    title: "Completed Orders",
    statusToFetch: ["completed"],
    data: [],
    loading: false,
    meta: { current_page: 1 },
    lastFetched: new Date(),
  },
};

export const initialKitchenReducerState = {
  tables: isEmpty(storageKitchenState) ? defaultTablesObject : storageKitchenState.tables,
  loading: false,
  error: null,
};

type KitchenContextValues = {
  tables: KitchenTableDynamicTypeKeyValue;
  loading: boolean;
  initialLoad(): void;
  updateTableBySlug(slug: string): void;
  handleChangePageBySlug(slug: string, page: number): void;
  addNewOrderFromSocket(order: Order): void;
  handleOpenToast(status: "error" | "success", message: string): void;
};

export const KitchenContext = createContext<KitchenContextValues>({
  tables: {},
  loading: false,
  initialLoad: () => null,
  updateTableBySlug: () => null,
  handleChangePageBySlug: () => null,
  addNewOrderFromSocket: () => null,
  handleOpenToast: () => null,
});

export const KitchenProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const [state, dispatch] = useReducer(KitchenReducer, initialKitchenReducerState);

  // Toast state
  const [toastOpen, setToastOpen] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useSocket("order-created", "OrderCreated", (value: { order: Order }) => {
    if (value.order.activity.booking_mode === "MENU") {
      addNewOrderFromSocket(value.order);
    }
  });

  useSocket("order-updated", "OrderUpdated", (value: { order: Order }) => {
    if (value.order.activity.booking_mode === "MENU" && value.order.viewed_at !== null) {
      updateOrderFromSocket(value.order);
    }
  });

  useEffect(() => {
    /// check faulty state in store
    const checkedState = Object.values(state.tables ?? {}).map((table) => {
      return (
        table.hasOwnProperty("id") &&
        table.hasOwnProperty("slug") &&
        table.hasOwnProperty("title") &&
        table.hasOwnProperty("statusToFetch") &&
        table.hasOwnProperty("loading") &&
        table.hasOwnProperty("data") &&
        table.hasOwnProperty("meta") &&
        table.hasOwnProperty("lastFetched")
      );
    });
    if (!checkedState.every(Boolean)) {
      dispatch({ type: "resetDefaultTables", payload: defaultTablesObject });
    }
  }, [state.tables]);

  const getOrders = (table: KitchenTableDynamicType, page?: number) => {
    return RetreatApiClient.getOrders(
      {
        booking_mode: "MENU",
        ...(table.statusToFetch &&
          table.statusToFetch.reduce((prev: { [key: string]: boolean }, current) => {
            prev[current] = true;
            return prev;
          }, {})),
      },
      user.token,
      { page: page ?? table.meta.current_page ?? 1, limit: 5 }
    );
  };

  const updateTableData = (
    slug: string,
    data: Order[],
    meta: { last_page?: number; current_page?: number }
  ) => {
    dispatch({ type: "updateTableData", payload: { slug, data, meta } });
  };

  const initialLoad = () => {
    const slugs = Object.keys(state.tables ?? {});
    slugs.forEach((slug, index) => {
      if (
        differenceInMinutes(new Date(), new Date(state.tables[slug].lastFetched)) > 1 ||
        isEmpty(state.tables[slug].data)
      ) {
        dispatch({ type: "startLoading", payload: { slug } });
        getOrders(state.tables[slug])
          .then((result) => {
            updateTableData(slug, result.data.data, result.data.meta);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  const addNewOrderFromSocket = (order: Order) => {
    dispatch({ type: "newOrder", payload: { order } });
  };

  const updateOrderFromSocket = (order: Order) => {
    dispatch({ type: "updateOrder", payload: { order } });
  };

  const handleChangePageBySlug = (slug: string, page: number) => {
    const table = state.tables[slug];
    if (table) {
      dispatch({ type: "startLoading", payload: { slug } });
      getOrders(table, page)
        .then((result) => {
          updateTableData(slug, result.data.data, result.data.meta);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const updateTableBySlug = (slug: string) => {
    const table = state.tables[slug];
    if (table) {
      dispatch({ type: "startLoading", payload: { slug } });
      getOrders(table, table.meta.current_page)
        .then((result) => {
          updateTableData(slug, result.data.data, result.data.meta);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleOpenToast = (status: "error" | "success", message: string) => {
    if (status === "error") setToastError(true);
    setToastOpen(true);
    setToastMessage(message);
  };

  const handleToastClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setToastMessage("");
    setToastOpen(false);
    setToastError(false);
  };

  return (
    <KitchenContext.Provider
      value={{
        initialLoad,
        tables: state.tables,
        loading: state.loading,
        updateTableBySlug,
        addNewOrderFromSocket,
        handleChangePageBySlug,
        handleOpenToast,
      }}
    >
      {children}
      <Snackbar
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </KitchenContext.Provider>
  );
};
