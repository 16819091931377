import { RefreshOutlined } from "@mui/icons-material";
import { Stack, Typography, Box, Pagination } from "@mui/material";
import { KitchenTableDynamicType } from "contexts/KitchenReducer";
import { useKitchen } from "hooks/useKitchen";
import { useTranslation } from "react-i18next";
import SkeletonTable from "./SkeletonTable";
import Table from "./Table";

const OrdersTable = ({ table }: { table: KitchenTableDynamicType }) => {
  const { t } = useTranslation();

  const { handleChangePageBySlug, updateTableBySlug } = useKitchen();

  const handleOnChangePage = (e: any, newPageNumber: number) => {
    handleChangePageBySlug(table.slug, newPageNumber);
  };

  const handleUpdateTable = () => {
    updateTableBySlug(table.slug);
  };

  return (
    <Stack key={table.id} spacing={2}>
      <Stack
        justifyContent="justify-between"
        py={2}
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <Typography variant="h6" flex={1}>
          {table.title}
        </Typography>
        <Stack
          direction="row"
          justifyItems="center"
          alignItems="center"
          spacing={1}
          sx={{ cursor: "pointer" }}
          onClick={handleUpdateTable}
        >
          <Box
            width={10}
            height={10}
            borderRadius={200}
            bgcolor={table.loading ? "blue" : "greenyellow"}
          />
          <Typography variant="body1">{t(table.loading ? "Refreshing" : "Online")}</Typography>
          <RefreshOutlined />
        </Stack>
      </Stack>
      {/* TODO Check for other options to contain table on mobile */}
      <Box sx={{ width: { xs: "90vw", md: "100%" } }}>
        {table.loading ? <SkeletonTable /> : <Table rows={table.data ?? []} slug={table.slug} />}
      </Box>
      <Box marginY={2} display="flex" justifyContent="center">
        <Pagination
          count={table.meta.last_page ?? 1}
          page={table.meta.current_page}
          onChange={handleOnChangePage}
        />
      </Box>
    </Stack>
  );
};

export default OrdersTable;
