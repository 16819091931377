import axios from "axios";
import { format } from "date-fns";
import { Order } from "models/Order";
import { DateRange } from "react-day-picker";
import { ActivityLink } from "models/ActivityLink";
import qs from "qs";
import { CreateOrderPayload } from "types/CreateOrderData";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/signin";
    }
  }
);

const signIn = (email: string, password: string) => {
  return axiosClient.post(`login`, {
    email,
    password,
  });
};

const getOrders = (filter: any, token: string, meta?: { page?: number; limit?: number }) => {
  return axiosClient.get(`orders`, {
    params: { filter, ...(meta && { ...meta }) },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};

const createReceiptFromOrders = (
  orders: Order[],
  userId: number,
  paymentProvider: string,
  token: string
) => {
  return axiosClient.post(
    `transactions/from-orders`,
    {
      orders: orders.map((order) => order.id),
      user_id: userId,
      payment_provider: paymentProvider,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const getUserByEmail = (email: string, token: string) => {
  return axiosClient.get(`users`, {
    params: { "filter[email]": email },
    headers: { Authorization: `Bearer ${token}` },
  });
};

const createUser = (email: string, firstName: string, lastName: string, token: string) => {
  return axiosClient.post(
    `users`,
    { email: email, first_name: firstName, last_name: lastName },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const checkOptionGroupsAvailability = (
  startsAt: Date,
  endsAt: Date,
  numberOfGuests: number,
  activityId: number
) => {
  return axiosClient.get(`open/activities/${activityId}/availability/check-option-groups`, {
    params: {
      starts_at: format(startsAt, "yyyy-MM-dd"),
      ends_at: format(endsAt, "yyyy-MM-dd"),
      people: numberOfGuests,
    },
  });
};

const checkRoomAvailability = (dateRange: DateRange, numberOfGuests: number) => {
  return axiosClient.get(`open/bookings/availability/check`, {
    params: {
      starts_at: format(dateRange.from as Date, "yyyy-MM-dd"),
      ends_at: format(dateRange.to as Date, "yyyy-MM-dd"),
      accommodation_id: process.env.REACT_APP_ACCOMMODATION_ID ?? 1,
      people: numberOfGuests,
    },
  });
};

const checkRoomTypeAvailability = (dateRange: DateRange, numberOfGuests: number) => {
  return axiosClient.get(`open/bookings/availability/check-room-types`, {
    params: {
      starts_at: format(dateRange.from as Date, "yyyy-MM-dd"),
      ends_at: format(dateRange.to as Date, "yyyy-MM-dd"),
      accommodation_id: process.env.REACT_APP_ACCOMMODATION_ID ?? 1,
      people: numberOfGuests,
    },
  });
};

const createOpenOrder = (order: CreateOrderPayload) => {
  return axiosClient.post(`open/activities/availability/reserve`, order);
};

const getOrderByInvoiceNumber = (invoiceNumber: string) => {
  return axiosClient.get(`open/orders/${invoiceNumber}/status`);
};

const reserveRoom = (payload: any) => {
  return axiosClient.post(`open/bookings/availability/reserve`, payload);
};

const requestReceiptPaymentLink = (receiptId: number) => {
  return axiosClient.post(`open/transactions/${receiptId}/payment-request-link`);
};

const completeOrderByInvoiceNumber = (invoiceNumber: string, token: string) => {
  return axiosClient.post(
    `orders/${invoiceNumber}/complete-by-invoice-number`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const requestBookingPaymentLink = (bookingId: number) => {
  return axiosClient.post(`open/bookings/${bookingId}/payment-request-link`);
};

const requestOrderPaymentLink = (orderId: number) => {
  return axiosClient.post(`open/orders/${orderId}/payment-request-link`);
};

const setOrderStatus = (orderId: number, newStatus: string, token: string) => {
  if (newStatus === "processing") {
    return axiosClient.post(
      `orders/${orderId}/process`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }
  return axiosClient.post(
    `orders/${orderId}/confirm`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const viewOrder = (orderId: number | string, token: string) => {
  return axiosClient.put(
    `orders/${orderId}/view`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const fetchActivityLinkSettings = (uuid: string) => {
  return axiosClient.get(`open/activity-link/${uuid}`);
};

const getActivityLinkMenu = (slug: string) => {
  return axiosClient.get(`open/activities/${slug}`);
};

const createActivityLink = (activityLink: ActivityLink, token: string) => {
  return axiosClient.post(
    `activity-links`,
    { ...activityLink },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const updateActivityLink = (activityLink: ActivityLink, token: string) => {
  return axiosClient.put(
    `activity-links/${activityLink.id}`,
    { ...activityLink },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const deleteActivityLink = (activityLink: ActivityLink, token: string) => {
  return axiosClient.delete(`activity-links/${activityLink.id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const markTransactionAsPaid = (transactionId: number, token: string) => {
  return axiosClient.post(
    `transactions/${transactionId}/confirm`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const fetchOptionGroup = (optionGroupId: number, token: string) => {
  return axiosClient.get(`option-groups/${optionGroupId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const RetreatApiClient = {
  signIn,
  createUser,
  getUserByEmail,
  createReceiptFromOrders,
  checkOptionGroupsAvailability,
  checkRoomAvailability,
  reserveRoom,
  getOrderByInvoiceNumber,
  requestReceiptPaymentLink,
  requestBookingPaymentLink,
  requestOrderPaymentLink,
  checkRoomTypeAvailability,
  completeOrderByInvoiceNumber,
  getOrders,
  setOrderStatus,
  viewOrder,
  fetchActivityLinkSettings,
  getActivityLinkMenu,
  createOpenOrder,
  createActivityLink,
  updateActivityLink,
  deleteActivityLink,
  markTransactionAsPaid,
  fetchOptionGroup,
};

export default RetreatApiClient;
