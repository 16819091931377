import { Container, Stack, Typography } from "@mui/material";

const EmptyOrder = ({ title, description }: { title: string; description: string }) => {
  return (
    <Container maxWidth="md">
      <Stack justifyContent="center" textAlign="center" display="flex" height="50vh">
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Stack>
    </Container>
  );
};

export default EmptyOrder;
