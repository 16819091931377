import { TextField, TextFieldProps, useTheme } from "@mui/material";

const TextInput = (props: TextFieldProps) => {
  const theme = useTheme()
  return (
    <TextField
      variant="outlined"
      InputProps={{
        sx: { backgroundColor: theme.palette.background.default },
      }}
      {...props}
    />
  );
};

export default TextInput;
