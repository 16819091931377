import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Box,
  Container,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import EmptyOrder from "components/order-food/EmptyOrder";
import OrderOverviewCard from "components/order-food/OrderOverviewCard";
import { SelectMenuActivityContext } from "contexts/WaiterOrderFoodContext";
import { calculateOrderPrice } from "helpers/MenuActivityHelper";
import RetreatApiClient from "helpers/RetreatApiClient";
import { generateCreateOrderPayload } from "helpers/SelectMenuActivityHelper";
import { formatMoney } from "helpers/Shared";
import { colors } from "helpers/Theme";
import { isEmpty } from "lodash";
import { Activity, ActivityOption, Option } from "models/Activity";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CreateOrderTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    order,
    setOrder,
    activity,
    remarks,
    setRemarks,
    tag,
    setTag,
    selectedOptionGroups,
    setSelectedOptionGroups,
  } = useContext(SelectMenuActivityContext);

  const {
    has_option_groups: hasOptionGroups,
    option_groups: optionGroups,
    tags,
    id: activityId,
  } = activity as Activity;

  const [loading, setLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastError] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useMemo(() => {
    if (hasOptionGroups) {
      if (!selectedOptionGroups) {
        const formattedOptionGroups = optionGroups.reduce(
          (prevValue, option) => ({ ...prevValue, [option.id]: null }),
          {}
        );
        setSelectedOptionGroups(formattedOptionGroups);
      }
    }
  }, [hasOptionGroups, optionGroups, setSelectedOptionGroups, selectedOptionGroups]);

  const handleChangeNotes = (e: any) => setRemarks(e.target.value);

  const handlePostOrder = () => {
    setLoading(true);
    RetreatApiClient.createOpenOrder(
      generateCreateOrderPayload(
        activityId,
        order,
        remarks,
        selectedOptionGroups ?? {},
        tag ? [tag] : []
      )
    )
      .then((response) => {
        const { order } = response.data.data;
        setOrder([]);
        setTag(null);
        setRemarks("");
        setSelectedOptionGroups(null);
        setLoading(false);
        navigate("../orders/" + order.id);
      })
      .catch((error) => {
        setLoading(false);
        setToastOpen(true);
        setToastMessage(t("Something went wrong. Please try again"));
      });
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setToastMessage("");
    setToastOpen(false);
  };

  const handleUpdateOptionGroups = (optionGroupId: number, newValue: any | null) => {
    setSelectedOptionGroups((prevValue: any) => {
      return { ...prevValue, [optionGroupId]: newValue };
    });
  };

  const renderOptionGroups = () => {
    return (
      hasOptionGroups && (
        <Stack spacing={2}>
          {optionGroups.map((option: ActivityOption) => {
            const handleChange = (event: any, newValue: Option | null) => {
              handleUpdateOptionGroups(option.id, newValue);
            };
            return (
              selectedOptionGroups && (
                <Stack
                  key={option.id}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography flex={1}>{option.name}</Typography>
                  <Autocomplete
                    disablePortal
                    id={`optiongroups-${option.id}`}
                    options={option.options}
                    getOptionLabel={(option: Option) => option.name}
                    sx={{ flex: 3 }}
                    value={selectedOptionGroups[option.id]}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          sx: { backgroundColor: theme.palette.background.default },
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Stack>
              )
            );
          })}
        </Stack>
      )
    );
  };

  const renderTags = () => {
    return (
      !isEmpty(tags) && (
        <Box marginY={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography flex={1}>{t("Tag")}</Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={tags}
              getOptionLabel={(option: { id: number; name: string }) => option.name}
              sx={{ flex: 3 }}
              value={tag}
              onChange={(event: any, newValue: { id: number; name: string } | null) => {
                setTag(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    sx: { backgroundColor: theme.palette.background.default },
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Stack>
        </Box>
      )
    );
  };

  return (
    <Box>
      {isEmpty(order) ? (
        <EmptyOrder
          title={"Empty Basket"}
          description={t("The basket is empty, select some items from the menu first.")}
        />
      ) : (
        <Box>
          <Box
            bgcolor={theme.palette.mode === "dark" ? colors.darkMedium : colors.greyLight}
            paddingY={2}
          >
            <Container maxWidth="md">
              {renderOptionGroups()}
              {renderTags()}
              <Box marginY={2}>
                <TextField
                  multiline
                  fullWidth
                  id="outlined-basic"
                  label={t("Notes (optional)")}
                  onChange={handleChangeNotes}
                  value={remarks}
                  InputProps={{
                    sx: { backgroundColor: theme.palette.background.default },
                  }}
                />
              </Box>
            </Container>
          </Box>
          <Container maxWidth="md">
            <Box marginY={2}>
              <OrderOverviewCard
                products={order}
                activity={activity}
                total={calculateOrderPrice(order)}
              />
            </Box>
            <Box height={150} />
            <Box position="fixed" bottom={70} right={0} zIndex={100} width="100%">
              <Box display="flex" alignItems="center" flexDirection="column" px={2}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  loading={loading}
                  disabled={order.length === 0 || loading}
                  onClick={handlePostOrder}
                >
                  {t("Place order of {{price}}", {
                    price: formatMoney(calculateOrderPrice(order)),
                  })}
                </LoadingButton>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      <Snackbar open={toastOpen} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateOrderTab;
