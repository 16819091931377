import { AccountBox } from "@mui/icons-material";
import {
  Box,
  Button,
  createTheme,
  CssBaseline,
  Menu,
  MenuItem,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import ThemeSwitch from "components/controls/ThemeSwitch";
import Header from "components/Header";
import ThemeContext from "contexts/ThemeContext";
import { DarkTheme, LightTheme } from "helpers/Theme";
import { useAuth } from "hooks/useAuth";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useOutlet } from "react-router-dom";

const ProtectedLayout = () => {
  const [themeMode, setThemeMode] = useLocalStorage<"light" | "dark">("theme", "light");

  const { user } = useAuth();
  const outlet = useOutlet();

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [setThemeMode]
  );

  // TODO use a combination of system preference and user preference
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(
    () => createTheme(themeMode === "dark" ? DarkTheme : LightTheme),
    [themeMode]
  );

  if (!user) {
    return <Navigate to="/signin" />;
  }

  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {outlet}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const ProtectedWithHeaderLayout = () => {
  const { t } = useTranslation();
  const { user, signOut } = useAuth();
  const outlet = useOutlet();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleNavigateToDashboard = () => navigate("../dashboard");

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    signOut();
    handleClose();
  };

  const renderAccountMenu = () => {
    return (
      <Box>
        <Button variant="text" endIcon={<AccountBox />} onClick={handleMenu} color="inherit">
          {user.user.first_name}
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleSignout}>{t("Sign out")}</MenuItem>
        </Menu>
        <ThemeSwitch />
      </Box>
    );
  };

  return (
    <div>
      <Header onClickLogo={handleNavigateToDashboard} rightComponent={renderAccountMenu()} />
      {outlet}
    </div>
  );
};

export default ProtectedLayout;
