import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { Box, Button, useTheme, Divider, IconButton, Stack, Typography } from "@mui/material";
import LoadingContainer from "components/LoadingContainer";
import RetreatApiClient from "helpers/RetreatApiClient";
import { useAuth } from "hooks/useAuth";
import useFetch from "hooks/useFetch";
import { ActivityLink } from "models/ActivityLink";
import QRCode from "qrcode.react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ActivityLinkDialog from "./components/ActivityLinkDialog";
import ReactJson from "react-json-view";
import { colors } from "helpers/Theme";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ActivityLinksPage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selectedActivityLink, setSelectedActivityLink] = useState<ActivityLink | undefined>(
    undefined
  );

  const { data: activityLinks, loading } = useFetch(
    "activity-links",
    {},
    { Authorization: `Bearer ${user.token}` }
  );

  const updateActivityLink = (newActivityLink: ActivityLink) => {
    RetreatApiClient.updateActivityLink(newActivityLink, user.token).then((res) =>
      window.location.reload()
    );
  };

  const storeActivityLink = (newActivityLink: ActivityLink) => {
    RetreatApiClient.createActivityLink(newActivityLink, user.token).then((res) =>
      window.location.reload()
    );
  };

  const deleteActivityLink = (newActivityLink: ActivityLink) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this link?")) {
      RetreatApiClient.deleteActivityLink(newActivityLink, user.token).then((res) =>
        window.location.reload()
      );
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedActivityLink(undefined);
  };

  const handleDialogSave = (newActivityLink: ActivityLink, update: boolean) => {
    if (update) updateActivityLink(newActivityLink);
    else storeActivityLink(newActivityLink);
    handleClose();
  };

  return (
    <Box>
      <Stack marginY={2} spacing={1}>
        <Stack
          direction="row"
          justifyContent="justify-between"
          spacing={1}
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" flex={1}>
            {t("Activity Links")}
          </Typography>
          <Button onClick={handleClickOpen}>{t("New")}</Button>
        </Stack>
        <Stack spacing={1}>
          {loading ? (
            <LoadingContainer />
          ) : (
            activityLinks &&
            activityLinks.map((activityLink: ActivityLink) => {
              const handleOpen = () => {
                setSelectedActivityLink(activityLink);
                handleClickOpen();
              };
              const handleDelete = () => {
                deleteActivityLink(activityLink);
              };
              const handleCopy = () => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_QR_CODE_BASE_URL}${activityLink.uuid}`
                );
              };
              return (
                <Box key={activityLink.id}>
                  <Stack direction="row" key={activityLink.id} spacing={2} mb={1}>
                    <Stack alignItems="center">
                      <CodeImageComponent uuid={activityLink.uuid} />
                      <Stack direction="row">
                        <IconButton onClick={handleOpen}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                          <Delete />
                        </IconButton>
                        <IconButton onClick={handleCopy}>
                          <ContentCopy />
                        </IconButton>
                      </Stack>
                    </Stack>
                    <Stack spacing={1}>
                      <Typography>{activityLink.name}</Typography>
                      <Typography
                        variant="caption"
                        maxHeight={140}
                        overflow="auto"
                        width="100%"
                        bgcolor={
                          theme.palette.mode === "dark"
                            ? colors.white
                            : theme.palette.background.default
                        }
                      >
                        {activityLink.settings ? (
                          <ReactJson
                            src={activityLink.settings}
                            collapsed
                            name={false}
                            enableClipboard={false}
                          />
                        ) : (
                          "null"
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                </Box>
              );
            })
          )}
        </Stack>
      </Stack>
      <ActivityLinkDialog
        open={open}
        onClose={handleClose}
        onSave={handleDialogSave}
        currentActivityLink={selectedActivityLink}
      />
    </Box>
  );
};

const CodeImageComponent = ({ uuid }: { uuid: string }) => {
  const [overlayVisible, setOverlayVisible] = useState(false);

  const toggleOverlay = () => setOverlayVisible(!overlayVisible);

  const downloadCode = () => {
    var link = document.createElement("a");
    link.download = `${uuid}.png`;
    const element = document.getElementById("qrcode-canvas") as HTMLCanvasElement;
    link.href = element.toDataURL();
    link.click();
  };

  return (
    <Box borderRadius={3} p={2} position="relative" overflow="hidden">
      {overlayVisible && (
        <Box
          position="absolute"
          top={0}
          right={0}
          bgcolor={colors.dark + "AA"}
          width="100%"
          height="100%"
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
          onClick={toggleOverlay}
          sx={{ cursor: "pointer" }}
        >
          <IconButton color="info" size="large" onClick={downloadCode}>
            <FileDownloadIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
      <QRCode
        id="qrcode-canvas"
        renderAs="canvas"
        value={`${process.env.REACT_APP_QR_CODE_BASE_URL}${uuid}`}
        width={100}
        onClick={toggleOverlay}
      />
    </Box>
  );
};

export default ActivityLinksPage;
