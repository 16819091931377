import { styled, Tab, TabProps, Tabs, TabsProps } from "@mui/material";
import { colors } from "helpers/Theme";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
}

const StyledTabs = styled((props: StyledTabsProps & TabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  paddingRight: 0,
  paddingLeft: 0,
  color: colors.white,
  "& .MuiTabs-scrollButtons": {
    backgroundColor: colors.dark,
    "& svg": {
      height: "2em",
      width: "2em",
    },
  },
  "& .MuiTabs-indicator": {
    top: 5,
    bottom: 5,
    right: 5,
    height: "auto",
    background: "none",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      left: 4,
      right: 4,
      bottom: 0,
      borderRadius: 12,
      backgroundColor: colors.primary,
    },
  },
});

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps & TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: colors.white,
    minWidth: 100,
    zIndex: 2,
    "&.Mui-selected": {
      color: colors.white,
    },
  })
);

const QuickFilterStyles = { StyledTab, StyledTabs };

export default QuickFilterStyles;
