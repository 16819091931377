import { LoadingButton } from "@mui/lab";
import { Box, Paper, Container, Stack, Button } from "@mui/material";
import { Steps } from "types/StepsEnum";

type Props = {
  activeStep: Steps;
  loading: boolean;
  onBack(): void;
  onNext(): void;
  isValid: boolean;
};

const BottomStepperNavigator = ({ activeStep, loading, onBack, onNext, isValid }: Props) => {
  return (
    <Box position="fixed" bottom={0} left={0} width="100%" zIndex={100}>
      <Paper elevation={4} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
        <Container maxWidth="sm">
          <Stack direction="row" p={2} spacing={2} justifyContent="center">
            {activeStep !== Steps.RESERVE && (
              <Button variant="outlined" onClick={onBack} disabled={loading} sx={{ width: "40%" }}>
                Back
              </Button>
            )}
            <LoadingButton
              variant="contained"
              onClick={onNext}
              disabled={loading || !isValid}
              fullWidth
              loading={loading}
            >
              {activeStep === Steps.CONFIRM ? "Confirm and Pay" : "Next"}
            </LoadingButton>
          </Stack>
        </Container>
      </Paper>
    </Box>
  );
};

export default BottomStepperNavigator;
