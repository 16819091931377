import RetreatApiClient from "helpers/RetreatApiClient";
import { Activity } from "models/Activity";
import { ActivityLink } from "models/ActivityLink";
import { Order, OrderProduct } from "models/Order";
import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { PaymentMethod } from "models/PaymentMethod";
import { Snackbar, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CreateOrderPayload } from "types/CreateOrderData";
import { useLocalStorage } from "hooks/useLocalStorage";
import { generateNewOrderObject } from "helpers/MenuActivityHelper";
import { getActivitiesBySlugs } from "helpers/ClientOrderHelper";

type ClientOrderContextValues = {
  selectedActivity?: Activity;
  activityLink?: ActivityLink;
  activities: Activity[];
  paymentMethods: PaymentMethod[];
  loading: boolean;
  creatingOrder: boolean;
  email: string;
  selectedPaymentMethod: PaymentMethod | undefined;
  order: OrderProduct[];
  setSelectedActivity: React.Dispatch<React.SetStateAction<Activity | undefined>>;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod | undefined>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  clearOrder: () => void;
  createOrder: (orderPayload: CreateOrderPayload) => void;
  changeOrder: (currentOrder: OrderProduct[], product: OrderProduct, newValue: number) => void;
  addOrderToStorage: (order: Order) => void;
  getOrdersFromStorage: () => Order[];
};

export const ClientOrderContext = createContext<ClientOrderContextValues>({
  selectedActivity: undefined,
  setSelectedActivity: () => null,
  setEmail: () => null,
  setSelectedPaymentMethod: () => null,
  activityLink: undefined,
  activities: [],
  email: "string",
  paymentMethods: [],
  selectedPaymentMethod: undefined,
  order: [],
  createOrder: () => null,
  changeOrder: () => null,
  clearOrder: () => null,
  loading: false,
  creatingOrder: false,
  addOrderToStorage: (order: Order) => null,
  getOrdersFromStorage: () => [],
});

export const ClientOrderProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Previous orders from storage
  const [storageOrders, setStorageOrders] = useLocalStorage<Order[]>("orders", []);

  // User input state
  const [selectedActivity, setSelectedActivity] = useState<Activity | undefined>(undefined);
  const [order, setOrder] = useState<OrderProduct[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>(
    undefined
  );
  const [email, setEmail] = useState("");

  // Server data state
  const [activityLink, setActivityLink] = useState<ActivityLink | undefined>(undefined);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [creatingOrder, setCreatingOrder] = useState(false);

  // Toast state
  const [toastOpen, setToastOpen] = useState(false);
  const [toastError] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // Fetch payment methods
  const { data: paymentMethods } = useFetch("open/payment-methods", {
    exclude: ["cash", "bill", "yappy"],
  });

  // Initial fetch activity settings by uuid
  useEffect(() => {
    if (uuid) {
      RetreatApiClient.fetchActivityLinkSettings(uuid).then((activityLink) => {
        setActivityLink(activityLink.data.data);
      });
    }
  }, [uuid, searchParams, navigate]);

  const goToOrderPage = useCallback(
    (activitySlug: string, paymentSuccessful: string, invoiceNumber: string) => {
      RetreatApiClient.getActivityLinkMenu(activitySlug).then((response) => {
        setSelectedActivity(response.data.data);
        if (paymentSuccessful && invoiceNumber) {
          setToastOpen(true);
          setToastMessage("Payment successful");
          navigate("create-order");
        } else {
          navigate("menu");
        }
      });
    },
    [navigate]
  );

  const goToChooseActivityPage = useCallback(
    (activities: { slug: string }[]) => {
      const slugs = activities.map((activity) => activity.slug);
      !selectedActivity &&
        getActivitiesBySlugs(slugs).then((activities: Activity[]) => {
          setActivities(activities);
          navigate("choose");
        });
    },
    [navigate, selectedActivity]
  );

  const goToMenuPage = useCallback(
    (slug: string) => {
      !selectedActivity &&
        RetreatApiClient.getActivityLinkMenu(slug).then((activity) => {
          setSelectedActivity(activity.data.data);
          activity.data.data.booking_mode === "MENU"
            ? navigate("menu")
            : navigate("/book/activities/" + activity.data.data.slug);
        });
    },
    [navigate, selectedActivity]
  );

  useEffect(() => {
    const activitySlug = searchParams.get("activity_slug");
    const paymentSuccessful = searchParams.get("successful");
    const invoiceNumber = searchParams.get("invoice_number");
    if (activitySlug && paymentSuccessful) {
      goToOrderPage(activitySlug, paymentSuccessful ?? "", invoiceNumber ?? "");
    } else if (activitySlug) {
      goToMenuPage(activitySlug);
    } else if (activityLink && activityLink.settings && activityLink.settings.activities) {
      activityLink.settings.activities.length > 1
        ? goToChooseActivityPage(activityLink.settings.activities)
        : goToMenuPage(activityLink.settings.activities[0].slug);
    }
  }, [
    activityLink,
    searchParams,
    navigate,
    selectedActivity,
    goToOrderPage,
    goToChooseActivityPage,
    goToMenuPage,
  ]);

  const changeOrder = (currentOrder: OrderProduct[], product: OrderProduct, newValue: number) => {
    setOrder(generateNewOrderObject(currentOrder, product, newValue));
  };

  const clearOrder = () => {
    setOrder([]);
  };

  // Create a new order and redirects to payment page when payment_link is defined.
  const createOrder = (payload: CreateOrderPayload) => {
    setCreatingOrder(true);
    RetreatApiClient.createOpenOrder(payload)
      .then((response) => {
        const { order, payment_link }: { order: Order; payment_link: string } = response.data.data;
        setToastOpen(true);
        setToastMessage(
          t("Order {{invoiceNumber}} successfully created, redirecting to payment page.", {
            invoiceNumber: order.invoice_number,
          })
        );
        if (payment_link) {
          window.location.assign(payment_link);
        }
        addOrderToStorage(order, payment_link);
        clearOrder();
      })
      .catch((error) => {
        setToastOpen(true);
        setToastMessage(t("Something went wrong. Please try again"));
      })
      .finally(() => setCreatingOrder(false));
  };

  const addOrderToStorage = (order: Order, paymentLink?: string) => {
    setStorageOrders([{ ...order, payment_link: paymentLink }, ...storageOrders]);
  };

  const getOrdersFromStorage = () => {
    return storageOrders ?? [];
  };

  const handleToastClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setToastMessage("");
    setToastOpen(false);
  };

  return (
    <ClientOrderContext.Provider
      value={{
        selectedActivity,
        loading: false,
        activityLink,
        activities,
        paymentMethods,
        order,
        email,
        selectedPaymentMethod,
        setSelectedActivity,
        changeOrder,
        clearOrder,
        setSelectedPaymentMethod,
        setEmail,
        addOrderToStorage,
        getOrdersFromStorage,
        createOrder,
        creatingOrder,
      }}
    >
      {children}
      <Snackbar open={toastOpen} onClose={handleToastClose} autoHideDuration={5000}>
        <Alert
          onClose={handleToastClose}
          severity={toastError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </ClientOrderContext.Provider>
  );
};
