import { useContext } from "react";
import { ClientOrderContext } from "contexts/ClientOrderContext";
import MenuTabContent from "components/order-food/MenuTabContent";

const MenuTab = () => {
  const { selectedActivity, order, changeOrder } = useContext(ClientOrderContext);
  return (
    <MenuTabContent currentOrder={order} changeOrder={changeOrder} activity={selectedActivity} />
  );
};

export default MenuTab;
