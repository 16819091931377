import { Box, Container } from "@mui/material";
import OrderActivityInfo from "views/order-status-page/components/OrderActivityInfo";
import OrderPaymentStatus from "./components/OrderPaymentStatus";
import OrderSummary from "./components/OrderSummary";
import LoadingContainer from "components/LoadingContainer";
import useFetch from "hooks/useFetch";
import { useParams, useNavigate } from "react-router-dom";
import QuickSummary from "../../components/QuickSummary";
import { Order } from "models/Order";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import useInterval from "hooks/useInterval";
import { formatMoney } from "helpers/Shared";

const OrderStatusPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const fullSummaryRef = useRef<any>();

  const { data, loading, error } = useFetch(`open/orders/${params.orderNumber}/status`);

  const [order, setOrder] = useState<Order | null>(null);
  const [polling, setPolling] = useState<boolean>(false);

  useEffect(() => {
    setOrder(data);
    if (data && data.status) {
      setPolling(data.status === "paid" || data.status === "confirmed");
    }
  }, [data]);

  useInterval(
    async () => {
      const newOrderData = (
        await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}open/orders/${params.orderNumber}/status`
        )
      ).data.data;
      if (newOrderData) {
        setOrder(newOrderData);
        if (newOrderData.status === "completed") {
          setPolling(false);
        }
      }
    },
    polling ? 5000 : null
  );

  const scrollToFullSummary = () => {
    fullSummaryRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const renderPageContent = () => {
    if (error) {
      navigate("/");
      return <></>;
    }

    if (loading) {
      return <LoadingContainer />;
    }

    return (
      order && (
        <Box>
          <OrderActivityInfo activity={order.activity} />
          <QuickSummary
            totalPrice={formatMoney(order.total)}
            invoiceNumber={order.invoice_number}
            reservationDate={order.starts_at}
            reservationPickupTimeStart={order.starts_at}
            reservationPickupTimeEnd={order.ends_at}
            people={order.people}
            alternativePrices={order.activity_alternative_prices}
            onViewDetailsClick={scrollToFullSummary}
          />
          <OrderPaymentStatus order={order} />
          <Box ref={fullSummaryRef}>
            <OrderSummary order={order} />
          </Box>
        </Box>
      )
    );
  };

  return <Container maxWidth="sm">{renderPageContent()}</Container>;
};

export default OrderStatusPage;
