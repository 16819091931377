import { Close } from "@mui/icons-material";
import { Box, Container, Modal, Stack, Typography } from "@mui/material";
import DisplayImageCarousel from "components/DisplayImageCarousel";
import { BookRoomContext } from "contexts/BookRoomContext";
import { colors } from "helpers/Theme";
import { isEmpty } from "lodash";
import { RoomType } from "models/RoomType";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RoomTypeCard from "./RoomTypeCard";

const SelectRoomType = () => {
  const { selectedRoomType, setSelectedRoomType, availabilityData } = useContext(BookRoomContext);

  const [galleryOpen, setGalleryOpen] = useState(false);
  const [roomTypeImages, setRoomTypeImages] = useState<RoomType | null>(null);

  const handleGalleryOpen = (roomType: RoomType) => {
    setRoomTypeImages(roomType);
    setGalleryOpen(true);
  };
  const handleGalleryClose = () => setGalleryOpen(false);

  const changeSelectedRoomType = (roomType: RoomType) => {
    setSelectedRoomType(roomType);
  };

  useEffect(() => {
    if (selectedRoomType) {
      const newValueForSelectedRoomType = availabilityData?.room_types.find(
        (roomType) => roomType.id === selectedRoomType.id
      );
      if (isEmpty(newValueForSelectedRoomType?.rooms)) {
        setSelectedRoomType(null);
      } else {
        setSelectedRoomType(newValueForSelectedRoomType as RoomType);
      }
    }
  }, [availabilityData, selectedRoomType, setSelectedRoomType]);

  return (
    availabilityData && (
      <Box>
        <Stack spacing={2}>
          {availabilityData.room_types.map((type) => {
            return (
              <RoomTypeCard
                key={type.id}
                roomType={type}
                selected={selectedRoomType?.id === type.id}
                onClickButton={changeSelectedRoomType}
                onOpenGallery={handleGalleryOpen}
                nights={availabilityData.nights}
              />
            );
          })}
        </Stack>
        <GalleryModal
          open={galleryOpen}
          handleClose={handleGalleryClose}
          roomType={roomTypeImages}
        />
      </Box>
    )
  );
};

const GalleryModal = ({ open, handleClose, roomType }: any) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={handleClose}>
      <Container maxWidth="md">
        <Box
          bgcolor={colors.white}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { md: 500, sm: "100%", xs: "100%" },
            boxShadow: 24,
          }}
        >
          <Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" p={1}>
              <Typography>
                {roomType?.name ?? ""} {t("Gallery")}
              </Typography>
              <Box onClick={handleClose}>
                <Close />
              </Box>
            </Stack>
            <Box overflow="hidden">
              {roomType && (
                <DisplayImageCarousel
                  displayImage={roomType.display_image}
                  gallery={roomType.gallery}
                  pagingDotsPaddingBottom={0}
                />
              )}
            </Box>
          </Stack>
        </Box>
      </Container>
    </Modal>
  );
};

export default SelectRoomType;
