import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Paper, Stack, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { format } from "date-fns";
import RetreatApiClient from "helpers/RetreatApiClient";
import { formatMoney } from "helpers/Shared";
import { colors } from "helpers/Theme";
import { useAuth } from "hooks/useAuth";
import useFetch from "hooks/useFetch";
import { useKitchen } from "hooks/useKitchen";
import { capitalize } from "lodash";
import { Order } from "models/Order";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const KitchenOrderDetailPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const theme = useTheme();
  const { handleOpenToast } = useKitchen();

  const [orderData, setOrderData] = useState<Order | null>(null);

  const { data } = useFetch(`orders/${params.id}`, {}, { Authorization: `Bearer ${user.token}` });

  useEffect(() => {
    if (params.id && !orderData?.viewed_at) {
      RetreatApiClient.viewOrder(params.id, user.token);
    }
  }, [orderData, params.id, user.token]);

  useEffect(() => {
    if (data) {
      setOrderData(data);
    }
  }, [data]);

  const handleProcessing = () => {
    if (orderData) {
      RetreatApiClient.setOrderStatus(orderData.id, "processing", user.token).then((res) => {
        if (res.data.data.success) {
          setOrderData(res.data.data.order);
          handleOpenToast("success", t("Order preparation started"));
        }
      });
    }
  };

  const handleConfirmed = () => {
    if (orderData) {
      RetreatApiClient.setOrderStatus(orderData.id, "confirmed", user.token).then((res) => {
        if (res.data.data.success) {
          setOrderData(res.data.data.order);
          handleOpenToast("success", t("Order is confirmed"));
        }
      });
    }
  };

  const isPrepareButtonDisabled = () => {
    return (
      orderData?.status === "completed" ||
      orderData?.status === "processing" ||
      orderData?.status === "confirmed"
    );
  };

  const isConfirmButtonDisabled = () => {
    return orderData?.status === "completed" || orderData?.status === "confirmed";
  };

  return (
    orderData && (
      <Box>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="justify-between">
            <Stack
              direction="row"
              onClick={() => navigate("../")}
              flex={1}
              spacing={1}
              sx={{ cursor: "pointer" }}
            >
              <ArrowBack />
              <Stack>
                <Stack direction="row">
                  <Typography fontWeight="bold">{t("Back to orders")}</Typography>
                  <Typography>
                    {" "}
                    - {t("Order #{{invoiceNumber}}", { invoiceNumber: orderData.invoice_number })}
                  </Typography>
                </Stack>
                <Typography variant="body2">
                  {t("Planned at")} {format(new Date(orderData.starts_at), "PPPPp")}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                onClick={handleProcessing}
                disabled={isPrepareButtonDisabled()}
              >
                {t("Prepare")}
              </Button>
              <Button
                variant="contained"
                onClick={handleConfirmed}
                disabled={isConfirmButtonDisabled()}
              >
                {t("Confirm")}
              </Button>
            </Stack>
          </Stack>
          <Stack direction={{ md: "row" }} spacing={2} flex={1}>
            <Stack flex={3}>
              <Paper>
                <Box p={4}>
                  <Typography variant="h6">{orderData.activity.name}</Typography>
                  <Stack>
                    <Stack my={1}>
                      <Stack direction="row" justifyContent="justify-between">
                        <Typography flex={1} variant="caption">
                          {t("Item")}
                        </Typography>
                        <Typography flex={1} textAlign="right" variant="caption">
                          {t("Quantity")}
                        </Typography>
                        <Typography flex={1} textAlign="right" variant="caption">
                          {t("Total")}
                        </Typography>
                      </Stack>
                    </Stack>
                    {orderData &&
                      orderData.products.map((product) => (
                        <Stack my={1} key={product.name}>
                          <Stack direction="row" justifyContent="justify-between">
                            <Typography flex={1} variant="body1">
                              {product.name}
                            </Typography>
                            <Typography flex={1} textAlign="right" variant="body1">
                              {product.quantity}
                            </Typography>
                            <Typography flex={1} textAlign="right" variant="body1">
                              {formatMoney(product.price * product.quantity ?? 0)}
                            </Typography>
                          </Stack>
                        </Stack>
                      ))}
                    <Divider sx={{ borderStyle: "dashed", borderBottomWidth: "medium" }} />
                    <Stack
                      direction="row"
                      marginY={2}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography fontWeight="bold">{t("Total")}</Typography>
                      <Typography fontWeight="bold" variant="h5" fontFamily="Open Sans">
                        {formatMoney(orderData.total)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Paper>
            </Stack>
            <Stack flex={2}>
              <Paper>
                <Stack p={2} spacing={2}>
                  <Box
                    borderRadius={3}
                    textAlign="center"
                    bgcolor={
                      theme.palette.mode === "dark"
                        ? theme.palette.background.default
                        : colors.warning
                    }
                    py={1}
                  >
                    <Typography>{capitalize(orderData.status)}</Typography>
                  </Box>
                  <Typography>{t("{{count}} people", { count: orderData.people })}</Typography>
                  <Grid2 container gap={1}>
                    {orderData.tags &&
                      orderData.tags.map((tag) => <Chip key={tag.id} label={tag.name} />)}
                    {orderData.activity_options &&
                      orderData.activity_options.map((tag) => (
                        <Chip key={tag.id} label={tag.name} />
                      ))}
                  </Grid2>
                  {orderData.remarks && (
                    <Box>
                      <Typography fontWeight="bold">{t("Remarks")}</Typography>
                      <Typography>{orderData.remarks}</Typography>
                    </Box>
                  )}
                </Stack>
              </Paper>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    )
  );
};

export default KitchenOrderDetailPage;
