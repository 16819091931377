import { Collections } from "@mui/icons-material";
import { Card, Stack, Grid, Typography, Chip, Box, Radio } from "@mui/material";
import RichText from "components/generic/richtext-parser/RichText";
import { calculateDiscountedRoomPrice } from "helpers/BookRoomHelper";
import { formatMoney } from "helpers/Shared";
import { colors } from "helpers/Theme";
import { isEmpty } from "lodash";
import { RoomType, RoomTypeTag } from "models/RoomType";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  roomType: RoomType;
  selected: boolean;
  nights: number;
  onClickButton: (roomType: RoomType) => void;
  onOpenGallery: (roomType: RoomType) => void;
};

const RoomTypeCard = ({ roomType, selected, nights, onClickButton, onOpenGallery }: Props) => {
  const { t } = useTranslation();

  const { cheapest_room: cheapestRoom } = roomType;

  const roomAvailable = useMemo(() => !isEmpty(roomType.rooms), [roomType.rooms]);
  const showOriginalPrice = useMemo(
    () => roomAvailable && cheapestRoom && cheapestRoom.price !== cheapestRoom.original_price,
    [roomAvailable, cheapestRoom]
  );

  const handleOnClick = () => {
    if (!roomAvailable) return;
    onClickButton(roomType);
  };

  const handleOpenGallery = () => {
    onOpenGallery(roomType);
  };

  return (
    <Card elevation={selected ? 5 : 1} sx={{ borderRadius: 3 }} onClick={handleOnClick}>
      <Stack
        spacing={1.5}
        p={2}
        border="2px solid transparent"
        borderColor={selected ? colors.primary : undefined}
        borderRadius={3}
      >
        <Typography variant="h6" fontSize={14}>
          {roomType.name}
        </Typography>
        <Stack direction="row" spacing={2}>
          {roomType.display_image && (
            <Box
              borderRadius={3}
              width={75}
              height={75}
              bgcolor={colors.darkMedium}
              overflow="hidden"
              position="relative"
              onClick={handleOpenGallery}
            >
              <Box
                width={30}
                height={30}
                bgcolor={colors.darkMedium + "AA"}
                position="absolute"
                top={5}
                right={5}
                alignItems="center"
                justifyContent="center"
                display="flex"
                borderRadius={2}
              >
                <Collections htmlColor={colors.white} fontSize="small" />
              </Box>
              <img
                src={roomType.display_image.url}
                alt={roomType.display_image.file_name}
                height="100%"
                width="100%"
                style={{ objectFit: "cover" }}
              />
            </Box>
          )}
          <Box flex={1}>
            {!isEmpty(roomType.tags) ? (
              <RoomTypeTags tags={roomType.tags} />
            ) : (
              <RichText htmlString={roomType.description} />
            )}
          </Box>
        </Stack>
        {roomAvailable && (
          <Stack alignItems="flex-end">
            <Typography variant="body2">{t("Price per night")}</Typography>
            <Stack direction="row" spacing={1} textAlign="right">
              {showOriginalPrice && (
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{
                      textDecoration: "line-through",
                      textDecorationColor: "error",
                    }}
                  >
                    {formatMoney(cheapestRoom.nightly_price ?? 0)}
                  </Typography>
                </Stack>
              )}
              <Typography
                variant="h5"
                fontWeight="bold"
                fontFamily="Open Sans"
                color={colors.darkMedium}
              >
                {formatMoney(cheapestRoom.average_nightly_price ?? 0)}
              </Typography>
            </Stack>
          </Stack>
        )}
        <Stack
          direction="row"
          spacing={1}
          bgcolor={selected ? colors.primary : colors.greyLight}
          borderRadius={4}
          alignItems="center"
          onClick={handleOnClick}
        >
          <Radio
            checked={selected}
            onChange={handleOnClick}
            color="default"
            disabled={!roomAvailable}
          />
          <Typography
            variant="body2"
            fontWeight="medium"
            color={selected ? colors.white : roomAvailable ? colors.dark : colors.dark + "AA"}
          >
            {roomAvailable ? (
              <Trans
                i18nKey="{{count}} night for <strong>{{price}}</strong> <strike>{{old_price}}</strike>"
                components={{ strong: <strong />, strike: <s style={{ color: colors.error }} /> }}
                values={{
                  count: nights,
                  price: formatMoney(cheapestRoom.price ?? 0),
                  old_price: showOriginalPrice ? formatMoney(cheapestRoom.original_price ?? 0) : "",
                }}
              />
            ) : (
              t("This option is sold out")
            )}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

const RoomTypeTags = ({ tags }: { tags: RoomTypeTag[] }) => {
  return (
    <Grid container spacing={0.5}>
      {tags.map((tag) => (
        <Grid item key={tag.id}>
          <Chip
            size="small"
            label={tag.name}
            variant="outlined"
            sx={{ borderColor: colors.greyLight, color: colors.darkMedium }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default RoomTypeCard;
