import { Alert, Box, Container, Snackbar, Step, StepLabel, Stepper } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { Activity, TimeSlot } from "models/Activity";
import { ActivityContext } from "contexts/ActivityContext";
import { SelectedProductObject } from "types/SelectedProductObject";
import { SelectedAlternativePriceObject } from "types/SelectedAlternativePriceObject";
import { SelectedOptionObject } from "types/SelectedOptionObject";
import { Steps } from "types/StepsEnum";
import {
  formatProducts,
  formatAlternativePrices,
  formatOptionGroups,
  getNextStep,
  getPreviousStep,
  isValidEmail,
  reserveActivity,
} from "helpers/ActivityReserveHelper";
import Reserve from "./components/reservation/Reserve";
import Options from "./components/extras/Extras";
import Contact from "./components/contact/Contact";
import RedirectToPayment from "./components/payment/RedirectToPayment";
import LoadingContainer from "../../components/LoadingContainer";
import BottomStepperNavigator from "./components/BottomStepperNavigator";
import { isEmpty, sum, sumBy } from "lodash";
import { colors } from "helpers/Theme";
import "components/controls/stepper/StyledStepper.css";
import { Order } from "models/Order";
import Analytics from "helpers/Analytics";
import { OptionGroupsAvailability } from "models/OptionGroupsAvailability";

type ActivityData = {
  data: Activity;
  loading: boolean;
  error: unknown;
};

const BookActivityPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [steps, setSteps] = useState<Steps[]>([Steps.RESERVE, Steps.CONFIRM]);
  const [activeStep, setActiveStep] = useState(steps[0]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Reservation
  const [selectedPeopleAmount, setSelectedPeopleAmount] = useState(0);
  const [selectedAlternativePrices, setSelectedAlternativePrices] =
    useState<SelectedAlternativePriceObject | null>(null); // (activity.alternative_prices)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState<TimeSlot | null>(null); // (activity.time_slots)
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionObject | null>(null); // (activity.options)
  const [optionGroupsAvailabilityData, setOptionGroupsAvailabilityData] = useState<
    OptionGroupsAvailability[] | null
  >([]);

  // Extras/Addons
  const [selectedProducts, setSelectedProducts] = useState<SelectedProductObject | null>(null); // (activity.products)

  // Review and Payment
  //  Contact inputs
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  //  Payment methods
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any | null>(null);
  //  Remarks
  const [remarks, setRemarks] = useState("");
  // Terms and Conditions
  const [readTermsAndConditions, setReadTermsAndConditions] = useState(false);

  // Payment
  const [creatingReservation, setCreatingReservation] = useState(false);
  const [reservationData, setReservationData] = useState<{
    order: Order;
    payment_link: string;
  } | null>(null);
  const [paymentLink, setPaymentLink] = useState("");

  const {
    data: activityData,
    loading: activityLoading,
    error: activityError,
  }: ActivityData = useFetch(`open/activities/${params.activitySlug}`);

  useMemo(() => {
    if (activityData) {
      if (activityData.has_time_slots) {
        // Defaults to the first possible time slot if there are slots available.
        setSelectedTimeslot(activityData.time_slots[0]);
      }

      if (activityData.has_alternative_prices) {
        setSelectedAlternativePrices(formatAlternativePrices(activityData.alternative_prices));
      }

      if (!isEmpty(activityData.products)) {
        setSteps([Steps.RESERVE, Steps.EXTRAS, Steps.CONFIRM]);
        setSelectedProducts(formatProducts(activityData.products));
      }

      if (!isEmpty(activityData.option_groups)) {
        setSelectedOptions(formatOptionGroups(activityData.option_groups));
      }
    }
  }, [activityData]);

  useMemo(() => {
    if (!isEmpty(optionGroupsAvailabilityData)) {
      setSelectedOptions((prevValue) =>
        formatOptionGroups(
          optionGroupsAvailabilityData as OptionGroupsAvailability[],
          prevValue as SelectedOptionObject
        )
      );
    }
  }, [optionGroupsAvailabilityData]);

  useMemo(() => {
    const people = sumBy(Object.values(selectedAlternativePrices ?? {}), "amount");
    setSelectedPeopleAmount(people);
  }, [selectedAlternativePrices]);

  const handleReservePostRequest = () => {
    reserveActivity({
      email,
      activityId: activityData.id,
      phoneNumber,
      firstName,
      lastName,
      date: selectedDate ?? new Date(),
      timeslot: selectedTimeslot,
      paymentMethod: selectedPaymentMethod,
      alternativePrices: selectedAlternativePrices ?? {},
      products: selectedProducts ?? {},
      options: selectedOptions ?? {},
      people: selectedPeopleAmount,
      remarks: remarks,
    })
      .then((response) => {
        setActiveStep(Steps.REDIRECTING);
        setReservationData(response.data.data);
        setPaymentLink(response.data.data.payment_link);
        setCreatingReservation(false);
        const { total, invoice_number } = response.data.data.order;
        Analytics.trackActivityBooked(total, invoice_number);
      })
      .catch((error) => {
        setOpen(true);
        setCreatingReservation(false);
        setLoading(false);
      });
  };

  const handleNext = () => {
    window.scrollTo(0, 0);
    if (activeStep === Steps.CONFIRM) {
      setCreatingReservation(true);
      // setLoading(true);
      handleReservePostRequest();
      return;
    }
    setLoading(true);
    setActiveStep(getNextStep(activeStep, activityData.products.length <= 0));
    setLoading(false);
  };

  const handleBack = () => {
    setActiveStep(getPreviousStep(activeStep, activityData.products.length <= 0));
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const isValidOptions = (people: number) => {
    if (!selectedOptions) return true;
    return Object.values(selectedOptions as SelectedOptionObject)
      .map((optionGroup) => {
        const selectedOption = optionGroup.options[optionGroup.selected];
        if (selectedOption.max_people === null) {
          return true;
        }
        return selectedOption.is_available && selectedOption.stock && selectedOption.stock >= people;
      })
      .every(Boolean);
  };

  const isValidReservation = () => {
    switch (activeStep) {
      case Steps.RESERVE:
        if (selectedDate !== null) {
          if (selectedAlternativePrices) {
            const people = sum(
              Object.values(selectedAlternativePrices).map((value) => value.amount)
            );
            return people > 0 && isValidOptions(people);
          } else {
            return selectedPeopleAmount > 0 && isValidOptions(selectedPeopleAmount);
          }
        }
        return false;

      case Steps.EXTRAS:
        return true;

      case Steps.CONFIRM:
        if (
          !selectedPaymentMethod ||
          !firstName ||
          !lastName ||
          !email ||
          !phoneNumber ||
          !readTermsAndConditions
        ) {
          return false;
        }
        if (!isValidEmail(email)) return false;
        return true;

      default:
        return false;
    }
  };

  const getActiveStepInNumber = () => {
    if (activeStep === Steps.REDIRECTING) return steps.length + 1;
    return Object.values(steps).indexOf(activeStep);
  };

  const renderPage = () => {
    if (activityError) {
      navigate("/");
      return <></>;
    }

    if (activityLoading) {
      return (
        <Container maxWidth="sm">
          <LoadingContainer />
        </Container>
      );
    }

    return (
      <Box>
        <Box padding={2} bgcolor={colors.darkMedium}>
          <Container maxWidth="sm">
            <Stepper activeStep={getActiveStepInNumber()} className="stepper">
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Container>
        </Box>
        <Container maxWidth="sm">
          <Box>{renderStepContent()}</Box>

          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
              Unable to complete reservation, please try again later.
            </Alert>
          </Snackbar>
          {activeStep !== Steps.REDIRECTING && (
            <BottomStepperNavigator
              activeStep={activeStep}
              loading={loading || creatingReservation}
              onBack={handleBack}
              onNext={handleNext}
              isValid={isValidReservation()}
            />
          )}
        </Container>
      </Box>
    );
  };

  const renderStepContent = () => {
    if (loading) return <LoadingContainer />;

    switch (activeStep) {
      case Steps.RESERVE:
        return <Reserve />;

      case Steps.EXTRAS:
        return <Options />;

      case Steps.CONFIRM:
        return <Contact />;

      case Steps.REDIRECTING:
        return (
          <RedirectToPayment
            paymentLink={paymentLink}
            order={reservationData?.order ?? undefined}
          />
        );

      default:
        break;
    }
  };

  return (
    <ActivityContext.Provider
      value={{
        activity: activityData,
        optionGroupsAvailabilityData,
        setOptionGroupsAvailabilityData,
        selectedPeopleAmount,
        setSelectedPeopleAmount,
        setSelectedAlternativePrices,
        selectedAlternativePrices,
        selectedDate,
        setSelectedDate,
        setSelectedTimeslot,
        selectedTimeslot,
        setSelectedProducts,
        selectedProducts,
        selectedOptions,
        setSelectedOptions,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        email,
        setEmail,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        phoneNumber,
        setPhoneNumber,
        remarks: remarks,
        setRemarks: setRemarks,
        creatingReservation,
        readTermsAndConditions,
        setReadTermsAndConditions,
      }}
    >
      {renderPage()}
    </ActivityContext.Provider>
  );
};

export default BookActivityPage;
