import _, { sum } from "lodash";
import { Product } from "models/Activity";
import { OrderProduct } from "models/Order";
import { MenuGroup } from "types/MenuGroups";

/**
 * Generates new order array object for the orderFoodpage
 *
 *
 * @param currentOrder - order from state (OrderProduct[])
 * @param product - product to edit, add or remove
 * @param products - new amount for product in order array
 *
 * @return order array with correct amounts per product
 */
export const generateNewOrderObject = (
  currentOrder: OrderProduct[],
  product: OrderProduct,
  newValue: number
) => {
  // Create mutable order object from existing state order
  const order = [...currentOrder];
  const productInStateIndex = order.findIndex((orderProduct) => orderProduct.id === product.id);
  if (productInStateIndex === -1) {
    // Add new product
    order.push({ ...product, quantity: newValue });
  } else if (newValue === 0) {
    // Remove product
    order.splice(productInStateIndex, 1);
  } else {
    // Update order
    order[productInStateIndex].quantity = newValue;
  }
  return order;
};

export const groupMenuProducts = (products: Product[]): MenuGroup[] => {
  return _(products)
    .groupBy("product_category_name")
    .map((items, key) => ({
      title: key !== "null" ? key : "Uncategorized",
      category: { ...items[0].category },
      items,
    }))
    .value();
};

export const calculateOrderPrice = (order: OrderProduct[]) => {
  return sum(order.map((o) => o.price * (o.quantity ?? 0)));
};

