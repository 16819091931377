import { Box, Container, Stack } from "@mui/material";
import LoadingContainer from "components/LoadingContainer";
import useFetch from "hooks/useFetch";
import { Order } from "models/Order";
import ReceiptOverviewCard from "../components/ReceiptOverviewCard";

const ReceiptsTab = () => {
  const { data, loading } = useFetch("transactions");

  if (loading) return <LoadingContainer />;

  return (
    data && (
      <Container maxWidth="md">
        <Stack spacing={2} marginY={2}>
          {data && data.map((item: Order) => <ReceiptOverviewCard item={item} key={item.id} />)}
          <Box height={50} />
        </Stack>
      </Container>
    )
  );
};

export default ReceiptsTab;
