import { RadioGroup, RadioGroupProps } from "@mui/material";

type Props = {};

const CustomRadioGroup = (props: RadioGroupProps & Props) => {
  return (
    <RadioGroup name="custom-radio-group" {...props}>
      {props.children}
    </RadioGroup>
  );
};

export default CustomRadioGroup;
