import { Stack } from "@mui/material";
import { useKitchen } from "hooks/useKitchen";
import useOneSignal from "hooks/useOneSignal";
import { useEffect } from "react";
import OrdersTable from "./OrdersTable";

const KitchenTables = () => {
  const { tables, initialLoad } = useKitchen();

  useEffect(() => {
    initialLoad();
  }, []);

  useOneSignal({ role: "kitchen" });
  return (
    <Stack spacing={3}>
      {Object.values(tables ?? {}).map((table) => (
        <OrdersTable table={table} key={table.id} />
      ))}
    </Stack>
  );
};

export default KitchenTables;
