import parse, { HTMLReactParserOptions } from "html-react-parser";
import "./RichText.css";

const options: HTMLReactParserOptions = {
  replace: (domNode) => {},
};

const RichText = ({ htmlString }: { htmlString: string }) => {
  return <div className="richtext-container">{parse(htmlString, options)}</div>;
};

export default RichText;
