import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { Order } from "models/Order";
import { QRCodeSVG } from "qrcode.react";
import YappyInstructions from "components/YappyInstructions";
import { useTranslation } from "react-i18next";
import { colors, BaseTheme } from "helpers/Theme";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import RichText from "components/generic/richtext-parser/RichText";
import RetreatApiClient from "helpers/RetreatApiClient";

const OrderPaymentStatus = ({ order }: { order: Order }) => {
  const { t } = useTranslation();

  const fetchPaymentLink = () => {
    RetreatApiClient.requestOrderPaymentLink(order.id).then((response) => {
      window.location.assign(response.data.data.link);
    });
  };

  const renderStatus = () => {
    switch (order.status) {
      case "cancelled":
        return (
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <ErrorOutline color="error" fontSize="large" />
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {t("Cancelled")}
              </Typography>
              <Typography>
                {t("Your order was cancelled. Please contact us if you have any questions.")}
              </Typography>
            </Stack>
          </Stack>
        );

      case "completed":
        return (
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <CheckCircleOutline color="primary" fontSize="large" />
            <Stack>
              <Typography variant="body1" fontWeight="bold">
                {t("Checked in")}
              </Typography>
              {order.activity.order_confirmation_description && (
                <RichText htmlString={order.activity.order_confirmation_description} />
              )}
              <Typography>{t("Enjoy your day!")}</Typography>
            </Stack>
          </Stack>
        );

      case "paid":
      case "confirmed":
        return (
          <Stack spacing={3} alignItems="center">
            <Box>
              <Typography variant="h6" textAlign="center">
                {t("Show at location")}
              </Typography>
              {order.activity.order_paid_description && (
                <RichText htmlString={order.activity.order_paid_description} />
              )}
              <Typography variant="body2" textAlign="center">
                {t(
                  "Please show this QR code when you arrive at the location, this is your entry pass."
                )}
              </Typography>
            </Box>
            <Stack marginY={3} alignItems="center" spacing={2}>
              <QRCodeSVG value={order.invoice_number} />
              <Typography variant="body1" textAlign="center">
                #{order.invoice_number}
              </Typography>
            </Stack>
          </Stack>
        );

      case "pending":
        if (order.payment_provider === "yappy") {
          return <YappyInstructions order={order} />;
        }
        return (
          <Stack spacing={1} alignItems="center">
            <Typography variant="h6" textAlign="center">
              {t("Payment required")}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t(
                "Your order is currently only a reservation. Make sure you pay before someone else books your reserved date!"
              )}
            </Typography>
            <Button variant="contained" onClick={fetchPaymentLink}>
              {t("Proceed to payment")}
            </Button>
          </Stack>
        );

      default:
        break;
    }
  };
  return (
    <Box
      marginY={3}
      borderRadius={BaseTheme.shape.borderRadius}
      bgcolor={order.status === "cancelled" ? colors.errorLight : colors.primaryLight}
      p={3}
    >
      {renderStatus()}
    </Box>
  );
};

export default OrderPaymentStatus;
