import { Box, Typography } from "@mui/material";
import RichText from "components/generic/richtext-parser/RichText";
import { BookRoomContext } from "contexts/BookRoomContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const BookRoomInformation = () => {
  const { t } = useTranslation();

  const { accommodation } = useContext(BookRoomContext);

  return (
    accommodation && (
      <Box>
        <Typography variant="h6">{accommodation.booking_title}</Typography>
        <RichText htmlString={accommodation.booking_description} />
      </Box>
    )
  );
};

export default BookRoomInformation;
