import { Stack, Box, Typography } from "@mui/material";
import NumericInput from "components/controls/numeric-input/NumericInput";
import { formatMoney } from "helpers/Shared";
import { colors } from "helpers/Theme";

type Props = {
  imgUrl?: string;
  title: string;
  price: number;
  description: string;
  value: any;
  stock?: number;
  unlimited?: number;
  onChange(value: number): void;
};

const ExtraCard = ({
  imgUrl,
  title,
  price,
  description,
  value,
  stock,
  unlimited,
  onChange,
}: Props) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
      {imgUrl && (
        <Box flex={1} height={80} overflow="hidden" borderRadius={4}>
          <img src={imgUrl} alt={title} height={80}/>
        </Box>
      )}
      <Stack direction="column" flex={2}>
        <Typography variant="body1" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="caption" color={colors.darkMedium}>
          {formatMoney(price)}
        </Typography>
        <Typography variant="caption">{description}</Typography>
      </Stack>
      <NumericInput value={value} onChange={onChange} max={15} />
    </Stack>
  );
};

export default ExtraCard;
