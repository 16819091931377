import MenuTabContent from "components/order-food/MenuTabContent";
import { SelectMenuActivityContext } from "contexts/WaiterOrderFoodContext";
import { useContext } from "react";

const MenuTab = () => {
  const { activity, order, changeOrder } = useContext(SelectMenuActivityContext);
  return <MenuTabContent currentOrder={order} changeOrder={changeOrder} activity={activity} />;
};

export default MenuTab;
