import { Box, ClickAwayListener, Paper, Popper } from "@mui/material";
import { noop } from "lodash";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import RangePicker from "../core/RangePicker";

const DesktopDateRangePicker = (props: any) => {
  const {
    onClose,
    onSelectRange,
    selectedRange,
    disabledDays,
    fromMonth,
    anchorEl,
    ...attributes
  } = props;

  const [range, setRange] = useState<DateRange | undefined>(selectedRange);

  const handleSetRange = (value: DateRange | undefined) => {
    if (range?.to) {
      if (range?.to !== value?.to) {
        // If selected date is after previous second date
        setRange(value?.from ? { from: value?.to } : undefined);
        onSelectRange(value?.from ? { from: value?.to } : undefined);
      } else {
        // If selected first date is before previous first date
        setRange(value?.from ? { from: value?.from } : undefined);
        onSelectRange(value?.from ? { from: value?.from } : undefined);
      }
    } else {
      // initial selection
      setRange(value);
      onSelectRange(value);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <Box {...attributes}>
      {/* TODO check what nonce, onResize, onResizeCapture do and change if neccesary */}
      <Popper anchorEl={anchorEl} open={open} nonce={1} onResize={noop} onResizeCapture={noop}>
        <Paper>
          <ClickAwayListener onClickAway={onClose}>
            <Box p={1}>
              <RangePicker
                selected={range}
                onSelect={handleSetRange}
                disabled={disabledDays}
                fromMonth={fromMonth}
              />
            </Box>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  );
};

export default DesktopDateRangePicker;
