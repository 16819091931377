import { Box, Container, Stepper, Step, StepLabel } from "@mui/material";
import { colors } from "helpers/Theme";
import "./StyledStepper.css";

type Props = {
  steps: string[];
  activeStep: number;
};

const StyledStepper = ({ steps, activeStep }: Props) => {
  return (
    <Box padding={2} bgcolor={colors.darkMedium}>
      <Container maxWidth="sm">
        <Stepper activeStep={activeStep} className="stepper">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>
    </Box>
  );
};

export default StyledStepper;
