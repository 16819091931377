import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import { ActivityContext } from "contexts/ActivityContext";
import ChipRadioControl from "components/controls/chip-radio/ChipRadioControl";
import ChipRadioGroup from "components/controls/chip-radio/ChipRadioGroup";
import { useTranslation } from "react-i18next";
import RichText from "components/generic/richtext-parser/RichText";

const ReservationSelectTimeslot = () => {
  const { t } = useTranslation();
  const { activity, setSelectedTimeslot, selectedTimeslot } = React.useContext(ActivityContext);

  const handleTimeslotChange = (selectedTimeslot: any) => {
    setSelectedTimeslot(selectedTimeslot);
  };

  return (
    activity && (
      <Box marginY={3}>
        <Typography variant="h6" mt={2}>
          3. {activity.timeslot_title ?? t("Select a pickup time")}
        </Typography>
        <RichText htmlString={activity.timeslot_description ?? `<p>${t("Select your preferred timeslot. Please make sure you arrive on time.")}</p>`} />
        {activity.time_slots ? (
          <ChipRadioGroup defaultValue={activity.time_slots[0]} value={selectedTimeslot}>
            <Grid container marginY={1} spacing={1} columns={{ xs: 3, sm: 5 }} bgcolor="primary">
              {activity.time_slots.map((t, i) => (
                <Grid key={i} item>
                  <ChipRadioControl
                    label={`${t.start_time} - ${t.end_time}`}
                    value={t}
                    onClick={handleTimeslotChange}
                  />
                </Grid>
              ))}
            </Grid>
          </ChipRadioGroup>
        ) : (
          <Typography>{t("No Slots available")}</Typography>
        )}
      </Box>
    )
  );
};

export default ReservationSelectTimeslot;
