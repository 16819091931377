import Header from "components/Header";
import { useOutlet } from "react-router-dom";

const OpenLayout = () => {
  const outlet = useOutlet();

  return (
    <div>
      <Header />
      {outlet}
    </div>
  );
};

export default OpenLayout;
