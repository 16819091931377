import {
  Unstable_Grid2 as Grid,
  Box,
  Chip,
  Typography,
  Divider,
  Stack,
  Card,
  CardActionArea,
} from "@mui/material";
import { formatMoney } from "helpers/Shared";
import { Activity } from "models/Activity";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  activity: Activity;
};

const ActivityBookNowCard = ({ activity }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOnClick = () => navigate(activity.slug);

  return (
    <Grid xs={1} onClick={handleOnClick} sx={{ cursor: "pointer" }}>
      <Card sx={{ borderRadius: 3 }}>
        <CardActionArea>
          <Box overflow="hidden">
            <Box position="relative">
              <Box position="absolute" top={10} right={10}>
                <Chip label="Book now" variant="filled" color="secondary" />
              </Box>
              <img
                src={activity.display_image.url}
                alt="a"
                width="100%"
                height={200}
                style={{ objectFit: "cover" }}
              />
            </Box>
            <Box p={2}>
              <Box marginBottom={2}>
                <Typography variant="h6">{activity.name}</Typography>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {activity.description}
                </Typography>
              </Box>
              <Divider />
              <Stack marginY={2}>
                {activity.has_alternative_prices ? (
                  <Box>
                    {activity.alternative_prices.map((price, index) => {
                      return (
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="body1" fontWeight="500">
                            {price.name}
                          </Typography>
                          <Typography variant="body1">{formatMoney(price.price)}</Typography>
                        </Stack>
                      );
                    })}
                  </Box>
                ) : (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" fontWeight="bold">
                      {t("Price per person")}
                    </Typography>
                    <Typography variant="body1">{formatMoney(activity.price)}</Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ActivityBookNowCard;
