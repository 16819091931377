import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import { ActivityContext } from "contexts/ActivityContext";
import { AlternativePrice } from "models/Activity";
import { useTranslation } from "react-i18next";
import NumericInput from "components/controls/numeric-input/NumericInput";
import { formatMoney } from "helpers/Shared";

const ReservationAlternativePricesAmount = () => {
  const { t } = useTranslation();
  const { activity, setSelectedAlternativePrices, selectedAlternativePrices } =
    React.useContext(ActivityContext);

  const handlePriceChange = (price: AlternativePrice, newValue: number) => {
    setSelectedAlternativePrices((prevValue) => {
      return { ...prevValue, [price.id]: { price, amount: newValue } };
    });
  };

  return (
    activity && (
      <Box marginY={3}>
        <Typography variant="h6">1. {activity.alternative_price_title}</Typography>
        {activity.alternative_prices.length > 0 ? (
          activity.alternative_prices.map((price, index) => {
            const handleChange = (newValue: number) => {
              handlePriceChange(price, newValue);
            };
            return (
              selectedAlternativePrices && (
                <Stack
                  key={index}
                  direction="row"
                  mt={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1" flex={1}>
                    {price.name}
                  </Typography>
                  <Typography variant="body1" flex={1} textAlign="right" mr={3}>
                    {formatMoney(price.price)}
                  </Typography>
                  <NumericInput
                    value={selectedAlternativePrices[price.id].amount}
                    onChange={handleChange}
                    max={15}
                  />
                </Stack>
              )
            );
          })
        ) : (
          <Typography>{t("No products available")}</Typography>
        )}
      </Box>
    )
  );
};

export default ReservationAlternativePricesAmount;
