import { createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "hooks/useLocalStorage";
import RetreatApiClient from "helpers/RetreatApiClient";
import i18n from "../i18n";

type AuthContextValues = {
  user: any;
  error: any;
  signIn: (email: string, password: string) => void;
  signOut: () => void;
};

export const AuthContext = createContext<AuthContextValues>({
  user: null,
  error: null,
  signIn: () => null,
  signOut: () => null,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const lng = i18n.language;

  const signIn = async (email: string, password: string) => {
    setError(null);
    RetreatApiClient.signIn(email, password)
      .then((response) => {
        setUser(response.data);
        navigate(lng ? `${lng}/dashboard` : "dashboard", { replace: true });
      })
      .catch((error) => {
        setError(error.response.data);
      });
  };

  const signOut = () => {
    setUser(null);
    navigate(lng ? `${lng}/signin` : "signin", { replace: true });
  };

  return (
    <AuthContext.Provider value={{ user, error, signIn, signOut }}>{children}</AuthContext.Provider>
  );
};
