import { Container, Stack, Typography } from "@mui/material";
import OrderOverviewCard from "components/order-food/OrderOverviewCard";
import { ClientOrderContext } from "contexts/ClientOrderContext";
import { t } from "i18next";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

const PreviousOrders = () => {
  const { t } = useTranslation();

  const { getOrdersFromStorage } = useContext(ClientOrderContext);

  return (
    <Container maxWidth="md">
      <Stack spacing={2} mb={9}>
        <Typography variant="h6">{t("Previous orders")}</Typography>
        {getOrdersFromStorage().map((order) => {
          return (
            <OrderOverviewCard
              order={order}
              key={order.id}
              products={order.products}
              total={order.total}
              activity={order.activity}
            />
          );
        })}
      </Stack>
    </Container>
  );
};

export default PreviousOrders;
