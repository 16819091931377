import { IconButton, useTheme } from "@mui/material";
import ThemeContext from "contexts/ThemeContext";
import { useContext } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { DarkMode } from "@mui/icons-material";

const ThemeSwitch = () => {
  const theme = useTheme();
  const { toggleColorMode } = useContext(ThemeContext);

  return (
    <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
      {theme.palette.mode === "dark" ? <DarkMode /> : <Brightness4Icon />}
    </IconButton>
  );
};

export default ThemeSwitch;
