import { Box, Button, Dialog, Paper, Stack } from "@mui/material";
import { useState } from "react";
import { DayPicker, DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";
import RangePicker from "../core/RangePicker";

const MobileDateRangePicker = (props: any) => {
  const { t } = useTranslation();
  const {
    onClose,
    onCancel,
    open,
    onSelectRange,
    selectedRange,
    disabledDays,
    fromMonth,
    ...attributes
  } = props;

  const [range, setRange] = useState<DateRange | undefined>(selectedRange);

  const handleSetRange = (value: DateRange | undefined) => {
    if (range?.to) {
      if (range?.to !== value?.to) {
        // If selected date is after previous second date
        setRange(value?.from ? { from: value?.to } : undefined);
        onSelectRange(value?.from ? { from: value?.to } : undefined);
      } else {
        // If selected first date is before previous first date
        setRange(value?.from ? { from: value?.from } : undefined);
        onSelectRange(value?.from ? { from: value?.from } : undefined);
      }
    } else {
      // initial selection
      setRange(value);
      onSelectRange(value);
    }
  };

  const handleCancel = () => {
    setRange(selectedRange);
    onSelectRange(selectedRange);
    onClose();
  };

  const handleOk = () => {
    onClose();
  };

  const renderFooter = () => {
    return (
      <Stack direction="row" justifyContent="flex-end">
        <Button onClick={handleCancel}>{t("Cancel")}</Button>
        <Button onClick={handleOk}>{t("Ok")}</Button>
      </Stack>
    );
  };

  return (
    <Box {...attributes}>
      <Dialog open={open} onClose={onClose}>
        <Paper>
          <Box p={1}>
            <RangePicker
              selected={range}
              onSelect={handleSetRange}
              footer={renderFooter()}
              disabled={disabledDays}
              fromMonth={fromMonth}
            />
          </Box>
        </Paper>
      </Dialog>
    </Box>
  );
};

export default MobileDateRangePicker;
