import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { PaymentMethod } from "models/PaymentMethod";
import BoxRadioControl from "components/controls/custom-radio/BoxRadioControl";
import CustomRadioGroup from "components/controls/custom-radio/CustomRadioGroup";

type Props = {
  paymentMethods: PaymentMethod[];
  value: PaymentMethod | undefined;
  onChange: (value: any) => void;
  disabled?: boolean;
};

const SelectPaymentMethod = ({ paymentMethods, value, onChange, disabled }: Props) => {
  const [selectedPaymentMethod, setPaymentMethod] = useState(value ?? { id: null });

  const handlePaymentMethodChange = (selectedPaymentMethodId: string) => {
    const methodFromData = paymentMethods.find((method) => method.id === selectedPaymentMethodId);
    if (methodFromData) {
      onChange(methodFromData);
      setPaymentMethod(methodFromData);
    }
  };

  return (
    <Box>
      {paymentMethods && (
        <CustomRadioGroup defaultValue={paymentMethods[0].id} value={selectedPaymentMethod.id}>
          <Grid container spacing={1} columns={{ xs: 3, sm: 4 }}>
            {paymentMethods.map((paymentMethod, i) => {
              const handleClick = (e: any) => handlePaymentMethodChange(e as string);
              return (
                <Grid key={i} xs={1} sm={1} item>
                  <BoxRadioControl<string>
                    value={paymentMethod.id}
                    onClick={handleClick}
                    disabled={disabled}
                  >
                    <PaymentCard slug={paymentMethod.slug} name={paymentMethod.name} />
                  </BoxRadioControl>
                </Grid>
              );
            })}
          </Grid>
        </CustomRadioGroup>
      )}
    </Box>
  );
};

type PaymentCardProps = {
  slug: string;
  name: string;
};

const PaymentCard = ({ slug, name }: PaymentCardProps) => {
  const getPaymentImage = () => {
    switch (slug) {
      case "nequi":
        return require("assets/images/payment-icons/nequi.png");
      case "clave":
        return require("assets/images/payment-icons/clave.png");
      case "credit_card":
        return require("assets/images/payment-icons/mastercard.png");
      case "yappy":
        return require("assets/images/payment-icons/yappy.png");
      case "cash":
        return require("assets/images/payment-icons/cash.png");
      case "bill":
        return require("assets/images/payment-icons/bill.jpeg");

      default:
        return require("assets/images/payment-icons/nequi.png");
    }
  };
  return (
    <Stack alignItems="center">
      <img src={getPaymentImage()} alt={slug} height={25} />
      <Typography variant="caption">{name}</Typography>
    </Stack>
  );
};

export default SelectPaymentMethod;
