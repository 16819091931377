import { useEffect, useState } from "react";
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import i18n from "../i18n";
import { useAuth } from "./useAuth";
import { useNavigate } from "react-router-dom";

export default function useFetch(
  url: string,
  params?: any,
  headers?: AxiosRequestHeaders,
  page?: number,
  otherAxiosConfig?: AxiosRequestConfig
) {
  const navigate = useNavigate();
  const [data, setData] = useState<any>(null);
  const [meta, setMeta] = useState<any>(null);
  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}${url}`, {
          params: { ...params, ...(page && { page }) },
          headers: {
            "Accept-Language": i18n.language ?? "en",
            ...(user && { Authorization: `Bearer ${user.token}` }),
            ...headers,
          },

          ...otherAxiosConfig,
        });
        setData(response.data.data);
        setMeta(response.data.meta);
      } catch (err: any) {
        if (err.code === 401) {
          navigate("/");
        }
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [page, navigate]);

  return { data, error, loading, meta };
}
