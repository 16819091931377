import { Box, Typography, Divider, Container } from "@mui/material";
import { Activity, Product } from "models/Activity";
import { useMemo, useRef, useState } from "react";
import { MenuGroup } from "types/MenuGroups";
import MenuRowItem from "components/order-food/MenuRowItem";
import QuickFilterTopBar from "components/quick-filter/QuickFilterTopBar";
import { OrderProduct } from "models/Order";
import { groupMenuProducts } from "helpers/MenuActivityHelper";

type Props = {
  activity: Activity | undefined;
  changeOrder: (currentOrder: OrderProduct[], product: OrderProduct, newValue: number) => void;
  currentOrder: OrderProduct[];
};

const MenuTabContent = ({ activity, changeOrder, currentOrder }: Props) => {
  const [menuGroups, setMenuGroups] = useState<MenuGroup[]>([]);

  const refs = useRef<any>([]);

  useMemo(() => {
    if (activity) {
      setMenuGroups(groupMenuProducts(activity.products));
    }
  }, [activity]);

  const handleCategoryClick = (refIndex: number) => {
    refs.current[refIndex].scrollIntoView({ behavior: "smooth" });
  };

  const renderMenu = () =>
    menuGroups.map((menuGroup, index) => {
      return (
        <Box
          key={index}
          ref={(element) => (refs.current[index] = element)}
          sx={{ scrollMarginTop: 70 }}
        >
          <Typography variant="h6">{menuGroup.title}</Typography>
          <Divider />
          {menuGroup.items.map(renderMenuItem)}
        </Box>
      );
    });

  const renderMenuItem = (item: Product) => {
    const currentOrderAmount = currentOrder.find(
      (orderItem: { id: number }) => orderItem.id === item.id
    );
    const orderProduct: OrderProduct = {
      id: item.id,
      name: item.name,
      quantity: currentOrderAmount?.quantity ?? 0,
      description: item.description,
      price: item.price,
      total: 0,
    };
    const handleAmountChange = (newValue: number) =>
      changeOrder(currentOrder, orderProduct, newValue);
    return (
      <MenuRowItem
        menuItem={item}
        key={item.id}
        onChange={handleAmountChange}
        value={currentOrderAmount?.quantity ?? 0}
      />
    );
  };

  return (
    <Box>
      <QuickFilterTopBar menuGroups={menuGroups} onCategoryClick={handleCategoryClick} />
      <Container maxWidth="md">
        <Box mt={2} mb={10}>
          {renderMenu()}
        </Box>
      </Container>
    </Box>
  );
};

export default MenuTabContent;
